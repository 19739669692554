import {IPhotoUploadListener, PhotosProvider} from "../module.evaluation-services/service.photos/photos";
import {AspectAnswers} from "../../javascript.lib.mojo-base/model/AspectAnswers";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {FirebaseAnswers} from "../firebase/realtime-database/answers-x/FirebaseAnswers";
import {ModalSpinnerComponent} from "../module.base-components/component.modal-spinner/modal-spinner";
import {MatDialog} from "@angular/material/dialog";
import {Command} from "../../javascript.lib.mojo-base/util/Command";
import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";




class SaveProgressDisplay2 implements IPhotoUploadListener {

  private _log: ILogger = LoggerFactory.build( 'SaveProgressDisplay2' );

  public command = new Command<void>();


  onUploadCompleted(photoKey: number, remainingPhotos: number) {

    this._log.debug( "onUploadCompleted", photoKey );
    if( 0 === remainingPhotos ) {

      this.command.resolve();
    }
  }

  onUploadFailed(photoKey: number, remainingPhotos: number) {

    this._log.error( "onUploadFailed", photoKey );
    if( 0 === remainingPhotos ) {

      this.command.resolve();
    }
  }

  onUploadPaused(photoKey: number, bytesTransferred: number, totalBytes: number) {

    const progress = Math.ceil((bytesTransferred / totalBytes) * 100);
    this._log.debug( `Uploading photo: ${progress}%` );
  }

  onUploadRunning(photoKey: number, bytesTransferred: number, totalBytes: number) {

    const progress = Math.ceil((bytesTransferred / totalBytes) * 100);
    this._log.debug( `Uploading photo: ${progress}%` );
  }

  dismiss() {

    this.command.resolve();
  }

  open() {

    ModalSpinnerComponent.open( this.dialog, this.command.toPromise() );
  }

  constructor( public dialog: MatDialog ) {

    // this.loading = loadingCtrl.create({
    //   content: 'Saving data'
    // });
  }


}

export class FirebaseSaver2 {


  public isSaving: boolean = false;




  async saveAspectAndPhotos( propertyKey: string, aspectAnswers: AspectAnswers ) {

    this.isSaving = true;
    let saveProgressDisplay = new SaveProgressDisplay2( this.dialog );

    setTimeout(() => {

      if( saveProgressDisplay ) {

        saveProgressDisplay.open();
      }
    }, 300);

    try {

      await FirebaseAnswers.writeValueXByKey( this.afDb, propertyKey, aspectAnswers );
      await this.savePhotos( propertyKey, saveProgressDisplay );

    } finally {

      this.isSaving = false;
      await saveProgressDisplay.dismiss();
      saveProgressDisplay = null;
    }

  }

  private async savePhotos( propertyKey: string,
                            listener: SaveProgressDisplay2 ) {

    if( this.photos.hasPhotos() && this.photos.hasChanges() ) {

      await this.photos.saveAll( propertyKey, listener );
    }
  }


  constructor( public afDb: AngularFireDatabase,
               public photos: PhotosProvider,
               public dialog: MatDialog
  ) {
  }




}
