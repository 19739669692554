import {IAnswer} from "../model/Answer";


// vvv 6D29F654-3414-4118-85B1-4E3272418BD9

export class ValueHelper {

  // private static _LOG: ILogger = LoggerFactory.build( 'ValueHelper' );

  static getContext(callerName: string, questionKey: string) {

    return `${callerName}/${questionKey}`;
  }

  public static valueToInteger( value ): number|null  {


    if (null === value) {

      return null;
    }

    if ('undefined' === typeof value) {

      return null;
    }


    if ('number' === typeof value) {
      return value;
    }

    if ('string' === typeof value) {

      if (0 === value.length) {

        return null;
      }

      const numberAnswer = parseInt(value, 10);
      if (isNaN(numberAnswer)) {

        console.error( 'isNaN(numberAnswer)', value);
        return null;
      }

      return numberAnswer;
    }

    console.error( 'unhandled type', typeof value, value);
    return null;

  }

  static getIntegerValue(answer: IAnswer, defaultValue: number): number {


    if (!answer) {

      const context = ValueHelper.getContext('getIntegerValue', answer.questionKey);
      console.error(context, '!answer');
      return defaultValue;
    }


    const numberAnswer = ValueHelper.valueToInteger( answer.value );

    if( null === numberAnswer ) {

      return defaultValue;
    }

    return numberAnswer;


    // const value = answer.value;
    //
    // if (null === value) {
    //
    //   return defaultValue;
    // }
    //
    // if ('number' === typeof value) {
    //   return value;
    // }
    //
    // if ('string' === typeof value) {
    //
    //   if (0 === value.length) {
    //     return defaultValue;
    //   }
    //
    //   const numberAnswer = parseInt(value, 10);
    //   if (isNaN(numberAnswer)) {
    //
    //     const context = ValueHelper.getContext('getIntegerValue', answer.questionKey);
    //     console.error(context, 'Number.isNaN( answer )', value);
    //     return defaultValue;
    //   }
    //
    //   const context = ValueHelper.getContext('getIntegerValue', answer.questionKey);
    //   console.warn(context, '\'string\' == typeof value', answer);
    //   return numberAnswer;
    // }
    //
    // const context = ValueHelper.getContext('getIntegerValue', answer.questionKey);
    // console.error(context, 'unhandled type', typeof value, value);
    // return defaultValue;
  }


}




// ^^^ 6D29F654-3414-4118-85B1-4E3272418BD9
