import {NocoDbProxy} from "./NocoDbProxy";
import {ITableListing} from "./TableInformation";
import {IProjectInformation} from "./meta/ProjectInformation";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {IListingReponse} from "./ListingReponse";
import {INocoProduct} from "./model/NocoProduct";


export class NocoDbProjectProxy {

  private static _log: ILogger = LoggerFactory.build( 'NocoDbProjectProxy' );

  public tableInformation: ITableListing;

  private static getTargetProject( projects: IProjectInformation[] ): IProjectInformation|null {

    for( const candidate of projects ) {
      if( 'products-playpen' === candidate.title ) {
        return candidate;
      }
    }

    NocoDbProjectProxy._log.error( "project not found; returning null" );
    return null;
  }

  public async getView<T>( table: string, view: string = 'API' ): Promise<IListingReponse<T>> {

    const url = `${this.proxy.nocoDbConfig.httpServer}/api/v1/db/data/v1/${this.projectInfo.id}/${table}/views/${view}?limit=1000`;
    NocoDbProjectProxy._log.debug( 'url', url );

    const response = await this.proxy.get( url ) as IListingReponse<T>;
    NocoDbProjectProxy._log.debug( 'response', response );

    return response;
  }


  public static async build( proxy: NocoDbProxy,
                             projects: IProjectInformation[] ): Promise<NocoDbProjectProxy> {

    const projectInfo = NocoDbProjectProxy.getTargetProject( projects );
    const answer = new NocoDbProjectProxy( proxy, projectInfo );

    // http://localhost:8080/api/v1/db/meta/projects/{projectId}/tables
    const url = `${answer.proxy.nocoDbConfig.httpServer}/api/v1/db/meta/projects/${projectInfo.id}/tables`;
    answer.tableInformation = await proxy.get( url );

    return answer;
  }


  public async readTable( tableName: string ) {

    const url = `${this.proxy.nocoDbConfig.httpServer}/api/v1/db/meta/projects/${this.projectInfo.id}/tables`;
  }


  private constructor( public proxy: NocoDbProxy,
                       public projectInfo: IProjectInformation) {
  }

}
