import {ValueObject} from './ValueObject';
import {IDependantDescriptor, Question, IScoreForNumberRange} from './Question';
import {ValueHelper} from "../util/ValueHelper";
import {IQuestionAnswer} from "./QuestionAnswer";
import {BehaviorSubject, Subscription} from 'rxjs';
import {EnumeratedConstantReference, IEnumeratedConstant} from "./EnumeratedConstant";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {QuestionKey} from "./QuestionKey";
import {EnumeratedAnswer} from "./EnumeratedAnswer";


export interface IAnswer {

  questionKey: QuestionKey; // uuid
  recorded?: string;
  value: any;
  $key?: string;
}

export class Answer extends ValueObject<IAnswer> implements IQuestionAnswer {


  private static _log: ILogger = LoggerFactory.build( 'AnswerReference' );

  answer: Answer;

  private _photoKeys: number[];


  private _dependant: Answer|null = null;


  private _dependantSubscription: Subscription|null = null;

  // only set if the answer is of type enum
  private _enumAnswer: EnumeratedAnswer|null = null;

  get dependant(): Answer {

    return this._dependant;
  }

  set dependant( dependant: Answer|null )  {

    // AnswerReference._log.debug( 'set dependant', dependant );

    // no-op ?
    if( this._dependant === dependant ) {
      return;
    }

    if( this._dependantSubscription ) {

      this._dependantSubscription.unsubscribe();
    }

    this._dependant = dependant;

    if( dependant ) {

      this._dependantSubscription = dependant.getSubject().subscribe( (value: Answer) => {

        // AnswerReference._log.debug( 'this.question.value.dependant', this.question.value.dependant );
        if( !value.isTruthy( this.question.value.dependant ) ) {

          this.value.value = null;
          // this.value.value = this.question.getDefaultAnswer();

          // hacky way to get the subscription to trigger ...
          this.value.value = this.value.value;
        }
      } );
    }

  }


  private _subject: BehaviorSubject<Answer> = null;


  public getSubject(): BehaviorSubject<Answer> {

    if( !this._subject ) {

      this._subject = new BehaviorSubject<Answer>( this );
    }
    return this._subject;
  }

  getEnumOption(): IEnumeratedConstant|null {

    return this._enumAnswer.getEnumOption( this );
  }


  getEnumScore(): number {

    return this._enumAnswer.getScore( this );
  }


  isTruthy( dependantDescriptor: IDependantDescriptor ): boolean {


    // if( 'ogDMk' === this.question.value.key ) {
    //   AnswerReference._log.debug( `'ogDMk' === this.question.value.key`, this, dependantDescriptor );
    // }

    if ( this.question.isBoolean ) {


      const value = this.value.value as boolean;


      if( 'undefined' !== typeof dependantDescriptor.falsyBooleanValue ) {

        if( value === dependantDescriptor.falsyBooleanValue ) {

          return false;
        }

        return true;
      }

      if( 'undefined' !== typeof dependantDescriptor.truthyBooleanValue ) {

        if( value === dependantDescriptor.truthyBooleanValue ) {

          // if( 'ogDMk' === this.question.value.key ) {
          //   AnswerReference._log.debug( `'ogDMk' === this.question.value.key`, 'value === dependantDescriptor.truthyBooleanValue', value === dependantDescriptor.truthyBooleanValue );
          // }

          return true;
        }
        // if( !dependantDescriptor.truthyBooleanValue && 'undefined' === typeof value ) {
        //
        //
        //   return true;
        // }

        return false;
      }



      if (value) {

        return true;
      }
      return false;
    }

    if( this.question.isEnum ) {

      return this._enumAnswer.isTruthy( this, dependantDescriptor );
    }

    if( this.question.isInteger ) {

      const value = ValueHelper.valueToInteger(this.value.value);

      if( null === value ) {

        Answer._log.warn( 'null === value', this );
        return false;
      }

      if( 0 === value ) {

        return false;
      }

      return true;
    }

    if( this.question.isTernary ) {

      const value = this.value.value as number;

      if( dependantDescriptor.truthyTernaryCodes ) {

        // console.log( 'this.question.isTernary', 'dependantDescriptor', dependantDescriptor );
        // console.log( 'this.question.isTernary', 'value', value );

        for( const candidate of dependantDescriptor.truthyTernaryCodes ) {

          if( candidate === value ) {

            return true;
          }
        }
        return false;
      }


      if( EnumeratedConstantReference.yes.codeAsNumber === value ) {

        return true;
      }

      return false;
    }

    return false;
  }

  removePhotoKeyAtIndex( index: number ) {

    const photoKeys = this.getPhotoKeys();
    photoKeys.splice( index, 1 );
  }

  addPhotoKey(): number {

    const now = new Date();
    const photoKey = now.getTime();
    this.getPhotoKeys().push( photoKey );
    return photoKey;
  }


  getIntegerValue( defaultValue: number = 0) {

    return ValueHelper.getIntegerValue( this.value, defaultValue );
  }


  getPhotoKeys(): number[] {

    if ( this._photoKeys ) {

      return this._photoKeys;
    }

    if ( this.value && this.value.value ) {

      // old school index ...
      if ( 'string' === typeof this.value.value || 'number' === typeof this.value.value ) {

        const count: number = ValueHelper.getIntegerValue( this.value , 0);

        this._photoKeys = [];
        this.value.value = this._photoKeys; // over-ride the old numeric value

        for (let i = 0; i < count; i++) {
          this._photoKeys.push( i );
        }
      } else if ( 'object' === typeof this.value.value && 'number' === typeof this.value.value.length ) { // new skool array

        this._photoKeys = this.value.value;
      }
    } else {

      this._photoKeys = [];
      this.value.value = this._photoKeys;
    }
    return this._photoKeys;
  }


  protected onSetValue( value: IAnswer | null) {

    this._photoKeys = null;

    if( this._enumAnswer ) {

      this._enumAnswer.onSetValue( value );
    }
    if( this._subject ) {

      this._subject.next( this );
    }
  }

  questionIsDisabled( question: Question ): boolean {

    return false;
  }

  constructor( public question: Question,
               value: IAnswer = null ) {

    super( value );

    if( question.isEnum ) {
      this._enumAnswer = new EnumeratedAnswer( question );
    }

    this.answer = this;

    if ( value ) {

      this.value = value;
    } else {

      this.value = {

        questionKey: question.value.key,
        recorded: null,
        value: question.getDefaultAnswer()
      };
    }
  }

}


/**
 * @deprecated use `Answer`
 */
export class AnswerReference extends Answer {}

