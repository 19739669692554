import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IFirebaseError} from "../../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {BaseSessionContext} from "../../service.session-context/BaseSessionContext";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ActivatedRoute, Params} from "@angular/router";
import {SignInParams} from "./SignInParams";
import {AuthenticateEmailLinkComponent} from "./component.authenticate-email-link/authenticate-email-link.component";
import {AlertDialogComponent} from "../component.alert-dialog/alert-dialog";
import {MatDialog} from "@angular/material/dialog";
import {IAuthenticationConfig} from "../../../environments/FacilitiesEvaluationEnvironment";
import {environment} from "../../../environments/environment";

/**
 * Generated class for the SignInHotelComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'sign-in',
  templateUrl: 'sign-in.html',
  styleUrls: ['sign-in.scss']
})
export class SignInComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'SignInComponent' );

  @Output() signInCompleted = new EventEmitter<void>();

  @Output() passwordResetRequested = new EventEmitter<string>();


  public authenticationConfig: IAuthenticationConfig = {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  };


  public onResetPassword( emailAddress: string ) {

    this._log.debug( 'emailAddress', emailAddress );
    this.passwordResetRequested.emit( emailAddress );
  }

  public onSignInCompleted() {

    this.signInCompleted.emit();
  }



  signInWithEmailLinkFailed( error: IFirebaseError ) {

    const title = 'Sign in failed';
    const message = "Not able to login automatically from link";

    const dialog = AlertDialogComponent.show( this.dialog, title, message );

    dialog.afterClosed().subscribe(result => {

      console.log('dialog was closed', result );
    });

  }


  async onParamsUpdated( params:Params ) {

    this._log.debug( 'params', params );

    const signInParams = SignInParams.build( params );
    if( !signInParams ) {
      return;
    }

    this._log.debug( 'signInParams', signInParams );

    const email = AuthenticateEmailLinkComponent.loadEmail();
    if( !email ) {
      this._log.warn( '!email' );
      return;
    }

    const error: IFirebaseError = await this.sessionContext.signInWithEmailLink( email, window.location.href );

    if( !error ) {

      this.onSignInCompleted();
      return;
    }

    this._log.error( 'error', error );
    this.signInWithEmailLinkFailed( error );

  }

  ngOnInit(): void {


    if( environment.authenticationConfig ) {

      this.authenticationConfig = environment.authenticationConfig;

    }

  }

  constructor( public sessionContext: BaseSessionContext,
               public afAuth: AngularFireAuth,
               route: ActivatedRoute,
               public dialog: MatDialog ) {

    route.queryParams.subscribe( (next:Params) => {

      this.onParamsUpdated( next );
    })

  }



}
