
import {javaScriptLibEnvironment} from "../environments/javascript.lib.environment";
import {IAnswer} from "../model/Answer";


export enum LogLevel {

  // vvv https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
  // ^^^ https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
}

export interface ILogEvent {

  codeContext: string;
  context: string|{ [key: string]: string|number|boolean; };
  correlationId: string;
  environmentId: string;
  execSystem: string;
  message: string;
  severity: LogLevel;
  time: number;
  trace?: string;
}

export class LogEvent {

  httpRequest?: any; // express HTTP request

  private static _build( codeContext: string, correlationId: string, message: string, severity: LogLevel ): LogEvent {

    const value: ILogEvent = {
      codeContext,
      context: {},
      correlationId,
      environmentId: javaScriptLibEnvironment.logging.environmentId,
      execSystem: javaScriptLibEnvironment.logging.execSystem,
      message,
      severity,
      time: new Date().getTime(),
    }

    const answer = new LogEvent( value );
    return answer
  }


  public static debug( codeContext: string, correlationId: string, message: string ): LogEvent {

    return LogEvent._build( codeContext, correlationId, message, LogLevel.DEBUG );
  }

  public static error( codeContext: string, correlationId: string, message: string ): LogEvent {

    return LogEvent._build( codeContext, correlationId, message, LogLevel.ERROR );
  }

  public static info( codeContext: string, correlationId: string, message: string ): LogEvent {

    return LogEvent._build( codeContext, correlationId, message, LogLevel.INFO );
  }

  public static warn( codeContext: string, correlationId: string, message: string ): LogEvent {

    return LogEvent._build( codeContext, correlationId, message, LogLevel.WARN );
  }

  addContext( name: string, value: boolean|number|string ) {

    if( typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string' ) {

      this.value.context[name] = value;
    } else {

      console.error( `unhandled type: name: ${name}; codeContext: '${this.value.codeContext};'; message: ${this.value.message}; correlationId: ${this.value.correlationId}`);
    }

  }

  // toGoogleLogEntry() {
  //
  //   // vvv https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry
  //   return {
  //     textPayload: this.value.message,
  //     jsonPayload: this.value,
  //     severity: this.severity,
  //   };
  //   // ^^^ https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry
  // }
  //
  //
  // toJson() {
  //
  //   const answer = {};
  //
  //   Object.assign( answer, this.value );
  //   answer['severity'] = this.severity;
  //
  //   return answer;
  // }
  //

  public setTrace( trace: string ): LogEvent {

    this.value.trace = trace;
    return this;
  }

  private constructor( public value: ILogEvent ) {
  }


}

