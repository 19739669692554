import {ValueObject} from "../model/ValueObject";




export interface IPropertyMetaData {

  // new fields added 2022-08-08 ...
  version?: number[];
}

export interface IBaseProperty {

  address: string|string[];
  country: string;
  email: string;
  name: string;

  // new fields added 2022-08-29 ...
  _meta?: IPropertyMetaData;
}

export interface IProperty extends IBaseProperty{

  // if _meta.version[0] === 2
  address: string|string[];
  country: string;
  email: string;
  name: string;
  orgType?: string;
  postCode: string;
  telephone: string;
  salesForceId?: string;
  website: string;

  // new fields added 2019-09-19 ...
  city?: string;
  numberOfBedrooms?: number;
  parentCompany?: string;
  starRating?: number;
  telephoneCountry?: string;

  // new fields added 2022-02-14 ...
  excludePhotos?: boolean;

  // new fields added 2022-08-29 ...
  _meta?: IPropertyMetaData;
}

// taken from `IMerckProperty` in `node.app.csv-to-json/src/model/MerckProperty.ts`
export interface IProperty2 extends IBaseProperty {

  country: string;
  name: string;
  address: string[];
  email: string;

  // new fields added 2022-09-12 ...
  _meta?: IPropertyMetaData;

}



export class PropertyReference extends ValueObject<IProperty> {


  private _searchString: string = null;


  static buildId( country: string, name: string, company: string = null  ): string {

    if( company ) {

      company = company.toLowerCase();
    }
    country = country.toLowerCase();

    let answer = name;
    answer = answer.toLowerCase();
    answer = answer.replace( /\(/g, '-' );
    answer = answer.replace( /\)/g, '-' );
    answer = answer.replace( /\//g, '-' );
    answer = answer.replace( / /g, '-' );
    answer = answer.replace( /--/g, '-' );

    if( company ) {

      return `${company}-${country}-${answer}`;
    } else {

      return `${country}-${answer}`;
    }
  }

  get searchString(): string {

    if( null !== this._searchString ) {

      return this._searchString;
    }

    let searchString = '';

    if( this.value.name ) {

      searchString += this.value.name + '/';
    }
    if( this.value.address ) {

      searchString += this.value.address + '/';
    }

    if( this.value.salesForceId ) {

      searchString += this.value.salesForceId + '/';
    }

    this._searchString = searchString.toLowerCase();
    return this._searchString;
  }

  clone(): PropertyReference {

    let value: IProperty = null;
    if( this.value ) {
      value = Object.assign( {}, this.value );
    }

    return new PropertyReference( value, this.propertyKey );
  }

  protected onSetValue(value: IProperty | null) {

    if( value.salesForceId ) {

      delete value.salesForceId;
    }

    if( value.starRating ) {

      delete  value.starRating;
    }

  }

  constructor( value: IProperty|null = null,
               public propertyKey: string|null = null ) {

    super( value );
  }

}
