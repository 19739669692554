import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {PwaSessionContextProvider} from "../service.pwa-session-context/pwa-session-context";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {PopupBootstrap} from "./PopupBootstrap";
import {environment} from "../environments/environment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {HttpClient} from "@angular/common/http";
import {
  ConfirmCancelDialogComponent
} from "../browser.lib.evaluation-tool/module.base-components/component.confirm-cancel-dialog/confirm-cancel-dialog";
import {MatDialog} from "@angular/material/dialog";
import type {
  FireworksDirective,
  FireworksOptions
} from '@fireworks-js/angular';
import { timer } from 'rxjs';


@Component({
  selector: 'page-home',
  templateUrl: 'home.html',
  styleUrls: ['home.scss']
})
export class HomePage implements OnInit{



  private log = LoggerFactory.build( 'HomePage' );

  productType = environment.productConfig.productType;
  productIsBanking: boolean = EProductType.banking == environment.productConfig.productType;

  productTypes = {
    brandHome: EProductType.brandHome,
    facilities: EProductType.facilities,
    manufacturing: EProductType.manufacturing,
  };

  icons = {
    faBars
  };


  @ViewChild('fireworks') fireworks?: FireworksDirective;

  fireworksEnabled = false;
  options: FireworksOptions = {
    opacity: 0.5
  }

  submittingAsCompleted = false;

  test() {

    this.log.debug( "constructor", moment() );

    let start = moment( '2016-06-06T12:00');
    let end = moment( '2016-06-06T13:13');

    this.log.debug( "constructor", start );

    // let difference = end.subtract( start );
    var duration = moment.duration(end.diff(start));
    this.log.debug( "constructor", duration.asMinutes() );
  }


  async ngOnInit() {



  }


  getSectionTitle( $index: number ) {
    let index = $index + 1;
    // if( this.productIsBanking ) {
    //
    //   index++;
    // }
    return `Section ${index}`;
  }

  async onSectionOpen($index: number ) {

    this.sessionContext.propertyContext.willEditAnswers();
    this.sessionContext.propertyContext.aspectAnswers[$index].willEditAnswers();
    AppRouteManifest.ASPECT_SECTION.navigateToAspect( this.router, $index, 0 );

    {
      const evaluationStatus = this.sessionContext.propertyContext.evaluationStatus;
      if( !evaluationStatus.isStarted() ) {

        this.sessionContext.evaluationStarted();
      }
    }

  }


  // toggleFireworks(): void {
  //   this.enabled = !this.enabled
  // }
  //
  // waitStop(): void {
  //   this.fireworks?.waitStop()
  // }
  //
  onNext() {

    this.onSectionOpen( 0 );
  }

  onShowScore() {

    this.sessionContext.propertyContext.showCongratulationsText = false;
    AppRouteManifest.SCORE.navigate( this.router );
  }


  onOpenDocuments() {

    AppRouteManifest.DOCUMENTS.navigate( this.router );
  }


  openPopup() {

    PopupBootstrap.showMojoPopup( this.sessionContext.propertyContext.propertyKey );
  }

  private async _showFireworks() {

    if( environment.preview && environment.preview.fireworksEnabled ) {

      this.fireworksEnabled = true;

      // [typescript - How to do a timer in Angular 5 - Stack Overflow](https://stackoverflow.com/questions/50455347/how-to-do-a-timer-in-angular-5)
      const aTimer = timer(10000 );

      aTimer.subscribe(async val => {

        this.log.debug( "stopping fireworks" );
        await this.fireworks?.waitStop();
        this.fireworksEnabled = false;
      });
    }

  }

  private async _evaluationCompleted() {

    await this.sessionContext.evaluationCompleted();

    this._showFireworks();

  }

  async onSubmit() {

    this.log.debug( "onSubmit" );

    // this.toggleFireworks();
    // return;

    // this.enabled = true;

    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: "Please confirm that you consider the evaluation complete and are ready for it to be reviewed.",
      title: 'Submit for review?',
      okLabel: 'SUBMIT'
    });

    dialog.afterClosed().subscribe(result => {

      console.log('The dialog was closed', result );

      if( result ) {

        try {

          this.submittingAsCompleted = true;
          this._evaluationCompleted();

        } finally {
          this.submittingAsCompleted = false;
        }
      } else {

        // this._showFireworks();
      }

    });

  }



  public reasonSubmitDisabled(): string|null {

    if( this.submittingAsCompleted ) {

      return "Submitting ...";
    }

    if( this.sessionContext.propertyContext.evaluationStatus.isCompleted() ) {

      return "Successfully submitted";
    }

    if( !this.sessionContext.propertyContext.isEvaluationCompleted() ) {

      return "Complete evaluation";
    }

    return null;
  }

  submitSectionIndexText(): string {

    const answer = this.reasonSubmitDisabled();

    if( null != answer ) {

      return answer;
    }

    return "Submit completed evaluation";

  }





  constructor( public router: Router,
               public route: ActivatedRoute,
               public sessionContext: PwaSessionContextProvider,
               public applicationContext: PwaApplicationContextProvider,
               public dialog: MatDialog,
  ) {}


}
