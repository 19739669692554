import {AngularFireDatabase} from '@angular/fire/compat/database';
import {first} from 'rxjs/operators';
import {AnswersReference} from '../../../../javascript.lib.mojo-base/model/Answers';
import {IAnswer} from '../../../../javascript.lib.mojo-base/model/Answer';
import {HotelReference} from '../../../../javascript.lib.mojo-base/hotel/model/Hotel';
import {EvaluationSection} from '../../../../javascript.lib.mojo-base/model/module/EvaluationSection';
import {AspectAnswers} from '../../../../javascript.lib.mojo-base/model/AspectAnswers';
import {FirebaseAnswersRedux} from "../../../../javascript.lib.mojo-base/product.common/firebase/FirebaseAnswersRedux";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {environment} from "../../../../environments/environment";


interface IAnswersMeta {
  completed: boolean;
}

export interface IFirebaseAnswersListener {

  onReadValueXByKey( propertyKey: string,
                     aspectQuestions: EvaluationSection,
                     answers: { [key: string]: IAnswer; }|null );

  onWriteValueXByKey( propertyKey: string, aspectAnswers: AspectAnswers );
}

export class FirebaseAnswers {



  private static log = LoggerFactory.build( 'FirebaseAnswers' );

  public static listener: IFirebaseAnswersListener = null;



  // static async read( firebaseConnection: FirebaseConnection, hotelKey: string): Promise<HotelReference|null> {
  //
  //   const path = FirebaseHotels.getPath( hotelKey );
  //
  //   const value = await RealtimeDatabase.getValue<IHotel[]>( firebaseConnection, path );
  //
  //   if ( value ) {
  //
  //     return new HotelReference( value[0], hotelKey );
  //   }
  //   return null;
  // }


  /**
   * @deprecated use `FirebaseAnswersRedux.readGeneral`
   * @param afDb
   * @param hotelKey
   */
  static async readGeneral( afDb: AngularFireDatabase, hotelKey: string ): Promise<AnswersReference> {

    const value = await FirebaseAnswers.readValue( afDb, hotelKey, 'general' );
    return new AnswersReference( 'general', value, hotelKey );
  }

  /**
   * @deprecated use `FirebaseAnswersRedux.readGeneralValue`
   * @param afDb
   * @param hotelKey
   */
  static async readGeneralValue(afDb: AngularFireDatabase, hotelKey: string ): Promise<{ [key: string]: IAnswer; }|null> {

    return FirebaseAnswers.readValue( afDb, hotelKey, 'general' );
  }

  /**
   * @deprecated use `FirebaseAnswersRedux.readBathroom`
   * @param afDb
   * @param hotelKey
   */
  static async readBathroom( afDb: AngularFireDatabase, hotelKey: string ): Promise<AnswersReference> {

    const value = await FirebaseAnswers.readValue( afDb, hotelKey, 'bathroom' );
    return new AnswersReference( 'bathroom', value, hotelKey );
  }

  /**
   * @deprecated use `FirebaseAnswersRedux.readBathroomValue`
   * @param afDb
   * @param hotelKey
   */
  static async readBathroomValue(afDb: AngularFireDatabase, hotelKey: string ): Promise<{ [key: string]: IAnswer; }|null> {

    return FirebaseAnswers.readValue( afDb, hotelKey, 'bathroom' );
  }

  /**
   * @deprecated use `FirebaseAnswersRedux.readBedroom`
   * @param afDb
   * @param hotelKey
   */
  static async readBedroom( afDb: AngularFireDatabase, hotelKey: string ): Promise<AnswersReference> {

    const value = await FirebaseAnswers.readValue( afDb, hotelKey, 'bedroom' );
    return new AnswersReference( 'bedroom', value, hotelKey );
  }

  /**
   * @deprecated use `FirebaseAnswersRedux.readBedroomValue`
   * @param afDb
   * @param hotelKey
   */
  static async readBedroomValue(afDb: AngularFireDatabase, hotelKey: string ): Promise<{ [key: string]: IAnswer; }|null> {

    return FirebaseAnswers.readValue( afDb, hotelKey, 'bedroom' );
  }



  public static async readValueX( afDb: AngularFireDatabase,
                                  hotel: HotelReference,
                                  aspectQuestions: EvaluationSection ): Promise<{ [key: string]: IAnswer; }|null> {


    const path = FirebaseAnswersRedux.getPathX( hotel.hotelKey, aspectQuestions );
    const answer = await afDb.object( path).valueChanges().pipe(first()).toPromise() as { [key: string]: IAnswer; };
    return answer;
  }


  public static async readValueXByKey( afDb: AngularFireDatabase,
                                       propertyKey: string,
                                       aspectQuestions: EvaluationSection ): Promise<{ [key: string]: IAnswer; }|null> {


    const path = FirebaseAnswersRedux.getPathX( propertyKey, aspectQuestions );
    const answer = await afDb.object( path).valueChanges().pipe(first()).toPromise() as { [key: string]: IAnswer; };

    if( answer && FirebaseAnswers.listener ) {
      try {


        FirebaseAnswers.listener.onReadValueXByKey( propertyKey, aspectQuestions, answer );

      } catch ( e ) {

        FirebaseAnswers.log.error( 'listener raised error', e );
      }
    }
    return answer;
  }

  public static async writeValueXByKey( afDb: AngularFireDatabase,
                                        propertyKey: string,
                                        aspectAnswers: AspectAnswers ): Promise<void> {


    const value = aspectAnswers.value;
    if( !value ) {

      FirebaseAnswers.log.error( 'writeValue', '!value', propertyKey,  aspectAnswers.aspectQuestions.value.name );
      return;
    }

    {
      const keys = Object.keys( value );
      if( 0 === keys.length ) {

        FirebaseAnswers.log.error( 'writeValue', '0 === keys.length', propertyKey, aspectAnswers.aspectQuestions.value.name );
        return;
      }
    }

    const path = FirebaseAnswersRedux.getPathX( propertyKey, aspectAnswers.aspectQuestions );

    if( FirebaseAnswers.listener ) {
      try {

        FirebaseAnswers.listener.onWriteValueXByKey( propertyKey, aspectAnswers );

      } catch ( e ) {

        FirebaseAnswers.log.error('listener raised error', e);

      }
    }


    return afDb.object( path ).set( value );
  }


  public static async writeValueX( afDb: AngularFireDatabase,
                                   hotel: HotelReference,
                                   aspectAnswers: AspectAnswers ): Promise<void> {

    return FirebaseAnswers.writeValueXByKey( afDb, hotel.hotelKey, aspectAnswers );
  }

  private static getMetaPath( hotel: HotelReference, aspectQuestions: EvaluationSection ) {

    const answer = `${environment.productConfig.firebaseAnswersRoot}/${hotel.hotelKey}/_meta`;
    FirebaseAnswers.log.debug( 'answer', answer);
    return answer;
  }

  public static async writeMeta( afDb: AngularFireDatabase,
                                 hotel: HotelReference,
                                 aspectQuestions: EvaluationSection,
                                 value: IAnswersMeta ): Promise<void> {

    const path = FirebaseAnswers.getMetaPath( hotel, aspectQuestions );
    FirebaseAnswers.log.debug( 'writeMeta', 'path', path );
    return afDb.object( path ).set( value );
  }


  private static async readValue( afDb: AngularFireDatabase, hotelKey: string, section: string ): Promise<{ [key: string]: IAnswer; }|null> {

    const path =  FirebaseAnswersRedux.getPath(hotelKey,section);
    FirebaseAnswers.log.debug( 'path', path);

    const answer = await afDb.object( path).valueChanges().pipe(first()).toPromise() as { [key: string]: IAnswer; };
    return answer;
  }


  private static writeValue( afDb: AngularFireDatabase, hotelKey: string, section: string, value: { [key: string]: IAnswer; } ): Promise<void> {

    if( !value ) {

      FirebaseAnswers.log.error( 'writeValue', '!value', hotelKey, section );
      return;
    }

    {
      const keys = Object.keys( value );
      if( 0 === keys.length ) {

        FirebaseAnswers.log.error( 'writeValue', '0 === keys.length', hotelKey, section );
        return;
      }
    }

    const path = FirebaseAnswersRedux.getPath(hotelKey,section);
    return afDb.object( path ).set( value );
  }

  static async writeBathroom(afDb: AngularFireDatabase, answers: AnswersReference ): Promise<void> {

    await FirebaseAnswers.writeValue( afDb, answers.hotelKey, 'bathroom', answers.value );
  }


  static async writeBathroomValue(afDb: AngularFireDatabase, hotelKey: string, value: { [key: string]: IAnswer; } ): Promise<void> {

    await FirebaseAnswers.writeValue( afDb, hotelKey, 'bathroom', value );
  }

  static async writeBedroom(afDb: AngularFireDatabase, answers: AnswersReference ): Promise<void> {

    await FirebaseAnswers.writeValue( afDb, answers.hotelKey, 'bedroom', answers.value );
  }

  static async writeBedroomValue(afDb: AngularFireDatabase, hotelKey: string, value: { [key: string]: IAnswer; } ): Promise<void> {

    await FirebaseAnswers.writeValue( afDb, hotelKey, 'bedroom', value );
  }

  static async writeGeneral(afDb: AngularFireDatabase, answers: AnswersReference ): Promise<void> {

    await FirebaseAnswers.writeValue( afDb, answers.hotelKey, 'general', answers.value );
  }

  static async writeGeneralValue(afDb: AngularFireDatabase, hotelKey: string, value: { [key: string]: IAnswer; } ): Promise<void> {

    await FirebaseAnswers.writeValue( afDb, hotelKey, 'general', value );
  }



}
