import {IFirebaseFirestore} from "../../javascript.lib.mojo-base/firebase/IFirebaseFirestore";
import firebase from "firebase/compat";
import Firestore = firebase.firestore.Firestore;


export class FirebaseFirestoreVersion9 implements IFirebaseFirestore {

  setValue<T>(path: string, value: T): Promise<void> {

    return this._firestore.doc( path ).set( value );
  }

  constructor( private _firestore: Firestore ) {
  }
}
