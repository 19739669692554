import {IMenuItem} from "../module.base-components/component.side-menu/side-menu.component";
import {
  ConfirmCancelDialogComponent
} from "../module.base-components/component.confirm-cancel-dialog/confirm-cancel-dialog";
import {MatDialog} from "@angular/material/dialog";


export abstract class BaseSideMenuModel {

  public menuItems: IMenuItem[] = [];

  public abstract doLogout(): Promise<void>;

  onLogout( personalIdentifier: string) {




    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: `You are logged in as '${personalIdentifier}'`,
      title: 'Logout?',
      okLabel: 'LOGOUT'
    });

    dialog.afterClosed().subscribe(result => {

      console.log('The dialog was closed', result );
      if( result ) {

        this.doLogout();
      }
    });


  }

  constructor( public dialog: MatDialog ) {
  }
}
