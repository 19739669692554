




<div mclass="question-card">

  <div mclass="help">
    <help [answer]="answer"></help>
  </div>


  <div mclass="question-label">
    <div style="display: none">
      {{answer.question.value.key}}
    </div>
    <span mclass="question-label-text"
          [ngClass]="{'disabledQuestion':_disabled}">
        {{answer.question.value.label}}
      </span>
  </div>

  <div mclass="answer-widget">

    <mat-form-field cclass="mat-form-field">
      <input matInput
             type="number"
             [(ngModel)]="model"
             [formControl]="formControl"
      >
      <mat-error *ngIf="formControl&&formControl.hasError('required')">
        <strong>Required</strong>
      </mat-error>
      <mat-error *ngIf="formControl&&formControl.hasError('min')">
        <strong>Too small</strong>
      </mat-error>
      <mat-error *ngIf="formControl&&formControl.hasError('max')">
        <strong>Too big</strong>
      </mat-error>
    </mat-form-field>


  </div>

</div>


<!--<div fxLayout="column" fxLayoutGap="10px" style="padding: 10px 8px 0px 8px;">-->

<!--  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">-->

<!--    <div fxFlex="16" [fxFlex.xs]="16">-->
<!--      <help [answer]="answer"></help>-->
<!--    </div>-->

<!--    <div fxFlex="67" [fxFlex.xs]="60">-->
<!--      <div style="display: none">{{answer.question.value.key}}</div>-->
<!--      <span-->
<!--        [ngClass]="{'disabledQuestion':_disabled}">-->
<!--        {{answer.question.value.label}}-->
<!--      </span>-->
<!--    </div>-->

<!--    <div fxFlex="16" [fxFlex.xs]="23">-->
<!--      <mat-form-field style="width: 100%">-->
<!--        <input matInput-->
<!--               type="number"-->
<!--               [(ngModel)]="model"-->
<!--               [formControl]="formControl"-->
<!--        >-->
<!--        <mat-error *ngIf="formControl&&formControl.hasError('required')">-->
<!--          <strong>Required</strong>-->
<!--        </mat-error>-->
<!--        <mat-error *ngIf="formControl&&formControl.hasError('min')">-->
<!--          <strong>Too small</strong>-->
<!--        </mat-error>-->
<!--        <mat-error *ngIf="formControl&&formControl.hasError('max')">-->
<!--          <strong>Too big</strong>-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
