// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {BankingAccessibilityProduct} from "../product.banking/model/BankingAccessibilityProduct";
import {TemplateProduct} from "../model.product/TemplateProduct";

export const environment: IProductEnvironment = {

  filename: 'environment.banking-856683.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    apiKey: "AIzaSyC3D0HILBhFEoOJuHbkr9mQRMc6urTce40",
    authDomain: "banking-856683.firebaseapp.com",
    databaseURL: "https://banking-856683-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "banking-856683",
    storageBucket: "banking-856683.appspot.com",
    messagingSenderId: "344709463445",
    appId: "1:344709463445:web:9ee8cd6c973f8d614e4a9c"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-banking-856683.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    // httpServer: 'http://spot.local:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/banking',
    product: TemplateProduct.INSTANCE,
    productType: EProductType.banking,
  },


};
