import {Component, Input, OnInit} from '@angular/core';
import {PageQuestions} from "../../../javascript.lib.mojo-base/model/module/PageQuestions";
import {EvaluationSection} from "../../../javascript.lib.mojo-base/model/module/EvaluationSection";

/**
 * Generated class for the AspectHeaderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'aspect-header',
  templateUrl: 'aspect-header.html'
})
export class AspectHeaderComponent implements OnInit {

  sectionQuestions: PageQuestions = null;
  @Input() aspectQuestions: EvaluationSection = null;
  _sectionIndex: number = null;

  progress: number = 0;

  @Input() set sectionIndex( value: number) {

    if( this._sectionIndex !== value ) {

      this._sectionIndex = value;
      this.init();
    }
  };

  get sectionIndex(): number {

    return this._sectionIndex;
  }

  @Input() sectionTitle: string = null;

  headerText: string = '';


  init() {

    {
      const sectionNumber = this.sectionIndex+1;
      const evaluationSectionsCount = this.aspectQuestions.evaluationSections.length;
      if( sectionNumber === evaluationSectionsCount ) {

        this.progress = 100;
      } else {

        this.progress = Math.round(100 * (sectionNumber/evaluationSectionsCount));
      }
    }

    this.sectionQuestions = this.aspectQuestions.evaluationSections[this.sectionIndex];

    {
      const headerText = this.sectionQuestions.value.headerText;
      if( headerText ) {

        this.headerText =  headerText;
      } else {

        this.headerText = this.aspectQuestions.value.name;
      }

    }

    this.headerText += ` (${this.sectionIndex+1} of ${this.aspectQuestions.evaluationSections.length})`;

  }
  // headerText() {
  //
  //   if( this.sectionQuestions.value.headerText ) {
  //
  //     return this.sectionQuestions.value.headerText;
  //   }
  //
  //   return this.aspectQuestions.value.name;
  // }

  ngOnInit(): void {

    this.init();
  }


  constructor() {
  }


}
