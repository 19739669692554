import {Answer} from "../Answer";
import {IScoreForNumberRange, IType, Question} from "../Question";
import {ValueHelper} from "../../util/ValueHelper";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {IScoreCalculator} from "./ScoreCalculator";
import {EnumeratedConstantReference} from "../EnumeratedConstant";


export class DefaultScoreCalculator implements IScoreCalculator {

  public static readonly INSTANCE = new DefaultScoreCalculator();

  private _log: ILogger = LoggerFactory.build( 'DefaultScoreCalculator' );



  public static calculateScorePercentage( score: number, maxScore: number ): number {

    if( 0 === maxScore) {
      return 0;
    }

    const answer = Math.round((score/ maxScore) * 100);
    return answer;
  }

  public static calculateScoreOutOf10( score: number, maxScore: number ): string {

    if( 0 === maxScore ) {
      return "0";
    }


    const percentage = DefaultScoreCalculator.calculateScorePercentage( score, maxScore ) / 10;

    if( 0 == percentage ) {
      return "0";
    }

    if( 100 == percentage ) {
      return "10";
    }

    return percentage.toFixed(1);
  }


  private _getScoreForValue( value: number, scoring: IScoreForNumberRange[]) {

    for( const scoreRange of scoring ) {

      if( scoreRange.lessThan ) {

        if( value >= scoreRange.lessThan ) {
          continue;
        }
      }
      if( value >= scoreRange.greaterThanOrEqualTo ) {

        return scoreRange.score;
      }
    }


    return 0;
  }

  // _getEnumScore( answer: AnswerReference ): number {
  //
  //   const question = answer.question;
  //
  //   let enumOption = answer.getEnumOption();
  //
  //   if( null === enumOption ) {
  //
  //     return 0;
  //   }
  //
  //   for ( const score of question.value.type2.typeEnum.scoring ) {
  //
  //     if( enumOption.codeAsString === score.codeAsString ) {
  //
  //       return score.score;
  //     }
  //   }
  //
  //   this._log.warn( `no matching enum found`, 'enumOption', enumOption, 'answer.question.value.key', answer.question.value.key );
  //   return 0;
  //
  // }


  public calculateScore(answer: Answer, type2?: IType ): number {

    const question: Question = answer.question;

    if ( null === answer.value.value ) {
      return 0;
    }

    if ( question.isBoolean ) {

      const scoring = type2.typeBoolean.scoring;

      const value = answer.value.value as boolean;
      if ( true === value ) {

        return scoring.onTrue;
      } else if ( false === value ) {

        return scoring.onFalse;
      }
      return 0;
    }

    if( question.isEnum ) {

      return answer.getEnumScore();
    }


    if ( question.isInteger ) {

      const scoring = type2.typeInteger.scoring;

      const value: number = ValueHelper.getIntegerValue( answer.value, 0  );

      if( 'number' !== typeof scoring.length ) {

        this._log.error( `'number' !== typeof answer.question.value.type2.typeInteger.scoring.length`, 'answer.question', answer.question );
        return 0;
      }
      return this._getScoreForValue( value, scoring );
    }

    if( question.isCmMeasurement ) {

      const scoring = type2.typeCmMeasurement.scoring;

      const value: number = ValueHelper.getIntegerValue( answer.value, 0  );

      if( 'number' !== typeof scoring.length ) {

        this._log.error( `'number' !== typeof scoring.length`, 'answer.question', answer.question );
        return 0;
      }

      return this._getScoreForValue( value, scoring );
    }

    if ( question.isPhoto ) {

      let count = 0;
      if( Array.isArray( answer.value.value ) ) {

        const arrayValue: [] = answer.value.value as [];
        count = arrayValue.length;
      } else { // super old school

        count = ValueHelper.getIntegerValue( answer.value, 0 );
      }
      if ( 0 < count ) {
        return question.value.maximumScore;
      }
      return 0;
    }

    if( question.isTernary ) {

      const value: number = ValueHelper.getIntegerValue( answer.value, 0  );

      const scoring = type2.typeTernary.scoring;
      if( 1 === value ) {

        return scoring.onTrue;
      }

      if( 0 === value ) {

        if( scoring.onFalse ) {

          return scoring.onFalse;
        } else {

          return 0;
        }
      }

      if( scoring.onNotApplicable ) {
        return scoring.onNotApplicable;
      }

      return 0;
    }

    if ( question.isText ) {

      let value = answer.value.value as string;
      const scoring = type2.typeText.scoring;
      if( value ) {

        if( scoring.anyText ) {

          value = value.trim();
          if( 0 !== value.length ) {

            return scoring.anyText;
          }
        }
      }
      return 0;
    }

    this._log.warn( 'unhandled question', this );
    return 0;
  }

  private _calculateScore( answer: Answer ): number {

    return this.calculateScore( answer, answer.question.value.type2 );
  }

  getScore(answer: Answer, useIsEssential: boolean  = true ): number {


    if( answer.dependant ) {

      if( answer.dependant.isTruthy( answer.question.value.dependant ) ) {

        return this._calculateScore( answer );
      } else {

        if( useIsEssential && answer.question.value.dependant.isEssential ) {

          // has dependency on another answer that is essential
          return 0;
        } else {

          // has dependency on another answer that is *not* essential
          return answer.question.value.maximumScore;
        }
      }
    }

    // no dependent
    return this._calculateScore( answer );

  }


  private constructor() {
  }

}
