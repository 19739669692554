import {IProduct} from "./IProduct";
import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../javascript.lib.mojo-base/model/EnumeratedConstant";
import {BankingEnumeratedConstants} from "../product.banking/model/BankingEnumeratedConstants";
import {BankingSummaryStructure} from "../product.banking/model/BankingSummaryStructure";
import {BankingQuestions} from "../product.banking/model/BankingQuestions";
import {BankingReportSections} from "../product.banking/model/BankingReportSections";
import {ScoreRuleSet} from "../javascript.lib.mojo-base/model/score/ScoreRuleSet";
import {EvaluationSection} from "../javascript.lib.mojo-base/model/module/EvaluationSection";
import {BankingSummaryStructureEmpty} from "../product.banking/model/BankingSummaryStructureEmpty";
import {BankingQuestionsEmpty} from "../product.banking/model/BankingQuestionsEmpty";
import {BankingReportSectionsEmpty} from "../product.banking/model/BankingReportSectionsEmpty";

export class TemplateProduct implements IProduct {


  public static INSTANCE: TemplateProduct = new TemplateProduct();

  summarySections = [];
  evaluationSections = [];

  questions = EvaluationSection.ARRAY.toQuestionSet( [] );

  reportSections = [];
  version = "1.0.0";
  scoreRules = new ScoreRuleSet([]);

  printCodes() {

  }


  getAllEnums(): IEnumeratedConstant[] {

    const answer = [
      BankingEnumeratedConstants.YES,
      BankingEnumeratedConstants.NO,
      BankingEnumeratedConstants.NOT_APPLICABLE,
    ];
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.ACCESS_TO_SIGN_LANGUAGE_INTERPRETER, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.ACCESS_TO_LANGUAGE_TRANSLATION_SERVICES, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.ACCESSIBILITY_CERTIFICATION, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.CAFETERIA_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.CAR_PARK_FACILITIES, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.DOOR_HANDLE_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.DOOR_PUSH_PAD_LOCATION, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.ENTRANCE_DOOR_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.FURNITURE_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.INNER_DOOR_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.CARD_MACHINE_KEYPAD_LAYOUT, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.STEP_FREE_ACCESS_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.TOILET_DOOR_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.TOILET_SINK_HANDLE_TYPE, answer );
    EnumeratedConstantReference.addEnumsFromObject( BankingEnumeratedConstants.TOUCH_SCREEN_REACHABLE, answer );


    console.log( 'answer', answer  );
    return answer;
  }


  public constructor() {

  }

}
