
import firebase from 'firebase/compat/app';
import database = firebase.database;
import {EvaluationUser, IEvaluationUser, IEvaluationUser2} from "../EvaluationUser";
import {IHotelUser} from "../../hotel/model/HotelUser";
import {IFirebaseConnection} from "../../firebase/FirebaseConnection";
import {LoggerFactory} from "../../log/LoggerFactory";

export class FirebaseEvaluationUser {

  private static log = LoggerFactory.build( 'FirebaseEvaluationUser' );

  private static getPath( userUid: string ) {

    return `/users/evaluation/${userUid}`;
  }

  /**
   * @param firebaseConnection
   * @param userUid
   */
  static async readLegacyHotelsReference(firebaseConnection: IFirebaseConnection, userUid: string ): Promise<EvaluationUser|null> {


    const path = `/users/hotels/${userUid}`;

    try {

      const value: EvaluationUser = await firebaseConnection.database2.getValue( path );

      if ( value ) {

        return EvaluationUser.build(value, userUid);
      }

    } catch ( e ) {

      console.log( e );
    }
    return null;
  }


  static async readAllUsers( fbDb: database.Database ): Promise<{ [userUid: string]: IEvaluationUser; }> {


    const path = `/users/evaluation/`;
    const snapshot = await fbDb.ref( path ).once( 'value' );
    const answer: { [userUid: string]: IEvaluationUser; } = snapshot.val();
    return answer;
  }

  static async readAllReferences( firebaseConnection: IFirebaseConnection ): Promise<EvaluationUser[]> {

    const path = `/users/evaluation/`;
    const snapshotValue: { [userUid: string]: IEvaluationUser; } = await firebaseConnection.database2.getValue<{ [userUid: string]: IEvaluationUser; }>( path );

    const answer: EvaluationUser[] = [];
    for( const uuid of Object.keys(snapshotValue)) {

      answer.push( EvaluationUser.build( snapshotValue[uuid], uuid ));
    }
    return answer;
  }

  static async readReference2( firebaseConnection: IFirebaseConnection, userUid: string ): Promise<EvaluationUser|null> {

    const path = FirebaseEvaluationUser.getPath( userUid );
    const snapshotValue = await firebaseConnection.database2.getValue<IEvaluationUser>( path );

    FirebaseEvaluationUser.log.debug( 'snapshotValue', snapshotValue);
    if ( snapshotValue ) {

      return EvaluationUser.build(snapshotValue, userUid);
    }
    return null;
  }

  static async readReference(fbDb: database.Database, userUid: string ): Promise<EvaluationUser|null> {

    const path = FirebaseEvaluationUser.getPath( userUid );
    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IHotelUser = snapshot.val();

    FirebaseEvaluationUser.log.debug( 'value', value);
    if ( value ) {

      return EvaluationUser.build(value, userUid);
    }
    return null;
  }

  // static async writeReference( fbDb: database.Database, facilityUser: EvaluationUser  ): Promise<void> {
  //
  //   const path = FirebaseEvaluationUser.getPath( facilityUser.userUid );
  //   return fbDb.ref( path ).set( facilityUser.value );
  // }

  static async writeReference( firebaseConnection: IFirebaseConnection, facilityUser: EvaluationUser ): Promise<void> {

    return FirebaseEvaluationUser.writeValue( firebaseConnection, facilityUser.userUid, facilityUser.value  );
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, userUid: string, value: IEvaluationUser|IEvaluationUser2 ): Promise<void> {

    const path = this.getPath( userUid );
    return firebaseConnection.database2.setValue( path, value );
  }

}


