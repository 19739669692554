import {Component, OnInit, ViewChild} from '@angular/core';
import {PwaApplicationContextProvider} from "../../service.pwa-application-context/pwa-application-context";
import {PwaSessionContextProvider} from "../../service.pwa-session-context/pwa-session-context";
import {ActivatedRoute, Router} from '@angular/router';
import {UserEditComponent} from "./component.user-edit/user-edit.component";
import {BackOfficeRouteManifest} from "../BackOfficeRouteManifest";
import {BackOfficeSessionContext} from "../../module.back-office-shared-library/service.back-office-session-context/BackOfficeSessionContext";
import {
  FirebaseConnectionService
} from "../../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {PropertyReference} from "../../javascript.lib.mojo-base/product.facilities/Property";
import {FirebaseProperty} from "../../javascript.lib.mojo-base/product.facilities/firebase/FirebaseProperty";
import {UserPropertyView} from "./component.user-property/UserPropertyView";
import {HttpClient} from "@angular/common/http";
import {
  FirebaseEvaluationUser
} from "../../javascript.lib.mojo-base/product.facilities/firebase/FirebaseEvaluationUser";
import {EvaluationUser} from "../../javascript.lib.mojo-base/product.facilities/EvaluationUser";

/**
 * Generated class for the RegistrationPage page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
  selector: 'page-manage-user',
  templateUrl: 'page-manage-user.html',
})
export class PageManageUser implements OnInit {

  userUid: string = null;

  @ViewChild( UserEditComponent, {static: true}) registrationComponent: UserEditComponent;


  properties: PropertyReference[] = [];
  users: EvaluationUser[] = [];

  async initProperties() {


    this.properties = [];

    if( !this.sessionContext.userIsAdministrator ) {

      return;
    }

    this.properties = await FirebaseProperty.list( this.firebase );
    this.properties.sort( (a, b) => {

      return a.value.name.localeCompare( a.value.name );
    } );
  }

  async initUsers() {

    this.users = [];

    if( !this.sessionContext.userIsAdministrator ) {

      return;
    }

    this.users = await FirebaseEvaluationUser.readAllReferences( this.firebase );

  }


  async onUsersModified() {

    await this.initUsers();
  }


  async ngOnInit() {

    await this.initProperties();
    await this.initUsers();
  }

  constructor( public router: Router,
               public sessionContext: BackOfficeSessionContext,
               public firebase: FirebaseConnectionService,
               public http: HttpClient
  ) {
  }

}
