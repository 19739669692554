
import firebase from 'firebase/compat/app';
import database = firebase.database;
import {IProperty, IProperty2, PropertyReference} from "../Property";
import {IFirebaseConnection} from "../../firebase/FirebaseConnection";
import {QuestionReference} from "../../../assets/browser.app.facilities-popup/version-2/Model";


export class FirebaseProperty {

  // private static log = LoggerFactory.build( 'FirebaseProperty' );

  private static getPath( propertyId: string ) {

    return `/properties/${propertyId}`;
  }




  static async list( firebaseConnection: IFirebaseConnection ): Promise<PropertyReference[]> {

    const values: { [propertyKey: string]: IProperty; } = await firebaseConnection.database2.getValue( '/properties' );

    const answer: PropertyReference[] = [];

    for( const propertyKey of Object.keys(values) ) {

      answer.push( new PropertyReference( values[propertyKey], propertyKey ));
    }

    return answer;
  }


  static async readLegacyReference(fbDb: database.Database, propertyKey: string ): Promise<PropertyReference|null> {

    const path = `/hotels/${propertyKey}`;

    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IProperty = snapshot.val()[0];

    if ( value ) {

      return new PropertyReference(value, propertyKey);
    }
    return null;
  }

  static async readReference(fbDb: database.Database, propertyKey: string ): Promise<PropertyReference|null> {

    const path = this.getPath( propertyKey );

    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IProperty = snapshot.val();

    if ( value ) {

      return new PropertyReference(value, propertyKey);
    }
    return null;
  }

  static async writeReference( fbDb: database.Database, property: PropertyReference  ): Promise<void> {

    const path = this.getPath( property.propertyKey );
    return fbDb.ref( path ).set( property.value );
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, propertyKey: string, value: IProperty ): Promise<void> {

    const path = this.getPath( propertyKey );
    return firebaseConnection.database2.setValue( path, value );
 }

  static async writeValue2( firebaseConnection: IFirebaseConnection, propertyKey: string, value: IProperty2 ): Promise<void> {

    const path = this.getPath( propertyKey );
    return firebaseConnection.database2.setValue( path, value );
  }

}
