import {IQuestion, Question} from './Question';
import {Answer, IAnswer} from './Answer';

export interface IQuestionAnswer {

  question: Question;
  answer: Answer;

}

/**
 * @deprecated use Answer as a IQuestionAnswer
 */
export class QuestionAnswer implements IQuestionAnswer {

  question: Question;
  answer: Answer;


  constructor(question: IQuestion|Question, answer: IAnswer|Answer = null ) {


    if ( question instanceof Question ) {

      this.question = question;
    } else {

      this.question = new Question( question );
    }


    if ( answer ) {

      if( answer instanceof Answer ) {

        this.answer = answer;
      } else {

        this.answer = new Answer( this.question, answer );
      }
    } else {

      this.answer = new Answer( this.question );
    }

  }

}
