import {NocoDbProjectProxy} from "./NocoDbProjectProxy";
import {NocoProduct, NocoProductSet} from "./model/NocoProduct";
import {NocoEnumeratedType, NocoEnumeratedTypeSet} from "./model/NocoEnumeratedType";
import {NocoQuestionType, NocoQuestionTypeSet} from "./model/NocoQuestionType";
import {NocoEnumeratedValue, NocoEnumeratedValueSet} from "./model/NocoEnumeratedValue";
import {NocoEvaluationQuestionImage, NocoEvaluationQuestionImageSet} from "./model/NocoEvaluationQuestionImage";
import {NocoEvaluationQuestion, NocoEvaluationQuestionSet} from "./model/NocoEvaluationQuestion";
import {NocoEvaluationQuestionTextAltSet} from "./model/NocoEvaluationQuestionTextAlt";
import {NocoEvaluationSection, NocoEvaluationSectionSet} from "./model/NocoEvaluationSection";
import {NocoProductEvaluationSet} from "./model/NocoProductEvaluation";
import {NocoProductEvaluation2Set} from "./model/NocoProductEvaluation2";
import {QuestionSet} from "../model/QuestionSet";
import {IProduct} from "../../model.product/IProduct";
import {EProductType} from "../model/ProductType";
import {IProductEnvironment} from "../../environments/FacilitiesEvaluationEnvironment";
import {EvaluationSection, IAspectQuestions} from "../model/module/EvaluationSection";
import {IQuestion, IType, ITypeEnum, Question} from "../model/Question";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {environment} from "../../environments/environment";
import {QuestionKey} from "../model/QuestionKey";
import {INocoDependantDescriptor, NocoDependantDescriptor} from "./model/NocoDependantDescriptor";
import {INocoDbProductValue} from "./NocoDbProductValue";


export class NocoDbProduct {


  private _log: ILogger = LoggerFactory.build( 'NocoDbProduct' );


  public static INSTANCE: NocoDbProduct = null;

  products: NocoProductSet;
  enumeratedTypes: NocoEnumeratedTypeSet;
  enumeratedValues: NocoEnumeratedValueSet;
  questionTypes: NocoQuestionTypeSet;
  evaluationQuestionImages: NocoEvaluationQuestionImageSet;
  evaluationQuestions: NocoEvaluationQuestionSet;
  evaluationQuestionTextAlts: NocoEvaluationQuestionTextAltSet;
  evaluationSections: NocoEvaluationSectionSet;
  productEvaluations: NocoProductEvaluationSet;
  productEvaluation2s: NocoProductEvaluation2Set;

  version: string = null;


  public static buildFromValue( value: INocoDbProductValue ): NocoDbProduct {

    const answer = new NocoDbProduct();

    answer.products = new NocoProductSet( value.products );
    answer.enumeratedTypes = new  NocoEnumeratedTypeSet( value.enumeratedTypes );
    answer.questionTypes = new  NocoQuestionTypeSet( value.questionTypes );
    answer.enumeratedValues = new  NocoEnumeratedValueSet( value.enumeratedValues );
    answer.evaluationQuestionImages = new  NocoEvaluationQuestionImageSet( value.evaluationQuestionImages );
    answer.evaluationQuestions = new  NocoEvaluationQuestionSet( value.evaluationQuestions );
    answer.evaluationQuestionTextAlts = new  NocoEvaluationQuestionTextAltSet( value.evaluationQuestionTextAlts );
    answer.evaluationSections = new  NocoEvaluationSectionSet( value.evaluationSections );
    answer.productEvaluations = new  NocoProductEvaluationSet( value.productEvaluations );
    answer.productEvaluation2s = new  NocoProductEvaluation2Set( value.productEvaluation2s );

    if( value.version ) {

      answer.version = value.version;
    } else {

      answer.version = "current";
    }

    NocoDbProduct.INSTANCE = answer;

    return answer;

  }

  public getValue(): INocoDbProductValue {

    const answer: INocoDbProductValue = {

      products: this.products.value,
      enumeratedTypes: this.enumeratedTypes.value,
      questionTypes: this.questionTypes.value,
      enumeratedValues: this.enumeratedValues.value,
      evaluationQuestionImages: this.evaluationQuestionImages.value,
      evaluationQuestions: this.evaluationQuestions.value,
      evaluationQuestionTextAlts: this.evaluationQuestionTextAlts.value,
      evaluationSections: this.evaluationSections.value,
      productEvaluations: this.productEvaluations.value,
      productEvaluation2s: this.productEvaluation2s.value,
    };

    return answer;

  }


  public static async load( proxy: NocoDbProjectProxy ): Promise<NocoDbProduct> {

    const answer = new NocoDbProduct();

    answer.products = await NocoProductSet.build( proxy );
    answer.enumeratedTypes = await NocoEnumeratedTypeSet.build( proxy );
    answer.questionTypes = await NocoQuestionTypeSet.build( proxy );
    answer.enumeratedValues = await NocoEnumeratedValueSet.build( proxy );
    answer.evaluationQuestionImages = await NocoEvaluationQuestionImageSet.build( proxy );
    answer.evaluationQuestions = await NocoEvaluationQuestionSet.build( proxy );
    answer.evaluationQuestionTextAlts = await NocoEvaluationQuestionTextAltSet.build( proxy );
    answer.evaluationSections = await NocoEvaluationSectionSet.build( proxy );
    answer.productEvaluations = await NocoProductEvaluationSet.build( proxy );
    answer.productEvaluation2s = await NocoProductEvaluation2Set.build( proxy );

    answer.version = "SPoT";

    NocoDbProduct.INSTANCE = answer;

    return answer;
  }

  private _jsonClone<T>( source: T ): T {

    const json = JSON.stringify( source );
    return JSON.parse( json );
  }


  // //  typeEnum?: ITypeEnum;
  // getEnumeratedType( question: NocoEvaluationQuestion ): ITypeEnum {
  //
  //     const answer: ITypeEnum = {
  //       options: [],
  //       scoring: [],
  //     };
  //
  //     return answer;
  //
  //
  // }

  getQuestionType( question: NocoEvaluationQuestion ): [string,IType]|null {


    const nocoQuestionType: NocoQuestionType = this.questionTypes.questionTypesByKey[question.value.QuestionTypeId];
    if( !nocoQuestionType ) {

      this._log.warn( '!nocoQuestionType', 'question', question );
      return null;
    }

    if( nocoQuestionType.isBoolean() ) {

      const answer: [string,IType] = [
        Question.TYPE_BOOLEAN,
        {
          typeBoolean: {
            scoring: {
              onTrue: 0,
              onFalse: 0
            }
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isEnumerated() ) {

      // const typeEnum = this.getEnumeratedType( question );

      const answer: [string,IType] = [
        Question.TYPE_ENUM,
        {
          typeEnum: {
            options: [],
            scoring: [],
          }
        }
      ];

      // this._log.warn( 'nocoQuestionType.isEnumerated()', 'question', question );

      return answer;

    }

    if( nocoQuestionType.isMeasurement() ) {

      const answer: [string,IType] = [
        Question.TYPE_CM_MEASUREMENT,
        {
          typeCmMeasurement: {
            minValue: 0,
            maxValue: Number.MAX_VALUE,
            scoring: []
          }
        }
      ];
      return answer;

    }

    if( nocoQuestionType.isNumeric() ) {

      const answer: [string,IType] = [
        Question.TYPE_INTEGER,
        {
          typeInteger: {
            minValue: 0,
            maxValue: Number.MAX_VALUE,
            scoring: []
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isPhoto() ) {

      const answer: [string,IType] = [
        Question.TYPE_PHOTO,
        {
          typePhoto: {}
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isTernary() ) {
      const  answer: [string,IType] = [
        Question.TYPE_TERNARY,
        {
          typeTernary: {
            scoring: {
              onTrue: 0
            }
          }
        }
      ];
      return answer;
    }

    if( nocoQuestionType.isTextual() ) {

      const answer: [string,IType] = [
        Question.TYPE_TEXT,
        {
          typeText: {
            scoring: {}
          }
        }
      ];
      return answer;
    }



    this._log.warn( 'unsupported question type', 'nocoQuestionType', nocoQuestionType );
    return null;

  }

  private _initDependency( pwaQuestion: IQuestion, nocoQuestion: NocoEvaluationQuestion) {

    if( nocoQuestion.value.DependencyJson ) {

      const dependency: INocoDependantDescriptor = JSON.parse(nocoQuestion.value.DependencyJson );
      pwaQuestion.dependant = NocoDependantDescriptor.toDependantDescriptor( dependency );
    } else if( nocoQuestion.value.DependencyId ) {

      const dependentQuestion: NocoEvaluationQuestion = this.evaluationQuestions.valuesById[nocoQuestion.value.DependencyId];
      if( !dependentQuestion ) {

        this._log.warn( '!dependentQuestion', 'nocoQuestion.value.DependencyId', nocoQuestion.value.DependencyId );
      } else {
        pwaQuestion.dependant = {

          questionKey: dependentQuestion.value.MysteriousTechColumn
        }
      }
    }

  }

  public buildQuestion( key: QuestionKey ): IQuestion|null {

    const nocoQuestion = this.evaluationQuestions.valuesByMysteriousTechColumn[key];
    if( !nocoQuestion ) {

      this._log.warn( '!nocoQuestion', 'key', key );
      return null;
    }


    // filter out banking questions
    // {
    //   const product = this.products.valuesById[nocoQuestion.value.ProductId];
    //   if( product === this.products.banking ) {
    //
    //     return null;
    //   }
    // }

    const questionType = this.getQuestionType( nocoQuestion );
    if( !questionType ) {

      this._log.warn( '!questionType', 'nocoQuestion', nocoQuestion );
      return null;
    }

    const answer: IQuestion = {


      key,
      label: nocoQuestion.value.QuestionText,
      helpImage: null,
      helpImages: [],
      helpText: nocoQuestion.value.InfoBox,
      popupLabel: nocoQuestion.value.PopupWording,
      maximumScore: nocoQuestion.value.MaxPoints,
      type: questionType[0],
      type2: questionType[1],
      nocoDbId: nocoQuestion.value.Id
    }

    this._initDependency( answer, nocoQuestion );
    nocoQuestion.initQuestionScoring( answer );


    const infoImages = this._getInfoImages( nocoQuestion );
    if( infoImages ) {

      answer.helpImages = infoImages;
    }


    return answer;
  }

  private _getInfoImages( nocoQuestion: NocoEvaluationQuestion ): string[]|null {



    const imageId = nocoQuestion.value.EvaluationQuestionImageId;
    if( !imageId ) {

      return null;
    }

    const nocoImage: NocoEvaluationQuestionImage = this.evaluationQuestionImages.evaluationQuestionImagesById[ imageId ];
    if( !nocoImage ) {

      this._log.warn( '!nocoImage', 'imageId', imageId );
      return null;
    }

    if( nocoImage.attachments && nocoImage.attachments.length ) {

      const answer: string[] = [];

      let index = 0;
      for( const attachment of nocoImage.attachments ) {

        const filename = attachment.toFilename( index );
        answer.push( `EvaluationQuestionImage/${filename}` );
        index++;
      }

      return answer;
    }

    return null;
  }


  private _getTypeEnum( nocoQuestion: NocoEvaluationQuestion ): ITypeEnum {


    // const debugQuestionId = 711; // What type of door handles are on the gatehouse door?
    const debugQuestionId = 583; // Are main access routes that connect to this building free from steps and obstructions?
    // const debugQuestionId = 589; // Type of surface at outdoor pedestrian walkways

    const debug = debugQuestionId === nocoQuestion.value.Id? true: false;

    if( debug ) {

      this._log.debug( 'debugQuestionId', debugQuestionId );
    }

    const answer: ITypeEnum = {
      options: [],
      scoring: []
    }

    const enumeratedType = this.enumeratedTypes.enumeratedTypesById[nocoQuestion.value.EnumeratedTypeId];
    if( !enumeratedType ) {

      this._log.error( '!enumeratedType', 'nocoQuestion.value.EnumeratedTypeId', nocoQuestion.value.EnumeratedTypeId );
      return answer;
    }


    if( debug ) {
      this._log.debug( 'enumeratedType', enumeratedType );
    }

    answer.enumeratedTypeId = nocoQuestion.value.EnumeratedTypeId;

    const values: NocoEnumeratedValue[] = this.enumeratedValues.getValues( enumeratedType, debug );
    if( !values ) {

      this._log.error( '!values', 'enumeratedType', enumeratedType );
      return answer;
    }

    if( debug ) {
      this._log.debug( 'values', values );
    }

    for( const value of values ) {

      answer.options.push( value.toEnumeratedConstant() );
      answer.scoring.push( value.toEnumScore() );
    }

    if( debug ) {
      this._log.debug( 'answer', answer );
    }

    return answer;
  }


  private _initTypesForEnumQuestion( enumQuestion: IQuestion, nocoQuestion: NocoEvaluationQuestion ) {


    const debugQuestionId = -1;
    // const debugQuestionId = 583; // Are main access routes that connect to this building free from steps and obstructions?
    const debug = debugQuestionId === nocoQuestion.value.Id? true: false;

    if( debug  ) {

      const clone = JSON.parse(JSON.stringify(enumQuestion));
      this._log.debug( `debugQuestionId`, debugQuestionId, 'clone', clone );
    }


    if( EProductType.banking !== environment.productConfig.productType
      && EProductType.manufacturingCampus !== environment.productConfig.productType ) {

      const onlyInit = {
        'gou1o': true, // Are the ground surfaces of outdoor pedestrian pathways stable, firm, and slip-resistant?
        'goXGG': true, // Are main access routes that connect to this building free from steps and obstructions?
      }

      if( !onlyInit[enumQuestion.key] ) {

        return;
      }

    }


    const typeEnum = this._getTypeEnum( nocoQuestion );
    if( enumQuestion?.type2?.typeEnum?.scoring ) {

      typeEnum.scoring = [...typeEnum.scoring, ...enumQuestion.type2.typeEnum.scoring ];
      // for( const legacyScore of enumQuestion.type2.typeEnum.scoring ) {
      //   if( debug ) {
      //
      //     this._log.debug( `debugQuestionId`, debugQuestionId, 'legacyScore', legacyScore );
      //   }
      //   typeEnum.scoring.push( legacyScore );
      // }
      this._log.debug( `debugQuestionId`, debugQuestionId, 'typeEnum.scoring', typeEnum.scoring );
    }

    if( enumQuestion?.type2?.typeEnum?.options ) {

      typeEnum.legacyOptions = enumQuestion?.type2?.typeEnum?.options;
    }

    // push the old legacy scores

    enumQuestion.type = Question.TYPE_ENUM;
    enumQuestion.type2 = {
      typeEnum
    };



  }

  private _initTypesForBooleanQuestion( booleanQuestion: IQuestion, nocoQuestion: NocoEvaluationQuestion) {

    booleanQuestion.type = Question.TYPE_BOOLEAN;


    if( nocoQuestion.value.ScoringJson ) {

      const scoring = JSON.parse( nocoQuestion.value.ScoringJson );
      booleanQuestion.type2 = {

        typeBoolean: {
          scoring
        }
      }
    } else {

      booleanQuestion.type2 = {

        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0
          }
        }
      }
    }

  }

    private _initTypesForIntegerQuestion( integerQuestion: IQuestion, nocoQuestion: NocoEvaluationQuestion) {

      integerQuestion.type = Question.TYPE_INTEGER;

      const type2: IType = {

        typeInteger: {
          minValue: 0,
          maxValue: Number.MAX_VALUE,
          scoring: []
        }
      }
      if( integerQuestion.type2.typeInteger.minValue ) {

        type2.typeInteger.minValue = integerQuestion.type2.typeInteger.minValue;
      }
      if( integerQuestion.type2.typeInteger.maxValue ) {

        type2.typeInteger.maxValue = integerQuestion.type2.typeInteger.maxValue;
      }

      type2.typeInteger.scoring = integerQuestion.type2.typeInteger.scoring;

      if( nocoQuestion.value.ScoringJson ) {

        type2.typeInteger.scoring = JSON.parse( nocoQuestion.value.ScoringJson );
      }

    }


    private _updateAltText( questionSet: QuestionSet ) {

      const product = this.products.getProduct( environment.productConfig.productType );
      const altTexts = this.evaluationQuestionTextAlts.filterByProduct( product );
      for( const altText of altTexts ) {
        const questionId: number = altText.value.EvaluationQuestionId;

        const debugQuestionId = 358;
        const debug = debugQuestionId == questionId;

        if( debug ) {

          this._log.debug( 'debugQuestionId', debugQuestionId, 'altText', altText );
        }

        const targetQuestion: Question|null = questionSet.getQuestionByNocoDbId( questionId );
        if( targetQuestion ) {

          if( altText.value.QuestionText ) {

            targetQuestion.value.label = altText.value.QuestionText;
          }
          if( altText.value.InfoBox ) {

            targetQuestion.value.helpText = altText.value.InfoBox;
          }
        }

        if( debug ) {

          this._log.debug( 'debugQuestionId', debugQuestionId, 'targetQuestion', targetQuestion );
        }


      }
    }


    private _buildQuestions( originals: QuestionSet ): QuestionSet {

    const answerValue: IQuestion[] = [];

    // take the original questions
    for( const original of originals.questions ) {

      const clone: IQuestion = this._jsonClone( original.value );
      const nocoQuestion: NocoEvaluationQuestion = this.evaluationQuestions.valuesByMysteriousTechColumn[clone.key];
      if( nocoQuestion ) {

        nocoQuestion.update( clone );

        const infoImages = this._getInfoImages( nocoQuestion );
        if( infoImages ) {

          clone.helpImages = infoImages;
        }

        if( 'Bk0gL' === original.value.key ) { // 'Bk0gL', // Can the door to the most accessible meeting room be opened by someone with limited strength?

          this._initTypesForBooleanQuestion( clone, nocoQuestion );

        } else if( 'Bk1dv' === original.value.key ) { // 'Bk1dv', // Can the door to the most accessible training room be opened by someone with limited strength?

          this._initTypesForBooleanQuestion( clone, nocoQuestion );

        } else if( original.isBoolean ) {

          this._initTypesForBooleanQuestion( clone, nocoQuestion );
        } else if( original.isEnum ) {

          this._initTypesForEnumQuestion( clone, nocoQuestion );
        } else if( original.isInteger ) {

          this._initTypesForIntegerQuestion( clone, nocoQuestion );
        }

        if( 360 === nocoQuestion.value.Id ) {

          this._initDependency( clone, nocoQuestion );
        }

      }  else {

        this._log.error( '!( nocoQuestion ); key exists in the code but not in SPoT', 'clone.key', clone.key );
      }

      answerValue.push( clone );
    }

    const questions = new QuestionSet( answerValue);

    // add the new questions
    for( const nocoQuestion of this.evaluationQuestions.values ) {

      const pwaQuestion = questions.questionByKey[ nocoQuestion.value.MysteriousTechColumn ];
      if( pwaQuestion ) {

        continue;
      }
      const newQuestion = this.buildQuestion( nocoQuestion.value.MysteriousTechColumn );

      if( newQuestion ) {

        if( nocoQuestion.isEnum ) {

          const typeEnum = this._getTypeEnum( nocoQuestion );
          newQuestion.type = Question.TYPE_ENUM;
          newQuestion.type2 = {
            typeEnum
          };
        }

        answerValue.push( newQuestion );
      }


    }

    const answer = new QuestionSet( answerValue );
    this._updateAltText( answer );
    return answer;
  }

  private _getQuestionsForSection( section: NocoEvaluationSection, questions: QuestionSet ): IQuestion[] {

    const answer: IQuestion[] = [];

    let index = -1;

    for( const askedQuestion of this.productEvaluation2s.values ) {

      index++;


      if( askedQuestion.value.EvaluationSectionId === section.value.Id ) {

        const nocoId = askedQuestion.value.QuestionId;
        const nocoQuestion = this.evaluationQuestions.valuesById[nocoId];
        if( !nocoQuestion ) {

          this._log.error( '!nocoQuestion',  'nocoId', nocoId );
          continue;
        }
        const appQuestion = questions.questionByKey[ nocoQuestion.value.MysteriousTechColumn ];
        if( !appQuestion ) {

          this._log.error( '!appQuestion', 'nocoQuestion.value.MysteriousTechColumn', nocoQuestion.value.MysteriousTechColumn, 'section.value.Id', section.value.Id );
        } else {

          answer.push( appQuestion.value );
        }
      }

    }

    return answer;

  }

  private _buildEvaluationSection( updatedQuestions: QuestionSet, section: NocoEvaluationSection, index: number ): EvaluationSection {


    if( !section ) {

      this._log.error( "!section", 'this.evaluationSections', this.evaluationSections, 'index + 1', index + 1 );
    }

    const questions = this._getQuestionsForSection( section, updatedQuestions );
    // this._log.debug( 'questions', questions );
    const pages = this.productEvaluation2s.toPages( section, updatedQuestions );
    // this._log.debug( 'pages', pages );

    const answerValue: IAspectQuestions = {
      name: section.value.Title,
      // firebaseAnswersRoot: `/answers-x/${productType}`,
      firebaseAspectId: section.value.FirebaseId,
      questions,
      sections: {
        evaluation: pages,
        portal: []
      },
      uuid: 'NULL',
    };


    this._log.debug( 'answerValue', answerValue );


    return new EvaluationSection( answerValue );

  }

  private _buildEvaluationSections( originals: EvaluationSection[], productType: EProductType, updatedQuestions: QuestionSet ): EvaluationSection[] {

    const answer: EvaluationSection[] = [];


    const nocoProduct = this.products.getProduct( environment.productConfig.productType );
    // this._log.debug( 'nocoProduct',  nocoProduct );
    const evaluationSections = this.evaluationSections.getEvaluationSections( nocoProduct.value.Id );

    let index = 0;

    for( const evaluationSection of evaluationSections ) {


      answer.push( this._buildEvaluationSection( updatedQuestions, evaluationSection, index ));
      index++;
    }

    this._log.debug( 'answer[0]', answer[0] );


    return answer;
  }


  buildProduct( environment: IProductEnvironment ): IProduct {


    const original = environment.productConfig.product;

    const questions = this._buildQuestions( original.questions );
    const evaluationSections = this._buildEvaluationSections( environment.productConfig.product.evaluationSections, environment.productConfig.productType, questions );

    const answer: IProduct = {

      evaluationSections,
      questions,
      reportSections: original.reportSections,

      scoreRules: original.scoreRules,
      /**
       * @deprecated use IProduct.summaryStructure
       */
      summarySections: original.summarySections,
      // summaryStructure?: product.summaryStructure,

      version: original.version,

      getAllEnums: original.getAllEnums,
      printCodes: original.printCodes,
    }

    if( original.summaryStructure ) {

      answer.summaryStructure = original.summaryStructure;
    }

    return answer;

  }


  toQuestionSet(): QuestionSet {

    const answer: QuestionSet  = new QuestionSet( []);

    return answer;

  }


  private constructor() {
  }

}
