

import firebase from 'firebase/compat/app';
import {IFirebaseConnection} from "../../javascript.lib.mojo-base/firebase/FirebaseConnection";
import {IRealtimeDatabase} from "../../javascript.lib.mojo-base/firebase/RealtimeDatabase";
import {RealtimeDatabaseVersion9} from "./RealtimeDatabaseVersion9";
import {IFirebaseStorage} from "../../javascript.lib.mojo-base/firebase/FirebaseStorage";
import {IFirebaseFirestore} from "../../javascript.lib.mojo-base/firebase/IFirebaseFirestore";
import {FirebaseFirestoreVersion9} from "./FirebaseFirestoreVersion9";


export class FirebaseConnectionVersion9 implements IFirebaseConnection {

  public database2: IRealtimeDatabase;
  public firestore: IFirebaseFirestore;

  public get storage2() : IFirebaseStorage {
    throw `'FirebaseConnectionVersion9.storage2' unimplemented`;
  }

  /**
   * @deprecated use 'database2'
   */
  database(): firebase.database.Database {

    return this._database;
  }



  storage(): firebase.storage.Storage {

    return this._storage;
  }

  constructor( private _database: firebase.database.Database,
               private _storage: firebase.storage.Storage,
               private _firestore: firebase.firestore.Firestore,
  ) {


    this.database2 = new RealtimeDatabaseVersion9( _database );
    this.firestore = new FirebaseFirestoreVersion9( _firestore );

  }

}

// // FIREBASE 8
// import firebase from 'firebase/compat/app';
//
// export class FirebaseConnection {
//
//   // x: firebase.database.Database;
//
//   database(): firebase.database.Database {
//
//     return this._database;
//   }
//
//   constructor( private _database: firebase.database.Database ) {
//   }
//
// }
//
//
