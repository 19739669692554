import {Params} from "@angular/router";


export class SignInParams {

  public apiKey: string;
  public lang: string;
  public mode: string;
  public oobCode: string;

  public static build( params:Params ): SignInParams|null {

    const answer = new SignInParams();

    answer.apiKey = params['apiKey'];
    if( !answer.apiKey ) {

      return null;
    }

    answer.lang = params['lang'];
    if( !answer.lang ) {

      return null;
    }

    answer.mode = params['mode'];
    if( !answer.mode ) {

      return null;
    }

    if( 'signIn' !== answer.mode ) {

      return null;
    }

    answer.oobCode = params['oobCode'];
    if( !answer.oobCode ) {

      return null;
    }

    return answer;
  }

  private constructor() {

  }

}
