import {Injectable, NgZone} from '@angular/core';
import {IQuestionAnswer, QuestionAnswer} from '../../../javascript.lib.mojo-base/model/QuestionAnswer';
// vvv https://github.com/angular/angularfire2/issues/1015
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {HttpClient} from '@angular/common/http';
// ^^^ https://github.com/angular/angularfire2/issues/1015
import firebase from 'firebase/compat/app';
import storage = firebase.storage;
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireUploadTask} from "@angular/fire/compat/storage/task";
import {UploadTaskSnapshot} from "@angular/fire/compat/storage/interfaces";
import {PhotoReference} from "../../../javascript.lib.mojo-base/firebase/Photo";
import {environment} from "../../../environments/environment";
import {BaseProxy} from "../../firebase/functions/BaseProxy";
import {BaseSessionContext} from "../../service.session-context/BaseSessionContext";
import {Command} from "../../../javascript.lib.mojo-base/util/Command";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";




export interface IPhotoUploadListener {

  onUploadCompleted( photoKey: number, remainingPhotos: number );
  onUploadFailed( photoKey: number, remainingPhotos: number );

  onUploadPaused( photoKey: number, bytesTransferred: number, totalBytes: number );
  onUploadRunning( photoKey: number, bytesTransferred: number, totalBytes: number );

}

export interface IImage {

  storageUrl?: string; // in storage
  base64Encoded?: string; // in memory base64 encoding
  blob?: any; // in memory blob
}


// class ZonedPhotoUploadListener implements  IPhotoUploadListener {
//
//
//   private _log = LoggerFactory.build( 'ZonedPhotoUploadListener' );
//
//
//   constructor( private zone: NgZone,
//                private delegate: IPhotoUploadListener = null ) {}
//
//
//   onUploadCompleted(photoKey: number, downloadURL: string) {
//
//     if ( this.delegate ) {
//
//       this.zone.run( () => this.delegate.onUploadCompleted( photoKey, downloadURL ));
//     } else {
//
//       this._log.info( 'onUploadCompleted', photoKey, downloadURL );
//     }
//
//   }
//
//   onUploadFailed(photoKey: number, reason) {
//
//     this._log.error( 'onUploadFailed', photoKey, reason );
//
//     if ( this.delegate ) {
//
//       this.zone.run( () => this.delegate.onUploadFailed( photoKey, reason ));
//     }
//
//   }
//
//   onUploadPaused(photoKey: number, bytesTransferred: number, totalBytes: number) {
//
//     if ( this.delegate ) {
//
//       this.zone.run( () => this.delegate.onUploadPaused( photoKey, bytesTransferred, totalBytes ));
//     } else {
//
//       this._log.info( 'onUploadPaused', photoKey, bytesTransferred, totalBytes );
//     }
//
//   }
//
//   onUploadRunning(photoKey: number, bytesTransferred: number, totalBytes: number) {
//
//     if ( this.delegate ) {
//
//       this.zone.run( () => this.delegate.onUploadRunning( photoKey, bytesTransferred, totalBytes ));
//     } else {
//
//       this._log.info( 'onUploadRunning', photoKey, bytesTransferred, totalBytes );
//     }
//   }
// }



@Injectable()
export class PhotosProvider {


  private _log = LoggerFactory.build( 'PhotosProvider' );


  answer: QuestionAnswer = null;
  public imageSources: string[] = [];
  public imageSources2: IImage[] = [];

  _hasChanges = false;

  hasPhotos(): boolean {

    if ( !this.answer ) {

      this._log.warn( '!this.answer' );
      return false;
    }

    if ( 0 === this.imageSources2.length ) {

      return false;
    }

    return true;
  }

  // returns whether the reset was redundant, i.e. the qa passed matched the current one in the `PhotosProvider`
  async init( propertyKey: string, answer: IQuestionAnswer ): Promise<boolean> {

    if ( this.answer === answer ) {

      // no-op
      return false;
    }

    this.answer = answer;
    this.imageSources = [];
    this.imageSources2 = [];
    this._hasChanges = false;

    await this.loadAllPhotos( propertyKey );

    return true;
  }

  hasChanges(): boolean {


    if ( !this.answer ) {

      this._log.error( 'hasChanges', '!this.answer' );
      return false;
    }

    return this._hasChanges;
  }

  get( hotelKey: string, index: number ) {

    this._log.debug( 'get' );
  }

  async saveAll( propertyKey: string, listener: IPhotoUploadListener ) {

    // if( !listener ) {
    //
    //   listener = new ZonedPhotoUploadListener( this.zone, listener );
    // }

    if ( !this._hasChanges ) {

      this._log.warn( 'saveAll', '!this._hasChanges' );
      return;
    }

    for ( let i = 0; i < this.imageSources2.length; i++ ) {

      const remainingPhotos = this.imageSources2.length - (i+1);
      const photoKey: number = this.answer.answer.getPhotoKeys()[i];
      try {

        const imageSource = this.imageSources2[i];
        if ( imageSource.base64Encoded ) {

          const downloadUrl = await this._save( imageSource, propertyKey, photoKey );
          this._log.debug( 'downloadUrl', downloadUrl );
          listener.onUploadCompleted( photoKey, remainingPhotos )
        }

      } catch ( e ) {

        this._log.error( 'caught exception', e );
        listener.onUploadFailed( photoKey, remainingPhotos )
      }
    }

    this._hasChanges = false;
  }

  private _storageSave(image: IImage, hotelKey: string, photoKey: number ): Promise<string> {

    const answer = new Promise<string>( (resolve: (value) => void, reject: (reason: any ) => void) => {

      const path = `photos/${hotelKey}/${this.answer.question.value.key}/${photoKey}`;
      // console.log( 'save', path );

      const imageRef = this.storage.ref( path);
      // console.log( 'save', imageRef );

      let uploadTask: AngularFireUploadTask;
      try {

        uploadTask = imageRef.putString( image.base64Encoded, 'data_url' ); // https://github.com/angular/angularfire2/issues/1439

      } catch ( e ) {

        this._log.error( 'save', e  );
        reject( e );
      }
      // console.log( 'save', uploadTask );

      uploadTask.snapshotChanges().subscribe( (snapshot: UploadTaskSnapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

        switch (snapshot.state) {
          case storage.TaskState.PAUSED: // or 'paused'
            // listener.onUploadPaused( photoKey, snapshot.bytesTransferred, snapshot.totalBytes );
            break;
          case storage.TaskState.RUNNING: // or 'running'
            // listener.onUploadRunning( photoKey, snapshot.bytesTransferred, snapshot.totalBytes );
            break;
        }
      }, (error) => {

        // listener.onUploadFailed( photoKey, error );
        reject( error );

      }, () => {

        imageRef.getDownloadURL().subscribe( (url: string) => {

          this._log.info('save', 'completed', 'url', url );
          // listener.onUploadCompleted( photoKey, url );

          resolve(url);

        });

      });

    });

    return answer;
  }


  private async _cloudFunctionSave(image: IImage, hotelKey: string, photoKey: number ): Promise<string> {


    const answer = new Command<string>();

    // listener.onUploadRunning( photoKey, 0, 100 );

    const path = `photos/${hotelKey}/${this.answer.question.value.key}/${photoKey}`;

    const proxy = new BaseProxy( this.http );

    const idToken = await this.sessionContext.fbUser.getIdToken();
    const options = await proxy.getAuthorizedOptions(idToken);

    const postUrl = `${environment.cloudFunctionsUrl}/uploadPhoto?path=${path}`;
    this._log.debug( 'postUrl', postUrl );
    await proxy.post( `${environment.cloudFunctionsUrl}/uploadPhoto?path=${path}`, image.blob, options );

    // listener.onUploadRunning( photoKey, 100, 100 );

    const imageRef = this.storage.ref( path );
    imageRef.getDownloadURL().subscribe( (url: string) => {


      this._log.info('save', 'completed', 'url', url );
      // listener.onUploadCompleted( photoKey, url );
      answer.resolve( url );

    });

    return answer.toPromise();

  }




  private _save( image: IImage, hotelKey: string, photoKey: number ): Promise<string> {


    if ( !this.answer ) {

      this._log.warn( '!this.answer' );
      return Promise.reject( '!this.answer' );
    }

    if( environment.cloudFunctionsUrl ) {

      return this._cloudFunctionSave( image, hotelKey, photoKey );
    } else {

      return this._storageSave( image, hotelKey, photoKey );
    }

  }

  push( base64Encoded: string, blob: any ) {

    this.imageSources.push( base64Encoded );
    this.imageSources2.push( {
      base64Encoded: base64Encoded,
      blob
    });

    if ( !this.answer ) {

      this._log.warn( 'push', '!this.questionAnswer' );
    } else {

      this.answer.answer.addPhotoKey();
      // this.questionAnswer.answer.value.value = this.imageSources.length;
    }

    this._hasChanges = true;
  }



  remove( index: number ) {

    this.imageSources.splice(index, 1);
    this.imageSources2.splice(index, 1);

    this.answer.answer.removePhotoKeyAtIndex( index );
    this._hasChanges = true;
  }

  // addLocalTestImage() {
  //
  //   this.push( PhotoReference.TEST_IMAGE );
  // }

  private async loadAllPhotos( propertyKey: string ) {

    if ( !this.answer ) {

      // console.error('loadAllPhotos', '!this.questionAnswer');
      return;
    }

    const photoKeys: number[] = this.answer.answer.getPhotoKeys();

    for ( const photoKey of photoKeys ) {

      const path = `photos/${propertyKey}/${this.answer.question.value.key}/${photoKey}`;
      // console.log( 'loadAllPhotos', path );

      const imageRef =  this.storage.ref( path);
      // console.log( 'loadAllPhotos', imageRef );

      const downloadURL = await imageRef.getDownloadURL().toPromise();
      this._log.debug( 'loadAllPhotos', downloadURL );

      this.imageSources.push( downloadURL );
      this.imageSources2.push( {
        storageUrl: downloadURL
      });
    }
  }

  // async loadTestImage( hotelKey: string ) {
  //
  //   const path = `photos/${hotelKey}/${this.answer.question.value.key}/0`;
  //   this._log.debug( 'loadTestImage', path );
  //
  //
  //   const imageRef = this.storage.ref( path);
  //   this._log.debug( 'loadTestImage', imageRef );
  //
  //
  //   const downloadURL = await imageRef.getDownloadURL().toPromise();
  //   this._log.debug( 'loadTestImage', downloadURL );
  //
  //   this.push( downloadURL );
  //
  // }

  constructor(public http: HttpClient,
              public afDb: AngularFireDatabase,
              private zone: NgZone,
              private storage: AngularFireStorage,
              public sessionContext: BaseSessionContext
  ) {


  }

}
