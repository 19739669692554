import {PageQuestions} from './PageQuestions';
import {AspectAnswers} from '../AspectAnswers';
import {Answer} from '../Answer';


/**
 * @deprecated use `SectionAnswers2`
 */
export class SectionAnswers1 {

  answers: Answer[] = [];

  constructor(public questions: PageQuestions, answers: AspectAnswers ) {

    for( const q of questions.questions ) {
      this.answers.push( answers.getAnswer( q ) );
    }
  }

}
