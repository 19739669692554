
import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {Answer} from "../../../../javascript.lib.mojo-base/model/Answer";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";


export interface DialogData {
  answer: Answer;
}


@Component({
  selector: 'question-boolean-dialog',
  templateUrl: 'question-boolean-dialog.html',
  styleUrls: ['question-boolean-dialog.scss']
})
export class QuestionBooleanDialogComponent {

  public static PANEL_CLASS = "question-boolean-dialog";

  private _log: ILogger = LoggerFactory.build( 'QuestionBooleanDialogComponent' );


  options: IEnumeratedConstant[] = [
    EnumeratedConstantReference.no,
    EnumeratedConstantReference.yes,
  ];

  selectedOption = EnumeratedConstantReference.no;
  answer: Answer;


  onCancelClick(): void {

    this.dialogRef.close();
  }

  onOkClick(): void {

    if( this.selectedOption == EnumeratedConstantReference.yes ) {

      this.answer.value.value = true;
    } else {

      this.answer.value.value = false;
    }

    // hacky way to get the subscription to trigger
    this.answer.value = this.answer.value;

    this.dialogRef.close();
  }

  constructor(

    public dialogRef: MatDialogRef<QuestionBooleanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
  ) {


    this.answer = data.answer;
    this._log.debug( 'this.answer', this.answer );

    if( this.answer.value.value ) {

      this.selectedOption = EnumeratedConstantReference.yes;
    } else {

      this.selectedOption = EnumeratedConstantReference.no;
    }
  }

}
