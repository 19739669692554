

export enum EProductType {
  banking = "banking",
  brandHome = "brandHome",
  facilities = "facilities",
  manufacturing = "manufacturing",
  manufacturingCampus = "manufacturing-campus",
}

