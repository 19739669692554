import {ActivatedRoute, Router} from '@angular/router';
import {Command} from "../../javascript.lib.mojo-base/util/Command";


export class RouteDescriptor {

  navigate( angularRouter: Router, params: any = null ) {


    let path = `/${this.path}`;
    if( params ) {
      for( const key in params ) {

        const value = params[key];
        path = path.replace( `:${key}`, value );
      }
    }


    angularRouter.navigate( [path]);
  }

  async getParam( route: ActivatedRoute, paramName: string ): Promise<string> {

    const answer = new Command<string>();

    route.paramMap.subscribe(params => {

      let paramValue = route.snapshot.paramMap.get( paramName );
      // console.log( `route['${paramName}'] = '${paramValue}'` );
      answer.resolve( paramValue );
    });

    return answer.toPromise();
  }


  /**
   *
   * @param path
   *
   */
  constructor(public path: string ) {
  }

}
