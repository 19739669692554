import {IFirebaseConnection} from "../FirebaseConnection";
import {EvaluationStatus, IEvaluationState} from "../../model/evaluation/EvaluationStatus";


export class FirebaseEvaluationState {

  private static _getPath( productId: string, propertyKey: string ) {

    return `/evaluation/${productId}/${ propertyKey }`;
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, productId: string, propertyKey: string, evaluationState: IEvaluationState ) {

    const path = FirebaseEvaluationState._getPath( productId, propertyKey );

    await firebaseConnection.database2.setValue<IEvaluationState>( path, evaluationState );

  }

  static async read( firebaseConnection: IFirebaseConnection, productId: string, propertyKey: string): Promise<EvaluationStatus|null> {

    const path = FirebaseEvaluationState._getPath( productId, propertyKey );

    const value = await firebaseConnection.database2.getValue<IEvaluationState>( path );

    if ( value ) {

      return new EvaluationStatus( value );
    }
    return null;
  }

}
