import { Component } from '@angular/core';
import {environment} from "../environments/environment";
import {PageManageUser} from "../module.back-office/page.manage-user/page-manage-user";
import {PwaApplicationContextProvider} from "../service.pwa-application-context/pwa-application-context";
import {
  FirebaseConnectionService
} from "../browser.lib.evaluation-tool/service.firebase-connection/FirebaseConnectionService";
import {FirestoreLoggerDelegate} from "../javascript.lib.mojo-base/log/FirestoreLoggerDelegate";
import {ILogEvent, LogEvent, LogLevel} from "../javascript.lib.mojo-base/log/LogEvent";
import {javaScriptLibEnvironment} from "../javascript.lib.mojo-base/environments/javascript.lib.environment";
import {LoggerDelegate} from "../javascript.lib.mojo-base/log/LoggerDelegate";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss'],
})
export class MyApp {

  rootPage:any = PageManageUser;

  isTest: boolean = environment.isTest || environment.isDevelopment;

  constructor( public applicationContext: PwaApplicationContextProvider,
               public firebase: FirebaseConnectionService ) {

    if( !environment.isDevelopment ) {

      LoggerDelegate.INSTANCE = new FirestoreLoggerDelegate( firebase.firestore );
    }
    {
      const event = LogEvent.info( "MyApp", "fefewfew", "Hello from MyApp" );
      LoggerDelegate.INSTANCE.handle( event );
    }

  }


}

