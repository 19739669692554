import {IDependantDescriptor, IEnumScore, Question} from "./Question";
import {Answer, IAnswer} from "./Answer";
import {IEnumeratedConstant} from "./EnumeratedConstant";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";


export class EnumeratedAnswer {

  private _log: ILogger = LoggerFactory.build( 'EnumeratedAnswer' );

  private _enumeratedConstantValue: IEnumeratedConstant = null;
  public value: number|null = null;


  getScore( answer: Answer ): number {

    if( null === this._enumeratedConstantValue ) {

      this._log.warn( `null === this.enumeratedConstantValue`, 'answer', answer );
      return 0;
    }

    for ( const score of this._question.value.type2.typeEnum.scoring ) {

      if( this._enumeratedConstantValue.codeAsNumber === score.codeAsNumber ) {

        return score.score;
      }
    }

    this._log.warn( `no matching enum found`, 'this._enumeratedConstantValue', this._enumeratedConstantValue, 'answer', answer );
    return 0;
  }

  onSetValue(answerValue: IAnswer ) {

    this._enumeratedConstantValue = null;
    this.value = null;

    if( 'undefined' === typeof answerValue  ) {
      return;
    }

    const underlyingValue = answerValue.value;

    if( 'undefined' === typeof underlyingValue  ) {
      return;
    }

    if( null === underlyingValue  ) {
      return;
    }

    if( "number" === typeof  underlyingValue ) {

      const underlyingNumber = underlyingValue as number;

      for( const option of this._question.value.type2.typeEnum.options ) {
        if( underlyingNumber === option.nocoId ) {

          this._enumeratedConstantValue = option;
          this.value = option.nocoId;
          answerValue.value = option.codeAsNumber;
          return;
        }
        if( underlyingNumber === option.codeAsNumber ) {

          this._enumeratedConstantValue = option;
          this.value = option.codeAsNumber;
          answerValue.value = option.codeAsNumber;
          return;
        }
      }
      this._log.warn( "option for underlyingNumber not found", "underlyingNumber", underlyingNumber, 'answerValue', answerValue, 'this._question', this._question );
      return;
    }

    if( "string" === typeof underlyingValue ) {

      const underlyingString = underlyingValue as string;

      for( const option of this._question.value.type2.typeEnum.options ) {
        if( underlyingString === option.codeAsString ) {

          this._enumeratedConstantValue = option;
          this.value = option.codeAsNumber;
          answerValue.value = option.codeAsNumber;
          return;
        }
      }

      for( const option of this._question.value.type2.typeEnum.options ) {
        if( underlyingString === "" + option.codeAsNumber ) {

          this._enumeratedConstantValue = option;
          this.value = option.codeAsNumber;
          answerValue.value = option.codeAsNumber;
          return;
        }
      }

      this._log.warn( "option for underlyingString not found", "underlyingString", underlyingString, 'answerValue', answerValue );
      return;
    }

    this._log.error( "unhandled value", "underlyingValue", underlyingValue, 'answerValue', answerValue );
  }

  private _getAltCode(question: Question, enumCode: number ): number {

    if( !question.isEnum  ) {

      this._log.warn( "!question.isEnum", "question", question );
      return enumCode;
    }

    const legacyOptions = question.value?.type2?.typeEnum?.legacyOptions;
    if( !legacyOptions ) {

      return enumCode;
    }

    for( const legacyOption of legacyOptions) {

      if( enumCode === legacyOption.codeAsNumber ) {

        return legacyOption.nocoId;
      }

      if( enumCode === legacyOption.nocoId ) {

        return legacyOption.codeAsNumber;
      }
    }

    return enumCode;

  }


  isTruthy(answer: Answer, dependantDescriptor: IDependantDescriptor ): boolean {

    if( null === this._enumeratedConstantValue ) {

      this._log.warn( `null === this.enumeratedConstantValue`, 'answer', answer );
      return null;
    }

    if( dependantDescriptor.truthyEnumCodes ) {

      for( const truthyEnumCode of dependantDescriptor.truthyEnumCodes ) {

        if( truthyEnumCode === this._enumeratedConstantValue.codeAsNumber ) {

          return true;
        }

        const altTruthyEnumCode = this._getAltCode( answer.question, truthyEnumCode );
        if( altTruthyEnumCode === this._enumeratedConstantValue.codeAsNumber ) {

          return true;
        }
      }


      return false;
    }

    if( dependantDescriptor.falsyEnumCodes ) {

      for( const falsyEnumCode of dependantDescriptor.falsyEnumCodes ) {

        if( falsyEnumCode === this._enumeratedConstantValue.codeAsNumber ) {
          return false;
        }

        const altFalsyEnumCode = this._getAltCode( answer.question, falsyEnumCode );
        if( altFalsyEnumCode === this._enumeratedConstantValue.codeAsNumber ) {
          return false;
        }

      }
      return true;
    }

    this._log.error( `dependantDescriptor has neither 'truthyEnumCodes' or 'falsyEnumCodes'`, 'answer', answer, 'dependantDescriptor', dependantDescriptor );
    return false;

  }


  public getEnumOption( answer: Answer ): IEnumeratedConstant|null {

    if( null === this._enumeratedConstantValue ) {

      this._log.warn( `null === this.enumeratedConstantValue`, 'answer', answer );
      return null;
    }

    return this._enumeratedConstantValue;

  }


  public constructor( private _question: Question ) {
  }





}
