import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomePage} from "../page.home/home";
import {HotelSelectPage} from "../page.hotel-select/hotel-select";
import {CompletedPage} from "../page.completed/completed";
import {AspectSectionComponent} from "../page.aspect-section/aspect-section.component";
import {DocumentsComponent} from "../page.documents/documents.component";
import {PasswordResetPage} from "../page.password-reset/password-reset.page";

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'aspect/:aspectIndex/:sectionIndex',
    component: AspectSectionComponent,
  },
  {
    path: 'back-office',
    loadChildren: () => import('../module.back-office/back-office.module').then(mod => mod.BackOfficeModule)
  },
  {
    path: 'development',
    loadChildren: () => import('../module.development/development.module').then(mod => mod.DevelopmentModule)
  },
  {
    path: 'documents',
    component: DocumentsComponent,
  },
  {
    path: 'home',
    component: HomePage,
  },
  {
    path: 'password-reset/:emailAddress',
    component: PasswordResetPage,
  },
  {
    path: 'property-select',
    component: HotelSelectPage,
  },
  {
    path: 'score',
    component: CompletedPage
  },
  {
    path: 'test',
    loadChildren: () => import('../browser.lib.evaluation-tool/module.test/test.module').then(mod => mod.TestModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
