import {Component, OnInit} from '@angular/core';
import {PwaSessionContextProvider} from "../service.pwa-session-context/pwa-session-context";
import { MatSnackBar } from "@angular/material/snack-bar";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {Router} from "@angular/router";
import {PropertyReference} from "../javascript.lib.mojo-base/product.facilities/Property";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";

/**
 * Generated class for the HotelSelectPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
@Component({
  selector: 'page-hotel-select',
  templateUrl: 'hotel-select.html',
  styleUrls: ['hotel-select.scss']
})
export class HotelSelectPage implements OnInit {

  private log = LoggerFactory.build( 'HomePage' );


  async onHotelSelect( property: PropertyReference ) {

    if( property === this.sessionContext.propertyContext.property ) {

      AppRouteManifest.HOME.navigate( this.router );
      return;
    }

    await this.sessionContext.selectProperty( property );

    // RegistrationPage.setRoot( this.navCtrl, hotel.hotelKey );
    // this.applicationContext.goToRegistrationPage( this.navCtrl, hotel.hotelKey );
    AppRouteManifest.HOME.navigate( this.router );

    this.snackBar.open(`Switched to ${property.value.name}`, null, {
      duration: 3000,
    });

  }

  ngOnInit(): void {

    this.log.info( 'this.sessionContext.propertyContext.property.propertyKey', this.sessionContext.propertyContext.property.propertyKey );
  }




  constructor( public router: Router,
               public sessionContext: PwaSessionContextProvider,
               private snackBar: MatSnackBar ) {
  }

}
