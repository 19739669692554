


<div cclass="completed-tick">

  <img src="assets/icons/tick-icon.purple.png"
       [@completed-img]="completedImg ? 'yes' : 'no'"
  >
  <strong class="text"
          style="margin-top: 4px;"
          [@completed-text]="completedText ? 'yes' : 'no'"
  >
    COMPLETED
  </strong>

</div>


