
import {ValueObject} from "./ValueObject";
import {EnumeratedConstantReference, EnumeratedValueAsString, IEnumeratedConstant} from "./EnumeratedConstant";
import {QuestionKey} from "./QuestionKey";



export interface IDependantDescriptor {

  questionKey: QuestionKey;

  falsyEnumCodes?: number[]; // should be available when the dependant has a value of 'enum'
  truthyEnumCodes?: number[]; // should be available when the dependant has a value of 'enum'

  falsyBooleanValue?: boolean;
  truthyBooleanValue?: boolean;

  truthyTernaryCodes?: number[];
  isEssential?: boolean;

}


export interface ITypeBoolean {
  scoring: {
    onTrue: number;
    onFalse: number;
  }
}

export interface IEnumScore {
  codeAsNumber: number,
  score: number
}

export interface ITypeEnum {

  options: IEnumeratedConstant[];
  scoring: IEnumScore[];
  enumeratedTypeId?: number;
  legacyOptions?: IEnumeratedConstant[];
}

export interface IScoreForNumberRange {
  greaterThanOrEqualTo: number;
  lessThan?: number;
  score: number;
}

export interface ITypeCmMeasurement {

  minValue: number;
  maxValue: number;
  scoring: IScoreForNumberRange[];
}

export interface ITypeInteger {
  minValue: number;
  maxValue: number;
  scoring: IScoreForNumberRange[];
}

export interface ITypeTernary {
  scoring: {
    onTrue: number;
    onFalse?: number;
    onNotApplicable?: number;
  }
}

export interface ITypeText {
  scoring: {
    anyText?: number;
  }
}


export interface IType {

  typeBoolean?: ITypeBoolean;
  typeCmMeasurement?: ITypeCmMeasurement;
  typeEnum?: ITypeEnum;
  typeFloat?: any;
  typeInteger?: ITypeInteger;
  typeLine?: any;
  typePhoto?: any;
  typeTernary?: ITypeTernary;
  typeText?: ITypeText;
}


export interface IQuestion {

  key: QuestionKey; // uuid
  label: string;
  helpImage: string;
  helpImages?: string[];
  helpText: string;
  popupLabel: string | null;
  maximumScore: number;
  /** @deprecated don't use **/
  spreadsheetRow?: number;
  /** @deprecated use `type2` **/
  type: string; // see below
  type2?: IType;
  enumOptions?: IEnumeratedConstant[]; // should be available when type has a value of 'enum'
  dependant?: IDependantDescriptor;
  nocoDbId?: number;

}

export class Question extends ValueObject<IQuestion> {

  static readonly TYPE_BOOLEAN: string = 'boolean';
  static readonly TYPE_CM_MEASUREMENT: string = 'cmMeasurement'; // single-line
  static readonly TYPE_ENUM: string = 'enum';
  static readonly TYPE_FLOAT: string = 'float';
  static readonly TYPE_INTEGER: string = 'integer';
  static readonly TYPE_LINE: string = 'line'; // single-line
  static readonly TYPE_PHOTO: string = 'photo'; // camera image
  static readonly TYPE_TEXT: string = 'text'; // multi-line
  static readonly TYPE_TERNARY: string = 'ternary'; // 1/yes/true, 0/no/false, 0x6e2f61/7221089/not-applicable/undefined


  static readonly array = {

    fromValues(questions: IQuestion[]): Question[] {

      return questions.map( (e) => {
        return new Question( e );
      });
    },

    toValues( questions: Question[] ): IQuestion[] {

      return questions.map( (e) => {
        return e.value;
      });
    }
  };


  isBoolean = false;
  isCmMeasurement = false;
  isEnum = false;
  isFloat = false;
  isInteger = false;
  isLine = false;
  isPhoto = false;
  isTernary = false;
  isText = false;


  /**
   * @deprecated use `QuestionReference.array.fromValues`
   */
  static buildArray( questions: IQuestion[] ) {

    return Question.array.fromValues( questions  );
  }

  static validateEnums( questions: IQuestion[] ): boolean {

    let answer = true;

    for( const question of questions ) {

      if( question.type !== Question.TYPE_ENUM ) {
        continue;
      }

      if( !question.enumOptions ) {

        console.error( 'Question', 'validateEnums', 'question.key', question.key );
        answer = false;
      }
    }

    return answer;
  }




  getDefaultAnswer(): any {



    if ( this.isBoolean ) {
      return false;
    }

    if ( this.isCmMeasurement ) {

      return null;
    }

    if ( this.isEnum ) {
      return 0;
    }

    if ( this.isFloat ) {
      return 0.0;
    }

    if ( this.isInteger ) {
      return null;
    }

    if ( this.isLine ) {
      return '';
    }

    if ( this.isPhoto ) {
      return [];
    }

    if ( this.isTernary ) {

      return EnumeratedConstantReference.notApplicable.codeAsNumber;
    }

    if ( this.isText ) {
      return '';
    }

    console.warn( [this], 'getDefaultAnswer', `unhandled type '${this.value.type}'` );
    return null;
  }


  /**
   * pre: `this.isEnum`
   */
  public getPopupLabelForEnumAnswer( providedAnswer: number ): string|null {

    if( !providedAnswer ) {

      return null;
    }

    for( const option of this.value.type2.typeEnum.options ) {
      if( option.codeAsNumber === providedAnswer ) {

        return option.popupLabel;
      }
    }

    console.warn( 'popup label not found', `providedAnswer: ${providedAnswer}` );

    return null;
  }


  protected onSetValue(value: IQuestion ) {
  }

  constructor( value: IQuestion ) {

    super( value );

    const type2: IType = {};

    if ( value.type === Question.TYPE_BOOLEAN ) {

      type2.typeBoolean = { scoring: {
                    onTrue: 0,
                    onFalse: 0,
                  }};
      this.isBoolean = true;

    } else if ( value.type === Question.TYPE_CM_MEASUREMENT ) {

      type2.typeCmMeasurement = {
        minValue: 0,
        maxValue: Number.MAX_VALUE,
        scoring: [],
      }
      this.isCmMeasurement = true;
    } else if ( value.type === Question.TYPE_ENUM ) {

      type2.typeEnum = {
        options: value.enumOptions,
        scoring: [],
      };

      this.isEnum = true;
    } else if ( value.type === Question.TYPE_FLOAT ) {


      type2.typeFloat = {};
      this.isFloat = true;
    } else if ( value.type === Question.TYPE_INTEGER ) {

      type2.typeInteger = {
        minValue: 0,
        maxValue: Number.MAX_VALUE,
        scoring: [],
      };
      this.isInteger = true;
    } else if ( value.type === Question.TYPE_LINE ) {

      type2.typeLine = {};
      this.isLine = true;
    } else if ( value.type === Question.TYPE_PHOTO ) {

      type2.typePhoto = {};
      this.isPhoto = true;
    } else if ( value.type === Question.TYPE_TERNARY ) {

      type2.typeTernary = {
        scoring: {
          onTrue: 0,
          onFalse: 0,
          onNotApplicable: 0,
      }};
      this.isTernary = true;

      this.value.enumOptions = [
        EnumeratedConstantReference.notApplicable,
        EnumeratedConstantReference.no,
        EnumeratedConstantReference.yes,
      ];

    } else if ( value.type === Question.TYPE_TEXT ) {

      type2.typeText = {
         scoring: {
          // any text defaults to ...
          anyText: value.maximumScore,
         },
      };
      this.isText = true;
    } else  {

      console.warn( [this], 'constructor', value.type );
    }

    if( !value.type2 ) {

      value.type2 = type2;
    }

  }

}

/**
 * @deprecated use `Question`
 */
export class QuestionReference extends Question {}
