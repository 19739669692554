import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient} from "@angular/common/http";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {PublicProxy} from "../../../firebase/functions/PublicProxy";
import {IFirebaseError} from "../../../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {BaseSessionContext} from "../../../service.session-context/BaseSessionContext";

/**
 * Generated class for the SignInHotelComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'authenticate-email-password',
  templateUrl: 'authenticate-email-password.component.html',
  styleUrls: ['authenticate-email-password.component.scss']
})
export class AuthenticateEmailPasswordComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'AuthenticateEmailPasswordComponent' );

  private _publicProxy: PublicProxy = null;

  hide = true;

  emailPasswordControls = {
    email: new FormControl(null, [ Validators.required, Validators.email ] ),
    password: new FormControl(null, [Validators.required] )
  };

  emailPasswordFormGroup = new FormGroup({
    email: this.emailPasswordControls.email,
    password: this.emailPasswordControls.password,
  });

  public authenticateError: string = null;


  @Output() signInCompleted = new EventEmitter<void>();

  @Output() passwordResetRequested = new EventEmitter<string>();

  public onResetPassword() {

    this.passwordResetRequested.emit( this.emailPasswordControls.email.value );
  }

  private _getEmailControlError(): string|null {

    if( this.emailPasswordControls.email.hasError( 'required')) {

      return 'Email address required';
    }

    if( this.emailPasswordControls.email.hasError( 'email')) {

      return 'Email address not valid';
    }

    return null;
  }


  async onEmailPasswordSubmit() {

    this.authenticateError = this._getEmailControlError();

    if( this.authenticateError ) {
      return;
    }


    if( this.emailPasswordControls.password.hasError( 'required')) {

      this.authenticateError = 'Email is required';
      return;
    }


    const email = this.emailPasswordControls.email.value.trim();
    const password = this.emailPasswordControls.password.value.trim();


    const signInError: IFirebaseError|null = await this.sessionContext.signInWithEmailAndPassword( email, password );



    if( signInError ) {

      // this._publicProxy.signInFailed( email );

      this._log.logError( 'Login failed', (event) => {
        event.addContext( 'email', email );
        event.addContext( 'signInError.code', signInError.code );
        event.addContext( 'signInError.message', signInError.message );
      })

      // this._log.error( 'signInError', signInError );
      this.authenticateError = "Login failed: Check email and password";

      // if( FirebaseErrorCodes.auth.WRONG_PASSWORD == signInError.code ) {
      //
      //   this.emailPasswordError = "The password is invalid or has not been setup";
      // } else {
      //
      //   this.emailPasswordError = signInError.message;
      // }

    } else {

      this.signInCompleted.emit();
    }

  }

  ngOnInit(): void {
    this._log.debug( 'ngOnInit ');
  }

  constructor( public sessionContext: BaseSessionContext,
               public afAuth: AngularFireAuth,
               http: HttpClient) {

    this._publicProxy = new PublicProxy( http );
  }



}
