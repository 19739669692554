import {EvaluationSection} from "../../model/module/EvaluationSection";
import {IAnswer} from "../../model/Answer";
import {IFirebaseAnswersListener} from "../../../browser.lib.evaluation-tool/firebase/realtime-database/answers-x/FirebaseAnswers";
import {AnswersReference} from "../../model/Answers";
import {AspectAnswers} from "../../model/AspectAnswers";
import {IFirebaseConnection} from "../../firebase/FirebaseConnection";
import {environment} from "../../../environments/environment";


export class FirebaseAnswersRedux {


  // private static _log = LoggerFactory.build( 'FirebaseAnswersRedux' );
  public static listener: IFirebaseAnswersListener = null;


  public static getPath( hotelKey: string, section: string ): string {

    const answer = `/answers/${hotelKey}/${section}`;
    // FirebaseAnswersRedux._log.debug( 'getPath', answer);
    return answer;
  }


  public static getPathX( propertyKey: string, aspectQuestions: EvaluationSection ) {

    const answer = `${environment.productConfig.firebaseAnswersRoot}/${propertyKey}/${aspectQuestions.value.firebaseAspectId}`;
    // FirebaseAnswersRedux._log.debug( 'aspectQuestions.value.firebaseAnswersRoot', aspectQuestions.value.firebaseAnswersRoot);
    // FirebaseAnswersRedux._log.debug( 'propertyKey', propertyKey);
    return answer;
  }

  public static async readBathroom(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<AnswersReference|null> {

    return FirebaseAnswersRedux.readReference( firebaseConnection, hotelKey, 'bathroom' );
  }

  public static async readBathroomValue(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<{ [key: string]: IAnswer; }|null> {

    return FirebaseAnswersRedux.readValue( firebaseConnection, hotelKey, 'bathroom' );
  }

  public static async readBedroom(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<AnswersReference|null> {

    return FirebaseAnswersRedux.readReference( firebaseConnection, hotelKey, 'bedroom' );
  }

  public static async readBedroomValue(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<{ [key: string]: IAnswer; }|null> {

    return FirebaseAnswersRedux.readValue( firebaseConnection, hotelKey, 'bedroom' );
  }

  public static async readGeneral(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<AnswersReference|null> {

    return FirebaseAnswersRedux.readReference( firebaseConnection, hotelKey, 'general' );
  }

  public static async readGeneralValue(firebaseConnection: IFirebaseConnection, hotelKey: string ): Promise<{ [key: string]: IAnswer; }|null> {

    return FirebaseAnswersRedux.readValue( firebaseConnection, hotelKey, 'general' );
  }

  private static async readReference( firebaseConnection: IFirebaseConnection, hotelKey: string, section: 'bathroom'|'bedroom'|'general' ): Promise<AnswersReference> {

    const value = await FirebaseAnswersRedux.readValue( firebaseConnection, hotelKey, section );
    return new AnswersReference( section, value, hotelKey );
  }

  private static async readValue( firebaseConnection: IFirebaseConnection, hotelKey: string, section: string ): Promise<{ [key: string]: IAnswer; }|null> {

    const path =  FirebaseAnswersRedux.getPath(hotelKey,section);

    return firebaseConnection.database2.getValue<{ [key: string]: IAnswer; }>( path );
  }


  public static async readReferenceX( firebaseConnection: IFirebaseConnection,
                                      hotelKey: string,
                                      aspectQuestions: EvaluationSection ): Promise<AspectAnswers> {


    const value = await FirebaseAnswersRedux.readValueX( firebaseConnection, hotelKey, aspectQuestions );
    return new AspectAnswers( value, aspectQuestions );
  }


  public static async readValueX( firebaseConnection: IFirebaseConnection,
                                  hotelKey: string,
                                  aspectQuestions: EvaluationSection ): Promise<{ [key: string]: IAnswer; }|null> {


    const path = FirebaseAnswersRedux.getPathX( hotelKey, aspectQuestions );
    return firebaseConnection.database2.getValue<{ [key: string]: IAnswer; }|null>( path );
  }



}
