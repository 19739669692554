import {EvaluationSection} from '../../../model/module/EvaluationSection';
// import {General} from './General';
// import {Bedroom} from './Bedroom';
// import {Bathroom} from './Bathroom';
import {IScoredQuestion} from "../../../model/ScoredQuestion";
import {IQuestion, Question} from "../../../model/Question";
import {Answer, IAnswer} from "../../../model/Answer";
import {EnumeratedConstants} from "../../../model/EnumeratedConstant";


export class BathroomQuestions01 {


  static readonly EN_SUITE_BATHROOM_DOOR_WIDTH = new Question({

    key: '-L6DTPdJCrTMAMMYdkfb', // uuid
    label: 'What is the width of the accessible bathroom door?',
    helpImage: 'artdept.ie/35_bathroom_door_width.png',
    helpText: 'In centimetres, measure the width of the bathroom door from inside the door frame as shown.\n',
    popupLabel: 'Bathroom door width',
    type: Question.TYPE_CM_MEASUREMENT,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        const value: number = answer.getIntegerValue();

        // [0-79]
        if (value < 80) {

          return 0;
        }

        // [80-]
        return 3;
      }

    },
    spreadsheetRow: 59,
    maximumScore: 3,
  } as IScoredQuestion);
  static readonly TOILET_HEIGHT = new Question({

    key: '-L6DTpHMghPxnYuIEvsf', // uuid
    label: 'What is the toilet height from the floor to the top of the toilet seat?',
    helpImage: 'artdept.ie/46_toilet_height.png',
    helpText: 'In centimetres, please measure the toilet seat height from the floor to the top of the toilet seat. ',
    popupLabel: 'Toilet height',
    type: Question.TYPE_CM_MEASUREMENT,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        const value: number = answer.getIntegerValue();

        // [0-45]
        if (value < 46) {

          return 0;
        }

        // [46-51]
        if (value < 52) {

          return 3;
        }

        // [52-]
        return 0;
      }

    },
    spreadsheetRow: 74,
    maximumScore: 3,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BathroomQuestions01.EN_SUITE_BATHROOM_DOOR_WIDTH,
    BathroomQuestions01.TOILET_HEIGHT,
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BathroomQuestions01.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BathroomQuestions02 {


  static readonly PHOTOS_OF_BATHROOM_DOORWAY = new Question({
    key: '-L6DTS91zwTezGIJ8YC6', // uuid
    label: 'Please take 1 photo of open doorway to en-suite accessible bathroom (in landscape mode)',
    helpImage: null,
    helpText: 'Take a good quality photo of the adapted bathroom doorway',
    popupLabel: 'Bathroom door',
    type: Question.TYPE_PHOTO,

    spreadsheetRow: 60,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BathroomQuestions02.PHOTOS_OF_BATHROOM_DOORWAY
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BathroomQuestions02.QUESTIONS);

  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BathroomQuestions03 {

  static readonly WET_ROOM_ROLL_IN_SHOWER = new Question({
    key: '-L7ZvUHo8g1s2sLjWCqJ', // uuid
    label: 'Does the accessible bathroom have a level floor roll in shower?',
    helpImage: 'artdept.ie/37_roll_in_shower.png',
    helpText: 'Does this adapted bathroom have a level floor roll-in shower wet room?',
    popupLabel: 'Wet room/roll-in shower\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 61,
    maximumScore: 3,
  } as IScoredQuestion);
  static readonly SEAT_PROVIDED = new Question({
    key: '-L6DTUEtCWfqX53qUeDs', // uuid
    label: 'Is there a seat in the roll in shower? This could be fixed or portable',
    helpImage: 'artdept.ie/38_shower_seat.png',
    helpText: 'What seating is provided in the roll in shower? Is it a fixed shower seat, moveable shower chair or no seat provided?',
    popupLabel: 'Seat provided\n',
    type: Question.TYPE_ENUM,
    scoreCalculator: {
      getScore(answer: Answer): number {

        const value = answer.getIntegerValue(EnumeratedConstants.ShowerSeatType.none.codeAsNumber);

        if (EnumeratedConstants.ShowerSeatType.none.codeAsNumber == value) {

          return 0;
        }

        if (EnumeratedConstants.ShowerSeatType.portable.codeAsNumber == value) {

          return 1;
        }

        if (EnumeratedConstants.ShowerSeatType.fixed.codeAsNumber == value) {

          return 3;
        }

        console.error([this], 'scoreCalculator', 'unexpected value', answer);
        return 0;
      }

    },
    dependant: {
      questionKey: '-L7ZvUHo8g1s2sLjWCqJ', // Does the accessible bathroom have a level floor roll in shower?
    },
    maximumScore: 3,
    enumOptions: EnumeratedConstants.ShowerSeatType.all,
  } as IScoredQuestion);
  static readonly BATH_TUB = new Question({
    key: '-L6DTVcbcgzIN8_BrgDv', // uuid
    label: 'Does the accessible bathroom have a bath tub?',
    helpImage: 'artdept.ie/36_bathtub.png',
    helpText: 'Does this adapted bathroom have a bath tub?',
    popupLabel: 'Bath tub\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 61,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly HAND_RAIL_ON_INSIDE_DOOR = new Question({

    key: '-L6DTWwW9qXWVwHWpsvs', // uuid
    label: 'Is there a hand rail on the inside of the accessible bathroom door? ',
    helpImage: 'artdept.ie/39_handrail_door.png',
    helpText: 'Does the adapted bathroom have a handrail on the inside of the door for easy closing\n',
    popupLabel: 'Hand rail on inside door\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 66,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly ADJUSTABLE_SHOWER_HEAD_HEIGHT = new Question({
    key: '-L6DTZoHtY1Cm9FeAEZx', // uuid
    label: 'Is the shower head height adjustable?\n',
    helpImage: 'artdept.ie/40_adjust_showerhead.png',
    helpText: 'Is the shower head able to be lowered or raised for someone in a seated position\n',
    popupLabel: 'Adjustable shower head height\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 67,
    maximumScore: 2,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BathroomQuestions03.WET_ROOM_ROLL_IN_SHOWER,
    BathroomQuestions03.SEAT_PROVIDED,
    BathroomQuestions03.BATH_TUB,
    BathroomQuestions03.HAND_RAIL_ON_INSIDE_DOOR,
    BathroomQuestions03.ADJUSTABLE_SHOWER_HEAD_HEIGHT,
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BathroomQuestions03.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BathroomQuestions04 {


  static readonly GRAB_RAILS = new Question({
    key: '-L6DTdIk-C5IorurlqWr', // uuid
    label: 'Are there grab rails around the shower and toilet?',
    helpImage: 'artdept.ie/41_shower_toilet_grabrails.png',
    helpText: 'Are there grab rails around the shower and toilet in the adapted bathroom, positioned to assist balance\n',
    popupLabel: 'Grab rails\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 68,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly ALARM_CORD_OR_BUTTON_FOR_EMERGENCY_USE = new Question({
    key: '-L6DTeq5__gGa23m_6aT', // uuid
    label: 'Do you have an alarm cord or button in the accessible bathroom for emergency use?  ',
    helpImage: 'artdept.ie/42_alarm_cord.png',
    helpText: 'Is there a pull cord or button to trigger an alarm in the adapted bathroom\n',
    popupLabel: 'Alarm cord/button for emergency use\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 69,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly CLEAR_SPACE_UNDER_SINK = new Question({

    key: '-L6DTgLZqSTISK9f04Ao', // uuid
    label: 'Is there a clear space under the sink to enable a wheelchair user to fit under?\n',
    helpImage: 'artdept.ie/43_sink_height.png',
    helpText: 'Is there space under the sink to enable a wheelchair user to fit under\n',
    popupLabel: 'Clear space under sink\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 70,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly LOWER_MIRROR_HEIGHT = new Question({

    key: '-L6DThiSWXX_F4xrcljo', // uuid
    label: 'Do you have a bathroom mirror at a lower height?',
    helpImage: 'artdept.ie/44_bathroom_mirror.png',
    helpText: 'Is the mirror at a lower level to see your reflection from a seating position or for someone of short stature?',
    popupLabel: 'Lower mirror height\n',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 1,
  } as IScoredQuestion);
  static readonly LEVER_TAP_HANDLES = new Question({

    key: '-L6DTj5T0adhCWaopGlM', // uuid
    label: 'Does the sink have lever tap handles?\n',
    helpImage: 'artdept.ie/45_lever_tap_handles.png',
    helpText: 'Do the tap handles have longer levers which makes it easier for people with poor hand dexterity to turn on and off?',
    popupLabel: 'Lever tap handles\n',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 2,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BathroomQuestions04.GRAB_RAILS,
    BathroomQuestions04.ALARM_CORD_OR_BUTTON_FOR_EMERGENCY_USE,
    BathroomQuestions04.CLEAR_SPACE_UNDER_SINK,
    BathroomQuestions04.LOWER_MIRROR_HEIGHT,
    BathroomQuestions04.LEVER_TAP_HANDLES,
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BathroomQuestions04.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BathroomQuestions05 {

  static readonly PHOTOS_OF_ADAPTED_BATHROOM = new Question({

    key: '-L6DTm7mUZrnZkaw4Zo3', // uuid
    label: 'Please take 3 photos of en-suite accessible bathroom from different angles (in landscape mode)',
    helpImage: null,
    helpText: 'Take 2 good quality photo of the adapted bathroom from different viewpoints',
    popupLabel: 'Bathroom internally',
    type: Question.TYPE_PHOTO,

    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BathroomQuestions05.PHOTOS_OF_ADAPTED_BATHROOM
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BathroomQuestions05.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BathroomQuestions06 {


  static readonly COMMENTS_OR_INFORMATION_ABOUT_THE_ACCESSIBLE_BATHROOM = new Question({
    key: '-L6DTr7ZmqARr6pkh5Ro', // uuid
    label: 'Do you have any other comments or information about the accessible bathroom?  Anything we have missed which you would like to add?',
    helpImage: null,
    helpText: 'Would you like to add any information about other accessible bathroom features you have which we haven\'t asked about?',
    popupLabel: null,
    type: Question.TYPE_TEXT,

    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BathroomQuestions06.COMMENTS_OR_INFORMATION_ABOUT_THE_ACCESSIBLE_BATHROOM
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BathroomQuestions06.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}

export class BedroomQuestions01 {


  static readonly NUMBER_OF_ACCESSIBLE_BEDROOMS = new Question({

      key: '-L6DTt_i9TDqVqrtQqlR', // uuid
      label: 'How many fully accessible bedrooms do you have in this hotel?',
      helpImage: null,
      helpText: 'How many fully accessible bedrooms do you have in your hotel that are suitable for wheelchair users? Do not include partially accessible bedrooms in this number.',
      popupLabel: 'Number of accessible bedrooms',
      type: Question.TYPE_INTEGER,
      scoreCalculator: {

        // pre: answer.value.value != null
        getScore(answer: Answer): number {


          const value: number = answer.getIntegerValue();

          if (0 === value) {
            return 0;
          }
          if (1 === value) {
            return 3;
          }
          if (2 === value) {
            return 4;
          }

          // maximumScore
          return 5;
        }

      },
      spreadsheetRow: 35,
      maximumScore: 5,
    } as IScoredQuestion );
  static readonly ACCESSIBLE_BEDROOMS_AVAILABLE_ON_GROUND_FLOOR = new Question({

    key: '-L6DTuvta24mUCyD6Zwb', // uuid
    label: 'Do you have any fully accessible bedrooms available on the ground floor?',
    helpImage: null,
    helpText: 'Are any of the fully accessible bedrooms located on the ground floor for someone who doesn\'t want to rely on the stairs/lift/elevator?',
    popupLabel: 'Accessible bedroom available on ground floor',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 38,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly INTERCONNECTING_ROOM_TO_ACCESSIBLE_ROOM = new Question({

    key: '-L6DTwffSrlqJB9Oy02S', // uuid
    label: 'Do any of the accessible bedrooms have an interconnecting/adjoining bedroom?',
    helpImage: 'artdept.ie/18_connect_room.png',
    helpText: 'Do any of the fully accessible bedrooms have adjoining rooms attached to them?\n',
    popupLabel: 'Interconnecting room to accessible room',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 37,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly BEDROOM_DOOR_WIDTH = new Question({
      key: '-L6DU0p7kMQPxGSBULag', // uuid
      label: 'What is the accessible bedroom door width?',
      helpImage: 'artdept.ie/19_bedroom_door_width.png',
      helpText: 'In centimetres, measure the accessible bedroom door width from inside door frame\n',
      popupLabel: 'Bedroom door width',
      type: Question.TYPE_CM_MEASUREMENT,
      scoreCalculator: {

        // pre: answer.value.value != null
        getScore(answer: Answer): number {

          const value: number = answer.getIntegerValue();

          // 0 - 69
          if (value < 70) {

            return 0;
          }

          // 70 - 80
          if (value < 81) {

            return 1;
          }

          // > 80
          return 3;
        }

      },
      spreadsheetRow: 39,
      maximumScore: 3,
    } as IScoredQuestion );


  static readonly QUESTIONS: Question[] = [
    BedroomQuestions01.NUMBER_OF_ACCESSIBLE_BEDROOMS,
    BedroomQuestions01.ACCESSIBLE_BEDROOMS_AVAILABLE_ON_GROUND_FLOOR,
    BedroomQuestions01.INTERCONNECTING_ROOM_TO_ACCESSIBLE_ROOM,
    BedroomQuestions01.BEDROOM_DOOR_WIDTH
  ];

  static readonly QUESTION_VALUES: IScoredQuestion[] = Question.array.toValues(BedroomQuestions01.QUESTIONS) as IScoredQuestion[];


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BedroomQuestions02 {


  static readonly PHOTOS_OF_BEDROOM_DOOR = new Question({
      key: '-L6DTyiEECV_ZaDfKRNe', // uuid
      label: 'Please take 2 photos of the Accessible Bedroom doorway - 1 Open & 1 Closed (in landscape mode)',
      helpImage: null,
      helpText: 'Take 1 good quality photo of accessible bedroom door open',
      popupLabel: 'Bedroom door',
      type: Question.TYPE_PHOTO,

      spreadsheetRow: 38,
      maximumScore: 0,
    } as IScoredQuestion );


  static readonly QUESTIONS: Question[] = [
    BedroomQuestions02.PHOTOS_OF_BEDROOM_DOOR
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BedroomQuestions02.QUESTIONS);

  static readonly QUESTION_IDS = {};


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BedroomQuestions03 {


  static readonly SPYHOLE_AT_LOWER_HEIGHT = new Question({
    key: '-L8nqehj8NtphZQQ9gsO', // uuid
    label: 'Is there a spyhole on the bedroom door at a lower height?',
    helpImage: 'artdept.ie/20_spyhole.png',
    helpText: 'Is there a second spyhole located at a lower height on the accessible bedroom door so that a wheelchair user or someone of a smaller stature can look through it?',
    popupLabel: 'Spyhole at lower height\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 36,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly TELEPHONE_REACHABLE_FROM_BED = new Question({

    key: '-L6DU3pnH0YIHTh1Bi4D', // uuid
    label: 'Is there a telephone reachable from the bed?',
    helpImage: 'artdept.ie/21_telephone.png',
    helpText: 'Is there a telephone on bedside locker which is reachable from the bed\n',
    popupLabel: 'Telephone reachable from bed\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 40,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly LIGHT_SWITCH_REACHABLE_FROM_BED = new Question({
    key: '-L6DU5Ds_eI-pqT1MLLm', // uuid
    label: 'Is there a light switch reachable from the bed?\n',
    helpImage: 'artdept.ie/22_light_switches.png',
    helpText: 'Are there any light switches reachable from the bed?',
    popupLabel: 'Light switch reachable from bed\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 58,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly ELECTRICAL_SOCKET_REACHABLE_FROM_BED = new Question({
    key: '-LdJlpjoVYyIDEYGAidj', // uuid
    label: 'Is there an electrical socket reachable from the bed?',
    helpImage: 'artdept.ie/49_bedside_socket.png',
    helpText: 'Are electrical points/sockets provided close to the bed for convience?',
    popupLabel: 'Electrical socket reachable from bed',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 59,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly TV_REMOTE_REACHABLE_FROM_BED = new Question({
    key: '-L7YnkWS4aY4HxT8uqNo', // uuid
    label: 'Is the TV remote control reachable from the bed?',
    helpImage: 'artdept.ie/23_remote_control.png',
    helpText: 'Is the TV remote control on or beside the bed in the accessible bedroom?\n',
    popupLabel: 'TV remote reachable from bed\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 42,
    maximumScore: 1,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BedroomQuestions03.SPYHOLE_AT_LOWER_HEIGHT,
    BedroomQuestions03.TELEPHONE_REACHABLE_FROM_BED,
    BedroomQuestions03.LIGHT_SWITCH_REACHABLE_FROM_BED,
    BedroomQuestions03.ELECTRICAL_SOCKET_REACHABLE_FROM_BED,
    BedroomQuestions03.TV_REMOTE_REACHABLE_FROM_BED,
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BedroomQuestions03.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BedroomQuestions04 {
  static readonly FLOOR_SURFACE_TYPE = new Question({
    key: '-LdcTDumQ6zkkld1slfR', // uuid
    label: 'What is the floor surface in the bedroom?',
    helpImage: 'artdept.ie/48_floor_type.png',
    helpText: 'What type of flooring is on the bedroom floor?',
    popupLabel: '(type) floor surface',
    type: Question.TYPE_ENUM,
    scoreCalculator: {
      getScore(answer: Answer): number {

        const value = answer.getIntegerValue(); // defaults to 0

        if (EnumeratedConstants.BedroomFloorSurface.carpet.codeAsNumber === value) {

          return 0;
        }

        if (EnumeratedConstants.BedroomFloorSurface.wood.codeAsNumber === value) {

          return 1;
        }

        if (EnumeratedConstants.BedroomFloorSurface.tile.codeAsNumber === value) {

          return 1;
        }

        if (EnumeratedConstants.BedroomFloorSurface.other.codeAsNumber === value) {

          return 0;
        }

        console.error([this], 'scoreCalculator', 'unexpected value', answer);
        return 0;
      }

    },
    maximumScore: 1,
    enumOptions: EnumeratedConstants.BedroomFloorSurface.all,
    spreadsheetRow: 62,
  } as IScoredQuestion);
  static readonly MINI_FRIDGE_AVAILABLE_IN_ROOM = new Question({
    key: '-L6DU9Tsu-B6srCBK9QJ', // uuid
    label: 'Do you have a mini fridge in the bedroom for medications or available on request?',
    helpImage: 'artdept.ie/24_mini_fridge.png',
    helpText: 'Is there a mini fridge in the fully adapted bedroom to enable someone to store medication? Or is there a mini fridge available on request?',
    popupLabel: 'Mini fridge available in room\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 44,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly REFRIGERATION_AVAILABLE_FOR_MEDICATIONS = new Question({
    key: '-L8Yd9Aq46wnsNgrXLmh', // uuid
    label: 'Do you have any refrigeration options for medication available on request?',
    helpImage: null,
    helpText: 'If you don\'t have a mini fridge in the bedroom, are there any refrigeration facilities available for guests to store medication in your hotel?',
    popupLabel: 'Refrigeration available for medications\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 43,
    maximumScore: 0,
    dependant: {
      questionKey: '-L6DU9Tsu-B6srCBK9QJ' // Do you have a mini fridge in the bedroom for medications or available on request?
    }
  } as IScoredQuestion);
  static readonly LOWER_HANGING_RAIL_IN_WARDROBE = new Question({

    key: '-L6DUEQ3ubJgoD4VtWMz', // uuid
    label: 'Do you have a lower hanging rail in the wardrobe?\n',
    helpImage: 'artdept.ie/25_wardrobe.png',
    helpText: 'Is there a lower hanging clothes rail provided in storage area (wardrobe)so that a person sitting in a wheelchair or a person of short stature can reach.',
    popupLabel: 'Lower hanging rail in wardrobe\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 45,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly SUBTITLE_OPTION_AVAILABLE_ON_TV = new Question({

    key: '-L6DUFfiGVqRd-eUhLNo', // uuid
    label: 'Does the TV have a closed captioning option (subtitles) for hearing impaired?\n',
    helpImage: 'artdept.ie/26_tv_captioning.png',
    helpText: 'Is there a function to turn on subtitles on the TV screen for a person with a hearing impairment?',
    popupLabel: 'Subtitle option available on TV\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 46,
    maximumScore: 1,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BedroomQuestions04.FLOOR_SURFACE_TYPE,
    BedroomQuestions04.MINI_FRIDGE_AVAILABLE_IN_ROOM,
    BedroomQuestions04.REFRIGERATION_AVAILABLE_FOR_MEDICATIONS,
    BedroomQuestions04.LOWER_HANGING_RAIL_IN_WARDROBE,
    BedroomQuestions04.SUBTITLE_OPTION_AVAILABLE_ON_TV,
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(BedroomQuestions04.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BedroomQuestions05 {


  static readonly FLASHING_BEACON_VISUAL_FIRE_ALARM = new Question({

    key: '-L6DUH7OROer8dijZTWU', // uuid
    label: 'Is there a visual alarm/flashing beacon alert in the room? \n',
    helpImage: 'artdept.ie/27_flashing_beacon.png',
    helpText: 'Do you have an alternative alarm system like a flashing beacon for those with hearing impairments?\n',
    popupLabel: 'Flashing beacon visual fire alarm\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 47,
    maximumScore: 1,
  } as IScoredQuestion);
  static readonly VIBRATING_PILLOW_ALARM = new Question({
    key: '-L82bhg77tDKfz0XqUcX', // uuid
    label: 'Do you have a vibrating pillow alarm available on request?\n',
    helpImage: 'artdept.ie/28_vibrating_pillow.png',
    helpText: 'Do you have an alternative alarm system like a vibrating pillow alarm for those with hearing impairments?',
    popupLabel: 'Vibrating pillow alarm\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 48,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly CEILING_TRACK_HOIST = new Question({

    key: '-L6DUIRCbPfu0RBtFCmb', // uuid
    label: 'Do any of the accessible bedrooms have a ceiling track hoist?',
    helpImage: 'artdept.ie/29_ceiling_hoist.png',
    helpText: 'Do any of your adapted rooms have a hoist built into the ceiling?\n',
    popupLabel: 'Ceiling track hoist\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 49,
    maximumScore: 4,
  } as IScoredQuestion)
  static readonly HEIGHT_ADJUSTABLE_BED = new Question({
    key: '-L6DUJfddpX5vW0f00S7', // uuid
    label: 'Do any of the accessible bedrooms have a height adjustable bed?',
    helpImage: 'artdept.ie/30_height_adjust_bed.png',
    helpText: 'Do any of your accessible bedrooms have a bed which can be raised or lowered electronically?\n',
    popupLabel: 'Height adjustable bed\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 50,
    maximumScore: 3,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BedroomQuestions05.FLASHING_BEACON_VISUAL_FIRE_ALARM,
    BedroomQuestions05.VIBRATING_PILLOW_ALARM,
    BedroomQuestions05.CEILING_TRACK_HOIST,
    BedroomQuestions05.HEIGHT_ADJUSTABLE_BED,
  ];

  static readonly QUESTION_VALUES: IQuestion[] = BedroomQuestions05.QUESTIONS.map((e) => {
    return e.value
  });

  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BedroomQuestions06 {


  static readonly PHOTOS_OF_ACCESSIBLE_BEDROOM = new Question({

    key: '-L6DUMJJt6Y2xkGxSAcq', // uuid
    label: 'Please take 3 photos of accessible bedroom from different angles  (in landscape mode)',
    helpImage: null,
    helpText: 'Take 3 good quality photos of the accessible bedroom from different angles',
    popupLabel: 'Bedroom internally',
    type: Question.TYPE_PHOTO,

    spreadsheetRow: 52,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BedroomQuestions06.PHOTOS_OF_ACCESSIBLE_BEDROOM
  ];

  static readonly QUESTION_VALUES: IQuestion[] = BedroomQuestions06.QUESTIONS.map((e) => {
    return e.value
  });

  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BedroomQuestions07 {

  static readonly WIDEST_SPACE_AT_SIDE_OF_BED = new Question({

    key: '-L6DURK38IpveIbqMt-d', // uuid
    label: 'What is the widest free space at the side of the bed? ',
    helpImage: 'artdept.ie/34_widest_free_space.png',
    helpText: 'In centimetres, measure the larger space at one side of the bed so a wheelchair can fit beside the bed.',
    popupLabel: 'Space at side of bed',
    type: Question.TYPE_CM_MEASUREMENT,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {


        const value: number = answer.getIntegerValue();

        // [91-]
        if (value > 90) {
          return 3;
        }

        // [80-90]
        if (value > 79) {
          return 2;
        }

        return 0;
      }

    },
    spreadsheetRow: 56,
    maximumScore: 3,
  } as IScoredQuestion);
  static readonly HEIGTH_OF_BED_FROM_FLOOR_TO_TOP_OF_MATTRESS = new Question({

    key: '-L6DUOfGxfEyd1_b6eDK', // uuid
    label: 'What is the height of the bed from the floor to the top of the mattress?',
    helpImage: 'artdept.ie/31_bed_height_floor_top_matress.png',
    helpText: 'In centimetres, measure the bed height from the floor to the top of the mattress as shown.\n',
    popupLabel: 'Bed height',
    type: Question.TYPE_CM_MEASUREMENT,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        const value: number = answer.getIntegerValue();

        // [45-55]
        if (value > 44 && value < 56) {

          return 3;
        }

        return 0;
      }

    },
    spreadsheetRow: 52,
    maximumScore: 3,
  } as IScoredQuestion);
  static readonly GAP_UNDER_BED = new Question({
    key: '-L82djnLe91vpX2v5wZs', // uuid
    label: 'Is there a gap under the bed for manoeuvring a mobile hoist?',
    helpImage: 'artdept.ie/32_gap_under_bed.png',
    helpText: 'Is there clear space underneath the bed for the legs of a mobile hoist to fit underneath it? ',
    popupLabel: 'Gap under bed\n',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 53,
    maximumScore: 0,
  } as IScoredQuestion);
  static readonly HEIGHT_OF_GAP_UNDER_THE_BED = new Question({
    key: '-L6DUPy6z0XDM_8XJrzo', // uuid
    label: 'What is the height of the gap under the bed - from the floor to the underside of the bed? ',
    helpImage: 'artdept.ie/33_height_gap_under_bed.png',
    helpText: 'In centimetres, measure the height of the gap under the bed from the floor to the top of the underside of the bed as shown.',
    popupLabel: 'Gap under the bed',
    type: Question.TYPE_CM_MEASUREMENT,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        const value: number = answer.getIntegerValue();

        // [30-]
        if (value > 29) {
          return 2;
        }

        return 0;
      }

    },
    spreadsheetRow: 55,
    maximumScore: 2,
    dependant: {
      questionKey: '-L82djnLe91vpX2v5wZs', // Is there a gap under the bed for manoeuvring a mobile hoist?
    }
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    BedroomQuestions07.WIDEST_SPACE_AT_SIDE_OF_BED,
    BedroomQuestions07.HEIGTH_OF_BED_FROM_FLOOR_TO_TOP_OF_MATTRESS,
    BedroomQuestions07.GAP_UNDER_BED,
    BedroomQuestions07.HEIGHT_OF_GAP_UNDER_THE_BED,
  ];

  static readonly QUESTION_VALUES: IQuestion[] = BedroomQuestions07.QUESTIONS.map((e) => {
    return e.value
  });


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class BedroomQuestions08 {

  static readonly COMMENTS_OR_INFORMATION_ABOUT_THE_ACCESSIBLE_BEDROOM = new Question({
    key: '-L6DUTNhUPyXteXAEuad', // uuid
    label: 'Do you have any other comments or information about the accessible bedroom?  Anything we have missed which you would like to add? \n',
    helpImage: null,
    helpText: 'Would you like to add any information about other accessible bedroom features you have which we haven\'t asked about?',
    popupLabel: null,
    type: Question.TYPE_TEXT,

    maximumScore: 0,
  } as IScoredQuestion);

  static readonly QUESTIONS: Question[] = [
    BedroomQuestions08.COMMENTS_OR_INFORMATION_ABOUT_THE_ACCESSIBLE_BEDROOM
  ];

  static readonly QUESTION_VALUES: IQuestion[] = BedroomQuestions08.QUESTIONS.map((e) => {
    return e.value
  });


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}

export class GeneralQuestions01 {


  static readonly ACCESSIBLE_PARKING_SPACES = new Question({
    key: '-L68__m25kCYRyJd3Ee-', // uuid
    label: 'How many wheelchair parking spaces do you have?\n',
    helpImage: 'artdept.ie/1_Accessible_parking.png',
    helpText: 'How many designated parking spaces are available for disabled people on your premises?\n',
    popupLabel: 'Accessible parking spaces',
    type: Question.TYPE_INTEGER,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        const value: number = answer.getIntegerValue();

        if (0 === value) {
          return 0;
        }
        if (1 === value) {
          return 1;
        }
        if (2 === value) {
          return 2;
        }

        // 3 ...
        return answer.question.value.maximumScore;
      }

    },
    maximumScore: 3,
  } as IScoredQuestion);
  static readonly NUMBER_OF_STEPS_INTO_HOTEL = new Question({
      key: '-L6DSSmoBN7vDZmddfIG', // uuid
      label: 'How many steps are there up to the main hotel entrance?\n',
      helpImage: 'artdept.ie/4_How_many_steps.png',
      helpText: 'If you have only steps, how many steps do you need to take to reach the entrance to your hotel\n',
      popupLabel: 'Number of steps into hotel',
      type: Question.TYPE_INTEGER,

      maximumScore: 0,
    } as IScoredQuestion );
  static readonly STEP_FREE_ENTRANCE = new Question({
    key: '-L6DSPiulNXmCtxJxElU', // uuid
    label: 'Do you have step free access into the main hotel entrance?  This could be level access, ramp access or via a lift.\n',
    helpImage: 'artdept.ie/2_a_step_free_access.png',
    helpImages: [
      'artdept.ie/2_a_step_free_access.png',
      'artdept.ie/2_b_ramp_steps.png',
      'artdept.ie/2_c_platform_lift.png',
    ],
    helpText: 'Is there a way to gain access into your hotel without the need to climb steps?',
    popupLabel: 'Step free entrance',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 3,
  } as IScoredQuestion);
  static readonly ALTERNATIVE_ACCESSIBLE_ENTRANCE = new Question({
      key: '-L6DSRo6vhlaMi5auigl', // uuid
      label: 'Do you have an alternative accessible entrance?',
      helpImage: 'artdept.ie/3_Alt_entrance.png',
      helpText: 'Is there an alternative entrance to your hotel via car park, ramp or lift that a person with a buggy, heavy suitcases or a wheelchair user can gain access?',
      popupLabel: 'Alternative accessible entrance',
      type: Question.TYPE_ENUM,
      scoreCalculator: {
        getScore(answer: Answer): number {


          // see `GeneralQuestions01.calculateScoreAcrossQuestions`
          return 0;
        }

      },
      maximumScore: 0, // score is bound up in `FREE_STEPS_QID`, see spreadsheet rules for calculating this score
      enumOptions: EnumeratedConstants.AlternativeAccessibleEntranceOptions.all,
    } as IScoredQuestion );
  static readonly AUTOMATIC_OR_MANUAL_DOOR_AT_ENTRANCE = new Question({
    key: '-L6DSTqBmCMPe0O-_C-q', // uuid
    label: 'What type of door do you have into the main hotel entrance? Please choose most accessible one.',
    helpImage: 'artdept.ie/5_Type_of_Door.png',
    helpText: 'Is the entrance door automatic or do you need to manually open the door? Choose most applicable one.',
    popupLabel: 'Automatic or Manual door at entrance',
    type: Question.TYPE_ENUM,
    scoreCalculator: {
      getScore(answer: Answer): number {

        const value = answer.getIntegerValue(EnumeratedConstants.EntranceDoorTypes.manual.codeAsNumber);

        if (EnumeratedConstants.EntranceDoorTypes.manual.codeAsNumber === value) {

          return 1;
        }

        if (EnumeratedConstants.EntranceDoorTypes.automatic.codeAsNumber === value) {

          return 3;
        }

        // for legacy data
        if (0 == value) {
          console.warn([this], 'scoreCalculator', '0 == value', answer);
          return 1;
        }

        console.error([this], 'scoreCalculator', 'unexpected value', answer);
        return 0;
      }

    },
    maximumScore: 3,
    enumOptions: EnumeratedConstants.EntranceDoorTypes.all,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions01.ACCESSIBLE_PARKING_SPACES,
    GeneralQuestions01.NUMBER_OF_STEPS_INTO_HOTEL,
    GeneralQuestions01.STEP_FREE_ENTRANCE,
    GeneralQuestions01.ALTERNATIVE_ACCESSIBLE_ENTRANCE,
    GeneralQuestions01.AUTOMATIC_OR_MANUAL_DOOR_AT_ENTRANCE,
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions01.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    let answer = 0;

    {
      const freeSteps = answerValues[GeneralQuestions01.STEP_FREE_ENTRANCE.value.key];
      const alternativeAccessibleEntrance = answerValues[GeneralQuestions01.ALTERNATIVE_ACCESSIBLE_ENTRANCE.value.key];

      if (freeSteps && alternativeAccessibleEntrance) {

        // If yes for Q. 1 and yes for Q.2, then atribute 3pts altogether between Q. 1 and Q 2.
        if (freeSteps.value) {

          // already got the 3 points
        } else if (alternativeAccessibleEntrance.value) {

          // If No to Q.1 then = 2 pts for any selection here in Q.2
          answer += 2;
        }

      } else {

        console.error('GeneralQuestions01', 'calculateScoreAcrossQuestions', freeSteps, alternativeAccessibleEntrance);
      }
    }

    return answer;
  }

}
export class GeneralQuestions02 {

  static readonly ENTRANCE_DOOR_WIDTH_MEASUREMENT = new Question({
    key: '-L6DUWeouya_jPxYHCr4', // uuid
    label: 'What is the main accessible entrance door width measurement?\n',
    helpImage: 'artdept.ie/6_main_door_width.png',
    helpText: 'In centimetres, please measure the width of the main accessible entrance door.',
    popupLabel: 'Entrance door width',
    type: Question.TYPE_CM_MEASUREMENT,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        const value: number = answer.getIntegerValue();

        // [0-79]
        if (value < 80) {

          return 0;
        }

        // [80-90]
        if (value < 91) {

          return 2;
        }

        // [90-]
        return 3;
      }

    },
    maximumScore: 3,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions02.ENTRANCE_DOOR_WIDTH_MEASUREMENT
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions02.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions03 {


  static readonly PHOTOS_OF_OUTSIDE = new Question({

    key: '-L6DTLoSb79g01k5lEFp', // uuid
    label: 'Please take 2 photos of outside of building - Accessible hotel entrance (in landscape mode)',
    helpImage: null,
    helpText: 'Take 2 good quality photos of 1. The main entrance and 2. Wheelchair accessible entrance (if applicable). Include any steps, ramps or lifts.',
    popupLabel: 'Hotel entrance',
    type: Question.TYPE_PHOTO,

    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions03.PHOTOS_OF_OUTSIDE
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions03.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions04 {


  static readonly LOWER_COUNTER_AT_RECEPTION = new Question({

    key: '-L6DUYviwVH0ANE8BhJP', // uuid
    label: 'Do you have a lower counter/desk at reception?\n',
    helpImage: 'artdept.ie/7_lower_reception.png',
    helpText: 'Is any part of the reception desk at a lower level for wheelchair users and people of short stature.\n',
    popupLabel: 'Lower counter at reception',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 1,
  } as IScoredQuestion);
  static readonly TTY_TEXT_TELEPHONE = new Question({
    key: '-LdNlNPm7xyhcTPeUbwX', // uuid
    label: 'Do you have a TTY or TDD text telephone?\n',
    helpImage: 'artdept.ie/47_tty_available.png',
    helpText: 'A TTY / TDD device lets people who are deaf, hard of hearing, or speech-impaired use a keyboard telephone to communicate.\n',
    popupLabel: 'TTY text telephone',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 1,
  } as IScoredQuestion);
  static readonly INDUCTION_LOOP_IN_RECEPTION_AREA = new Question({

    key: '-L6DU_2ueTTl0RA9YYNE', // uuid
    label: 'Do you have an induction loop in the reception area?',
    helpImage: 'artdept.ie/8_induction_loop.png',
    helpText: 'Is there an induction loop installed in the reception area for persons with hearing difficulties.\n',
    popupLabel: 'Induction loop in reception area',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 2,
  } as IScoredQuestion);
  static readonly GUIDE_DOGS_WELCOME = new Question({

    key: '-L6DUaSSJ7RNVE5cevfu', // uuid
    label: 'Are guide dogs and service dogs welcome?',
    helpImage: 'artdept.ie/9_guide_dogs.png',
    helpText: 'Are guide dogs/service dogs welcome in your general hotel area and to stay in the bedroom?',
    popupLabel: 'Guide dogs/service dogs welcome',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 2,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions04.LOWER_COUNTER_AT_RECEPTION,
    GeneralQuestions04.TTY_TEXT_TELEPHONE,
    GeneralQuestions04.INDUCTION_LOOP_IN_RECEPTION_AREA,
    GeneralQuestions04.GUIDE_DOGS_WELCOME
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions04.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions05 {

  static readonly PHOTOS_OF_RECEPTION = new Question( {

      key: '-L6DUgrH2S9Kv6VxIxg_', // uuid
      label: 'Please take 2 photos of the reception area (in landscape mode)',
      helpImage: null,
      helpText: 'Take 1 good quality photo of the reception area and 1 good quality photo of the accessible dining area',
      popupLabel: 'Reception area',
      type: Question.TYPE_PHOTO,

      maximumScore: 0,
    } as IScoredQuestion );

  static readonly QUESTIONS: Question[] = [
    GeneralQuestions05.PHOTOS_OF_RECEPTION
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions05.QUESTIONS);

  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions06 {


  static readonly WHEELCHAIR_AVAILABLE_TO_BORROW = new Question({
    key: '-L6DUbw12EOhbryKK0hw', // uuid
    label: 'Do you have a wheelchair available to borrow?\n',
    helpImage: 'artdept.ie/10_wheelchair.png',
    helpText: 'Does your hotel provide the facility for guests to borrow a wheelchair\n',
    popupLabel: 'Wheelchair available to borrow',
    type: Question.TYPE_BOOLEAN,

    maximumScore: 2,
  } as IScoredQuestion);
  static readonly WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER = new Question({
    key: '-L6DUjPR316laxgEUxak', // uuid
    label: 'Do you have a wheelchair accessible bathroom in the hotel lobby/foyer area for general use?',
    helpImage: 'artdept.ie/12_foyer_restrooms.png',
    helpText: 'Do you have a wheelchair accessible bathroom anywhere in the hotel for general use?',
    popupLabel: 'Wheelchair accessible bathroom near foyer area',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 18,
    maximumScore: 3,
  } as IScoredQuestion);
  static readonly BATHROOM_DOOR_WIDTH_MEASUREMENT = new Question({
    key: '-LC-VVJqmMZ38CK0T9__', // uuid
    label: 'What is the door width of this general accessible bathroom?',
    helpImage: 'artdept.ie/35_bathroom_door_width.png',
    helpText: 'In centimetres, please measure the width of the wheelchair accessible bathroom door',
    popupLabel: 'Hotel lobby bathroom door width',
    type: Question.TYPE_CM_MEASUREMENT,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        const value: number = answer.getIntegerValue();

        // [0-79]
        if (value < 80) {

          return 0;
        }

        // [80-90]
        if (value < 91) {

          return 2;
        }

        // [90-]
        return 3;
      }
    },
    spreadsheetRow: 18,
    maximumScore: 3,
    dependant: {
      questionKey: '-L6DUjPR316laxgEUxak',
    }
  } as IScoredQuestion);
  static readonly BABY_CHANGING_FACILITIES = new Question({
      key: '-L6DUkmgUA4IsBhSevg1', // uuid
      label: 'Do you have baby changing facilities in your hotel?\n',
      helpImage: 'artdept.ie/13_baby_changing.png',
      helpText: 'Are baby changing facilities available in your hotel\n',
      popupLabel: 'Baby changing facilities',
      type: Question.TYPE_BOOLEAN,

      spreadsheetRow: 21,
      maximumScore: 2,
    } as IScoredQuestion );


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions06.WHEELCHAIR_AVAILABLE_TO_BORROW,
    GeneralQuestions06.WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER,
    GeneralQuestions06.BATHROOM_DOOR_WIDTH_MEASUREMENT,
    GeneralQuestions06.BABY_CHANGING_FACILITIES
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions06.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions07 {


  static readonly PHOTOS_OF_WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER = new Question({

    key: '-LdiQFT9o-8P6lKjNxyU', // uuid
    label: 'Please take 2 photos of the wheelchair bathroom in lobby/foyer area (in landscape mode)',
    helpImage: null,
    helpText: 'Take 2 good quality photo of the wheelchair accessible bathroom in hotel lobby area.',
    popupLabel: 'Reception accessible bathroom',
    type: Question.TYPE_PHOTO,

    spreadsheetRow: 22,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions07.PHOTOS_OF_WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions07.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions08 {

  static readonly HAS_RESTAURANT = new Question({
    key: '-LdK1I9Vg7sAAXjOfNUy', // uuid
    label: 'Do you have a dining area in the hotel?',
    helpImage: null,
    helpText: 'Do you have a restaurant or any areas for eating, like a breakfast area, in the hotel?',
    popupLabel: null,
    type: Question.TYPE_BOOLEAN,
    scoreCalculator: {
      getScore(answer: Answer): number {

        // don't have a restaurant ?
        if (!answer.value.value) {

          return 1 + 2; // RESTAURANT_HAS_LARGE_PRINT_MENU + RESTAURANT_IS_WHEELCHAIR_ACCESSIBLE
        }

        return 0;
      }
    },
    spreadsheetRow: 29,
    maximumScore: 0,
  } as IScoredQuestion);
  static readonly RESTAURANT_HAS_LARGE_PRINT_MENU = new Question({
    key: '-LdK6MmZabhkYOfrDuKb', // uuid
    label: 'Do you have a menu available in larger print?',
    helpImage: null,
    helpText: 'Is there an alternative menu which comes in a larger font? Minimum 14pt font',
    popupLabel: 'Menu available in large print',
    type: Question.TYPE_BOOLEAN,
    scoreCalculator: {
      getScore(answer: Answer): number {

        if (answer.value.value) {

          return 1;
        }

        return 0;
      }
    },
    maximumScore: 1,
    dependant: {
      questionKey: '-LdK1I9Vg7sAAXjOfNUy' // Do you have a dining area in the hotel?
    },
  } as IScoredQuestion);
  static readonly RESTAURANT_IS_WHEELCHAIR_ACCESSIBLE = new Question({
    key: '-LdK6Mg3um_UFWICeVEQ', // uuid
    label: 'Is the dining area accessible to a wheelchair user? This could be level access, ramp access or via a lift.',
    helpImage: 'artdept.ie/11_dining_bar_access.png',
    helpText: 'Can the food and drinks area be accessed without steps?',
    popupLabel: 'Level or ramped access to dining area',
    type: Question.TYPE_BOOLEAN,
    scoreCalculator: {
      getScore(answer: Answer): number {

        if (answer.value.value) {

          return 2;
        }

        return 0;
      }
    },
    maximumScore: 2,
    dependant: {
      questionKey: '-LdK1I9Vg7sAAXjOfNUy' // Do you have a dining area in the hotel?
    },
  } as IScoredQuestion);
  static readonly HAS_BAR = new Question({
    key: '-LdK1IEN6m64D5q0mgF0', // uuid
    label: 'Do you have a bar in the hotel?',
    helpImage: null,
    helpText: 'Do you have a bar serving alcohol in the hotel?',
    popupLabel: null,
    type: Question.TYPE_BOOLEAN,
    scoreCalculator: {
      getScore(answer: Answer): number {

        // don't have a restaurant
        if (!answer.value.value) {

          return 2; // BAR_IS_WHEELCHAIR_ACCESSIBLE
        }

        return 0;
      }
    },
    spreadsheetRow: 33,
    maximumScore: 0,
  } as IScoredQuestion);
  static readonly BAR_IS_WHEELCHAIR_ACCESSIBLE = new Question({
    key: '-LdK1ISncjPs_ccSy_3Z', // uuid
    label: 'Is the bar accessible to a wheelchair user? This could be level access, ramp access or via a lift.',
    helpImage: 'artdept.ie/11_dining_bar_access.png',
    helpText: 'Can the drinks area be accessed without steps?',
    popupLabel: 'Level or ramped access to bar area',
    type: Question.TYPE_BOOLEAN,
    scoreCalculator: {
      getScore(answer: Answer): number {

        if (answer.value.value) {

          return 2;
        }

        return 0;
      }
    },
    maximumScore: 2,
    dependant: {
      questionKey: '-LdK1IEN6m64D5q0mgF0' // Do you have a bar in the hotel?
    },

  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions08.HAS_RESTAURANT,
    GeneralQuestions08.RESTAURANT_IS_WHEELCHAIR_ACCESSIBLE,
    GeneralQuestions08.RESTAURANT_HAS_LARGE_PRINT_MENU,
    GeneralQuestions08.HAS_BAR,
    GeneralQuestions08.BAR_IS_WHEELCHAIR_ACCESSIBLE
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions08.QUESTIONS);

  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions09 {


  static readonly PHOTOS_OF_RESTAURANT_AND_OR_BAR = new Question({
    key: '-L6DUmu-t8ZOjW_UqB4j', // uuid
    label: 'Please take 2 photos of the dining area and 2 photos of the bar area (if applicable) (in landscape mode)',
    helpImage: null,
    helpText: 'Take 2 good quality photos of the dining area and 2 good quality photos of the bar area from various angles',
    popupLabel: 'Dining/bar area',
    type: Question.TYPE_PHOTO,

    spreadsheetRow: 36,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions09.PHOTOS_OF_RESTAURANT_AND_OR_BAR
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions09.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions10 {


  static readonly HAS_LIFT = new Question({

    key: '-L6DUq6oQynhK9WKayYn', // uuid
    label: 'Do you have a lift/elevator?',
    helpImage: 'artdept.ie/14_lift.png',
    helpText: 'Is there an elevator or lift accessible to all areas in your hotel. N/A means your premises is a single story hotel.\n',
    popupLabel: 'Lift/elevator',
    type: Question.TYPE_ENUM,
    scoreCalculator: {
      getScore(answer: Answer): number {


        const value = answer.getIntegerValue(EnumeratedConstants.LiftAccessibility.no.codeAsNumber);

        if (EnumeratedConstants.LiftAccessibility.no.codeAsNumber == value) {

          return 0;
        }

        if (EnumeratedConstants.LiftAccessibility.yes.codeAsNumber == value) {
          return 3;
        }

        if (EnumeratedConstants.LiftAccessibility.notApplicable.codeAsNumber == value) {
          return 3 + 2 + 1; // this and the two questions below
        }

        console.error([this], 'scoreCalculator', 'unexpected value', answer);
        return 0;

      }
    },
    spreadsheetRow: 24,
    maximumScore: 3,
    enumOptions: EnumeratedConstants.LiftAccessibility.all
  } as IScoredQuestion);
  static readonly VOICE_ANNOUNCEMENT_IN_LIFT = new Question({

    key: '-L6DUrFUO4qmrcFvKHrB', // uuid
    label: 'Do you have a voice announcement for each floor in the lift/elevator?\n',
    helpImage: 'artdept.ie/15_lift_anncr.png',
    helpText: 'Is there spoken messages indicating floor number and other information to passengers in the elevator\n',
    popupLabel: 'Voice announcement in lift/elevator',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 26,
    maximumScore: 2,
    dependant: {
      questionKey: '-L6DUq6oQynhK9WKayYn', // Do you have a lift/elevator?
    },
  } as IScoredQuestion);
  static readonly RAISED_NUMBERS_ON_LIFT = new Question({

    key: '-L6DUsWDwAU6jzGWu1F4', // uuid
    label: 'Do you have raised numbers, letters or braille on the lift/elevator buttons? ',
    helpImage: 'artdept.ie/16_braille.png',
    helpText: 'Is there raised markings to read numbers on the elevator panel for the benefit of blind people',
    popupLabel: 'Raised numbers/letters/braille on lift buttons',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 27,
    maximumScore: 1,
    dependant: {
      questionKey: '-L6DUq6oQynhK9WKayYn', // Do you have a lift/elevator?
    },
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions10.HAS_LIFT,
    GeneralQuestions10.VOICE_ANNOUNCEMENT_IN_LIFT,
    GeneralQuestions10.RAISED_NUMBERS_ON_LIFT
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions10.QUESTIONS);

  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions11 {


  static readonly LIFT_DOOR_WIDTH = new Question({
    key: '-L6DUx1LLkQZpgZiil6T', // uuid
    label: 'What is the "width" of the lift/elevator door?',
    helpImage: 'artdept.ie/17_lift_dimensions.png',
    helpText: 'In centimetres, measure the door width of lift  -  Measure the internal width of lift   -   Measure the internal depth of lift',
    popupLabel: 'Lift/elevator door width',
    type: Question.TYPE_CM_MEASUREMENT,

    spreadsheetRow: 30,
    maximumScore: 0,
  } as IScoredQuestion);
  static readonly LIFT_INTERNAL_WIDTH = new Question({

    key: '-L6DUyNUA_z4vz9-l3rN', // uuid
    label: 'What is the "internal width" of the lift/elevator?',
    helpImage: 'artdept.ie/17_lift_dimensions.png',
    helpText: 'In centimetres, measure the door width of lift  -  Measure the internal width of lift   -   Measure the internal depth of lift',
    popupLabel: 'Lift/elevator internal width',
    type: Question.TYPE_CM_MEASUREMENT,

    spreadsheetRow: 30,
    maximumScore: 0,
  } as IScoredQuestion);
  static readonly LIFT_INTERNAL_DEPTH = new Question({
    key: '-L6DUzbUIMB8JptS8oN0', // uuid
    label: 'What is the "internal depth" of the lift/elevator?',
    helpImage: 'artdept.ie/17_lift_dimensions.png',
    helpText: 'In centimetres, measure the door width of lift  -  Measure the internal width of lift   -   Measure the internal depth of lift',
    popupLabel: 'Lift/elevator internal depth',
    type: Question.TYPE_CM_MEASUREMENT,

    spreadsheetRow: 30,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions11.LIFT_DOOR_WIDTH,
    GeneralQuestions11.LIFT_INTERNAL_WIDTH,
    GeneralQuestions11.LIFT_INTERNAL_DEPTH,
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions11.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions12 {


  static readonly PHOTOS_OF_LIFT = new Question({

    key: '-L6DUuTVyIhnJScW7J9c', // uuid
    label: 'Please take 1 photo of the lift/elevator with the doors open (in landscape mode)',
    helpImage: null,
    helpText: 'If you have more than 1 lift/elevator, please take a good quality photo of the larger lift with the door open.\n',
    popupLabel: 'Lift/elevator',
    type: Question.TYPE_PHOTO,

    spreadsheetRow: 28,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions12.PHOTOS_OF_LIFT
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions12.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}
export class GeneralQuestions13 {

  static readonly HAS_POOL = new Question({

    key: '-LdJz62cgvah-cdBlljK', // uuid
    label: 'Do you have a swimming pool?\n',
    helpImage: 'artdept.ie/50_swimming_pool.png',
    helpText: 'Does your hotel provide a swimming pool for guests',
    popupLabel: 'Swimming pool',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 47,
    maximumScore: 0
  } as IScoredQuestion);
  static readonly HAS_POOL_HOIST = new Question({

    key: '-LdJz62X5U349p99Hbv0', // uuid
    label: 'Do you have a swimming pool hoist or lift to assist customers with disabilities?',
    helpImage: 'artdept.ie/51_swimming_pool_with_hoist.png',
    helpText: 'Can you provide an accessible entry and exit from the swimming pool via a hoist or lift to assist guests with disabilities? ',
    popupLabel: 'Swimming pool hoist',
    type: Question.TYPE_BOOLEAN,
    scoreCalculator: {

      // pre: answer.value.value != null
      getScore(answer: Answer): number {

        // see `GeneralQuestions13.calculateScoreAcrossQuestions()`
        return 0;
      }
    },
    spreadsheetRow: 49,
    maximumScore: 3,
    dependant: {
      questionKey: '-LdJz62cgvah-cdBlljK', // Do you have a swimming pool?
    }
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions13.HAS_POOL,
    GeneralQuestions13.HAS_POOL_HOIST
  ];

  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions13.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    const hasPool = answerValues[GeneralQuestions13.HAS_POOL.value.key];

    // answer not found
    if (!hasPool) {
      return 0;
    }

    if (!hasPool.value) {
      return GeneralQuestions13.HAS_POOL_HOIST.value.maximumScore; // 3
    }

    const hasPoolHoist = answerValues[GeneralQuestions13.HAS_POOL_HOIST.value.key];
    if (hasPoolHoist && hasPoolHoist.value) {

      return GeneralQuestions13.HAS_POOL_HOIST.value.maximumScore; // 3
    }

    return 0;

  }

}
export class GeneralQuestions14 {


  static readonly PHOTOS_OF_POOL_HOIST = new Question({

    key: '-LdK-l7JsQWk78ZAzhMf', // uuid
    label: 'Please take 2 photo of the swimming pool including the pool hoist/lift (in landscape mode)',
    helpImage: null,
    helpText: 'Take 2 good quality photo of the swimming pool and swimming pool hoist/lift.',
    popupLabel: 'Swimming pool',
    type: Question.TYPE_PHOTO,

    spreadsheetRow: 50,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions14.PHOTOS_OF_POOL_HOIST
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions14.QUESTIONS);

  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }

}
export class GeneralQuestions15 {


  static readonly STAFF_TRAINING_FOR_CUSTOMERS_WITH_DISABILITIES = new Question({

    key: '-L6DV13rMnZakH1WBUzQ', // uuid
    label: 'Have your staff completed a certified disability awareness training course?',
    helpImage: null,
    helpText: 'Have your staff received training in key skills and knowledge required to meet the needs of customers with disabilities?',
    popupLabel: 'Staff training for customers with disabilities',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 31,
    maximumScore: 3,
  } as IScoredQuestion);
  static readonly DETAILS_OF_TRAINING = new Question({
    key: '-L6DV3KcIZeNvzsk5O74', // uuid
    label: 'Details of training',
    helpImage: null,
    helpText: 'Please give details of what type of disability training your staff received \n',
    popupLabel: null,
    type: Question.TYPE_TEXT,

    spreadsheetRow: 32,
    maximumScore: 0,
    dependant: {
      questionKey: '-L6DV13rMnZakH1WBUzQ', // Have your staff completed a certified disability awareness training course?
    }
  } as IScoredQuestion);
  static readonly HOTEL_ACCESS_STATEMENT = new Question({
    key: '-LDBg1L60jia6DnXRryr', // uuid
    label: 'Does your hotel have an access statement?',
    helpImage: null,
    helpText: 'An access statement is a written, clear and accurate description of your current facilities and the services you offer, to enable a potential visitor to make an informed decision as to whether your business meets their particular access needs.',
    popupLabel: 'Hotel access statement',
    type: Question.TYPE_BOOLEAN,

    spreadsheetRow: 33,
    maximumScore: 2,
  } as IScoredQuestion);
  static readonly ACCESSIBILITY_ACCREDITATION = new Question( {
      key: '-LFlmqSpHiupCHuKrWVC', // uuid
      label: 'Do you have any accessibility, inclusion or universal design standards accreditation?',
      helpImage: null,
      helpText: 'Please provide details of standards accreditation.',
      popupLabel: 'Accessibility accreditation',
      type: Question.TYPE_ENUM,
      scoreCalculator: {
        getScore(answer: Answer): number {

          const value = answer.getIntegerValue(EnumeratedConstants.StandardsAccreditation.none.codeAsNumber);


          if (EnumeratedConstants.StandardsAccreditation.none.codeAsNumber === value) {

            return 0;
          }

          if (EnumeratedConstants.StandardsAccreditation.ada.codeAsNumber === value) {

            return 3;
          }

          if (EnumeratedConstants.StandardsAccreditation.iso.codeAsNumber === value) {

            return 3;
          }

          if (EnumeratedConstants.StandardsAccreditation.dac.codeAsNumber === value) {

            return 3;
          }

          if (EnumeratedConstants.StandardsAccreditation.other.codeAsNumber === value) {

            return 3;
          }

          console.error([this], 'scoreCalculator', 'unexpected value', value);
          return 0;
        }
      },
      spreadsheetRow: 34,
      maximumScore: 3,
      enumOptions: EnumeratedConstants.StandardsAccreditation.all,
    } as IScoredQuestion );
  static readonly OTHER_COMMENTS = new Question({

    key: '-L6DV4US2AMYe2W8PA5n', // uuid
    label: 'Do you have any other comments or information about the entrance or lobby area? Anything we have missed which you would like to add?',
    helpImage: null,
    helpText: 'Would you like to add any information about other accessible features you have which we haven\'t asked about?',
    popupLabel: null,
    type: Question.TYPE_TEXT,

    spreadsheetRow: 33,
    maximumScore: 0,
  } as IScoredQuestion);


  static readonly QUESTIONS: Question[] = [
    GeneralQuestions15.STAFF_TRAINING_FOR_CUSTOMERS_WITH_DISABILITIES,
    GeneralQuestions15.DETAILS_OF_TRAINING,
    GeneralQuestions15.HOTEL_ACCESS_STATEMENT,
    GeneralQuestions15.ACCESSIBILITY_ACCREDITATION,
    GeneralQuestions15.OTHER_COMMENTS,
  ];


  static readonly QUESTION_VALUES: IQuestion[] = Question.array.toValues(GeneralQuestions15.QUESTIONS);


  static calculateScoreAcrossQuestions(answerValues: { [key: string]: IAnswer; }): number {

    return 0;
  }
}



export class GeneralPortalSections {


  static readonly ACCREDITATION: Question[] = [
    GeneralQuestions15.STAFF_TRAINING_FOR_CUSTOMERS_WITH_DISABILITIES,
    GeneralQuestions15.HOTEL_ACCESS_STATEMENT,
    GeneralQuestions15.ACCESSIBILITY_ACCREDITATION
  ];

  static readonly COMMENTS: Question[] = [
    GeneralQuestions15.OTHER_COMMENTS
  ];

  static readonly DINING_AND_BAR: Question[] = [
    GeneralQuestions08.HAS_RESTAURANT,
    GeneralQuestions08.RESTAURANT_IS_WHEELCHAIR_ACCESSIBLE,
    GeneralQuestions08.RESTAURANT_HAS_LARGE_PRINT_MENU,
    GeneralQuestions08.HAS_BAR,
    GeneralQuestions08.BAR_IS_WHEELCHAIR_ACCESSIBLE,
  ];

  static readonly ENTRANCE: Question[] = [
    GeneralQuestions01.ACCESSIBLE_PARKING_SPACES,
    GeneralQuestions01.NUMBER_OF_STEPS_INTO_HOTEL,
    GeneralQuestions01.STEP_FREE_ENTRANCE,
    GeneralQuestions01.ALTERNATIVE_ACCESSIBLE_ENTRANCE,
    GeneralQuestions01.AUTOMATIC_OR_MANUAL_DOOR_AT_ENTRANCE,
  ];

  static readonly LIFT_AND_ELEVATOR: Question[] = [
    GeneralQuestions10.HAS_LIFT,
    GeneralQuestions10.VOICE_ANNOUNCEMENT_IN_LIFT,
    GeneralQuestions10.RAISED_NUMBERS_ON_LIFT,
  ];

  static readonly MEASUREMENTS: Question[] = [
    GeneralQuestions02.ENTRANCE_DOOR_WIDTH_MEASUREMENT,
    GeneralQuestions06.BATHROOM_DOOR_WIDTH_MEASUREMENT,
    GeneralQuestions11.LIFT_DOOR_WIDTH,
    GeneralQuestions11.LIFT_INTERNAL_DEPTH,
    GeneralQuestions11.LIFT_INTERNAL_WIDTH,
  ];

  static readonly PHOTOGRAPHS: Question[] = [
    GeneralQuestions03.PHOTOS_OF_OUTSIDE,
    GeneralQuestions05.PHOTOS_OF_RECEPTION,
    GeneralQuestions07.PHOTOS_OF_WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER,
    GeneralQuestions09.PHOTOS_OF_RESTAURANT_AND_OR_BAR,
    GeneralQuestions12.PHOTOS_OF_LIFT,
    GeneralQuestions14.PHOTOS_OF_POOL_HOIST,
  ];

  static readonly RECEPTION: Question[] = [
    GeneralQuestions04.LOWER_COUNTER_AT_RECEPTION,
    GeneralQuestions04.INDUCTION_LOOP_IN_RECEPTION_AREA,
    GeneralQuestions06.WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER
  ];


  static readonly SERVICES: Question[] = [
    GeneralQuestions04.TTY_TEXT_TELEPHONE,
    GeneralQuestions04.GUIDE_DOGS_WELCOME,
    GeneralQuestions06.WHEELCHAIR_AVAILABLE_TO_BORROW,
    GeneralQuestions06.BABY_CHANGING_FACILITIES,
    GeneralQuestions13.HAS_POOL,
    GeneralQuestions13.HAS_POOL_HOIST,
  ];

}


export class AccessibilityQuestions {




  static readonly BATHROOM = new EvaluationSection({
    name: 'Bathroom',
    // firebaseAnswersRoot: '/answers',
    firebaseAspectId: 'bathroom',
    // questions: Bathroom.getAllQuestionValues() as IScoredQuestion[],
    questions: [
      BathroomQuestions01.EN_SUITE_BATHROOM_DOOR_WIDTH.value as IScoredQuestion,
      BathroomQuestions01.TOILET_HEIGHT.value as IScoredQuestion,
      BathroomQuestions02.PHOTOS_OF_BATHROOM_DOORWAY.value as IScoredQuestion,
      BathroomQuestions03.WET_ROOM_ROLL_IN_SHOWER.value as IScoredQuestion,
      BathroomQuestions03.SEAT_PROVIDED.value as IScoredQuestion,
      BathroomQuestions03.BATH_TUB.value as IScoredQuestion,
      BathroomQuestions03.HAND_RAIL_ON_INSIDE_DOOR.value as IScoredQuestion,
      BathroomQuestions03.ADJUSTABLE_SHOWER_HEAD_HEIGHT.value as IScoredQuestion,
      BathroomQuestions04.GRAB_RAILS.value as IScoredQuestion,
      BathroomQuestions04.ALARM_CORD_OR_BUTTON_FOR_EMERGENCY_USE.value as IScoredQuestion,
      BathroomQuestions04.CLEAR_SPACE_UNDER_SINK.value as IScoredQuestion,
      BathroomQuestions04.LOWER_MIRROR_HEIGHT.value as IScoredQuestion,
      BathroomQuestions04.LEVER_TAP_HANDLES.value as IScoredQuestion,
      BathroomQuestions05.PHOTOS_OF_ADAPTED_BATHROOM.value as IScoredQuestion,
      BathroomQuestions06.COMMENTS_OR_INFORMATION_ABOUT_THE_ACCESSIBLE_BATHROOM.value as IScoredQuestion,
    ],
    sections: {
      evaluation: [
        {
          headerText: 'Time to take some Measurements...',
          questionKeys: [
            '-L6DTPdJCrTMAMMYdkfb', // What is the width of the accessible bathroom door?
            '-L6DTpHMghPxnYuIEvsf', // What is the toilet height from the floor to the top of the toilet seat?
          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DTS91zwTezGIJ8YC6', // Please take 1 photo of open doorway to en-suite accessible bathroom (in landscape mode)
          ]
        },
        {
          headerText: 'Bathroom',
          questionKeys: [
            '-L7ZvUHo8g1s2sLjWCqJ', // Does the accessible bathroom have a level floor roll in shower?
            '-L6DTUEtCWfqX53qUeDs', // Is there a seat in the roll in shower? This could be fixed or portable
            '-L6DTVcbcgzIN8_BrgDv', // Does the accessible bathroom have a bath tub?
            '-L6DTWwW9qXWVwHWpsvs', // Is there a hand rail on the inside of the accessible bathroom door?
            '-L6DTZoHtY1Cm9FeAEZx', // Is the shower head height adjustable?

          ]
        },
        {
          headerText: 'Bathroom',
          questionKeys: [
            '-L6DTdIk-C5IorurlqWr', // Are there grab rails around the shower and toilet?
            '-L6DTeq5__gGa23m_6aT', // Do you have an alarm cord or button in the accessible bathroom for emergency use?
            '-L6DTgLZqSTISK9f04Ao', // Is there a clear space under the sink to enable a wheelchair user to fit under?

            '-L6DThiSWXX_F4xrcljo', // Do you have a bathroom mirror at a lower height?
            '-L6DTj5T0adhCWaopGlM', // Does the sink have lever tap handles?

          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DTm7mUZrnZkaw4Zo3', // Please take 3 photos of en-suite accessible bathroom from different angles (in landscape mode)
          ]
        },
        {
          headerText: 'Bathroom',
          questionKeys: [
            '-L6DTr7ZmqARr6pkh5Ro', // Do you have any other comments or information about the accessible bathroom?  Anything we have missed which you would like to add?
          ]
        },
      ],
      portal: [
        {
          headerText: 'Bathing facilities',
          questionKeys: [
            '-L7ZvUHo8g1s2sLjWCqJ', // Does the accessible bathroom have a level floor roll in shower?
            '-L6DTUEtCWfqX53qUeDs', // Is there a seat in the roll in shower? This could be fixed or portable
            '-L6DTVcbcgzIN8_BrgDv', // Does the accessible bathroom have a bath tub?
          ]
        },
        {
          headerText: 'Convenience',
          questionKeys: [
            '-L6DTZoHtY1Cm9FeAEZx', // Is the shower head height adjustable?

            '-L6DTgLZqSTISK9f04Ao', // Is there a clear space under the sink to enable a wheelchair user to fit under?

            '-L6DThiSWXX_F4xrcljo', // Do you have a bathroom mirror at a lower height?
            '-L6DTj5T0adhCWaopGlM', // Does the sink have lever tap handles?

          ]
        },
        {
          headerText: 'Safety',
          questionKeys: [
            '-L6DTWwW9qXWVwHWpsvs', // Is there a hand rail on the inside of the accessible bathroom door?
            '-L6DTdIk-C5IorurlqWr', // Are there grab rails around the shower and toilet?
            '-L6DTeq5__gGa23m_6aT', // Do you have an alarm cord or button in the accessible bathroom for emergency use?
          ]
        },
        {
          headerText: 'Comments',
          questionKeys: [
            '-L6DTr7ZmqARr6pkh5Ro', // Do you have any other comments or information about the accessible bathroom?  Anything we have missed which you would like to add?
          ]
        },
        {
          headerText: 'Measurements',
          questionKeys: [
            '-L6DTPdJCrTMAMMYdkfb', // What is the width of the accessible bathroom door?
            '-L6DTpHMghPxnYuIEvsf', // What is the toilet height from the floor to the top of the toilet seat?
          ]
        },
        {
          headerText: 'Photographs',
          questionKeys: [
            '-L6DTS91zwTezGIJ8YC6', // Please take 1 photo of open doorway to en-suite accessible bathroom (in landscape mode)
            '-L6DTm7mUZrnZkaw4Zo3', // Please take 3 photos of en-suite accessible bathroom from different angles (in landscape mode)
          ]
        },
      ]
    },
    evaluation: {
      homePageIconPath: 'assets/icons/bathroom_icon.purple.svg',
    },
    portal: {
      navigationMenu: {
        iconImgBase: 'assets/service.navigation-menu/Bathroom_Icon'
      }
    },
    uuid: '51c116c5-344b-41f8-8084-2cb642c03063'
  });
  static readonly BEDROOM = new EvaluationSection({
    name: 'Bedroom',
    // firebaseAnswersRoot: '/answers',
    firebaseAspectId: 'bedroom',
    // questions: Bedroom.getAllQuestionValues() as IScoredQuestion[],
    questions: [
      BedroomQuestions01.NUMBER_OF_ACCESSIBLE_BEDROOMS.value as IScoredQuestion,
      BedroomQuestions01.ACCESSIBLE_BEDROOMS_AVAILABLE_ON_GROUND_FLOOR.value as IScoredQuestion,
      BedroomQuestions01.INTERCONNECTING_ROOM_TO_ACCESSIBLE_ROOM.value as IScoredQuestion,
      BedroomQuestions01.BEDROOM_DOOR_WIDTH.value as IScoredQuestion,
      BedroomQuestions02.PHOTOS_OF_BEDROOM_DOOR.value as IScoredQuestion,
      BedroomQuestions03.SPYHOLE_AT_LOWER_HEIGHT.value as IScoredQuestion,
      BedroomQuestions03.TELEPHONE_REACHABLE_FROM_BED.value as IScoredQuestion,
      BedroomQuestions03.LIGHT_SWITCH_REACHABLE_FROM_BED.value as IScoredQuestion,
      BedroomQuestions03.ELECTRICAL_SOCKET_REACHABLE_FROM_BED.value as IScoredQuestion,
      BedroomQuestions03.TV_REMOTE_REACHABLE_FROM_BED.value as IScoredQuestion,
      BedroomQuestions04.FLOOR_SURFACE_TYPE.value as IScoredQuestion,
      BedroomQuestions04.MINI_FRIDGE_AVAILABLE_IN_ROOM.value as IScoredQuestion,
      BedroomQuestions04.REFRIGERATION_AVAILABLE_FOR_MEDICATIONS.value as IScoredQuestion,
      BedroomQuestions04.LOWER_HANGING_RAIL_IN_WARDROBE.value as IScoredQuestion,
      BedroomQuestions04.SUBTITLE_OPTION_AVAILABLE_ON_TV.value as IScoredQuestion,
      BedroomQuestions05.FLASHING_BEACON_VISUAL_FIRE_ALARM.value as IScoredQuestion,
      BedroomQuestions05.VIBRATING_PILLOW_ALARM.value as IScoredQuestion,
      BedroomQuestions05.CEILING_TRACK_HOIST.value as IScoredQuestion,
      BedroomQuestions05.HEIGHT_ADJUSTABLE_BED.value as IScoredQuestion,
      BedroomQuestions06.PHOTOS_OF_ACCESSIBLE_BEDROOM.value as IScoredQuestion,
      BedroomQuestions07.WIDEST_SPACE_AT_SIDE_OF_BED.value as IScoredQuestion,
      BedroomQuestions07.HEIGTH_OF_BED_FROM_FLOOR_TO_TOP_OF_MATTRESS.value as IScoredQuestion,
      BedroomQuestions07.GAP_UNDER_BED.value as IScoredQuestion,
      BedroomQuestions07.HEIGHT_OF_GAP_UNDER_THE_BED.value as IScoredQuestion,
      BedroomQuestions08.COMMENTS_OR_INFORMATION_ABOUT_THE_ACCESSIBLE_BEDROOM.value as IScoredQuestion,
    ],
    sections: {
      evaluation: [
        {
          headerText: 'Bedroom',
          questionKeys: [
            '-L6DTt_i9TDqVqrtQqlR', // How many fully accessible bedrooms do you have in this hotel?
            '-L6DTuvta24mUCyD6Zwb', // Do you have any fully accessible bedrooms available on the ground floor?
            '-L6DTwffSrlqJB9Oy02S', // Do any of the accessible bedrooms have an interconnecting/adjoining bedroom?
            '-L6DU0p7kMQPxGSBULag', // What is the accessible bedroom door width?
          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DTyiEECV_ZaDfKRNe', // Please take 2 photos of the Accessible Bedroom doorway - 1 Open & 1 Closed (in landscape mode)
          ]
        },
        {
          headerText: 'Bedroom',
          questionKeys: [
            '-L8nqehj8NtphZQQ9gsO', // Is there a spyhole on the bedroom door at a lower height?
            '-L6DU3pnH0YIHTh1Bi4D', // Is there a telephone reachable from the bed?
            '-L6DU5Ds_eI-pqT1MLLm', // Is there a light switch reachable from the bed?

            '-LdJlpjoVYyIDEYGAidj', // Is there an electrical socket reachable from the bed?
            '-L7YnkWS4aY4HxT8uqNo', // Is the TV remote control reachable from the bed?
          ]
        },
        {
          headerText: 'Bedroom',
          questionKeys: [
            '-LdcTDumQ6zkkld1slfR', // What is the floor surface in the bedroom?
            '-L6DU9Tsu-B6srCBK9QJ', // Do you have a mini fridge in the bedroom for medications or available on request?
            '-L8Yd9Aq46wnsNgrXLmh', // Do you have any refrigeration options for medication available on request?
            '-L6DUEQ3ubJgoD4VtWMz', // Do you have a lower hanging rail in the wardrobe?

            '-L6DUFfiGVqRd-eUhLNo', // Does the TV have a closed captioning option (subtitles) for hearing impaired?

          ]
        },
        {
          headerText: 'Bedroom',
          questionKeys: [
            '-L6DUH7OROer8dijZTWU', // Is there a visual alarm/flashing beacon alert in the room?

            '-L82bhg77tDKfz0XqUcX', // Do you have a vibrating pillow alarm available on request?

            '-L6DUIRCbPfu0RBtFCmb', // Do any of the accessible bedrooms have a ceiling track hoist?
            '-L6DUJfddpX5vW0f00S7', // Do any of the accessible bedrooms have a height adjustable bed?
          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DUMJJt6Y2xkGxSAcq', // Please take 3 photos of accessible bedroom from different angles  (in landscape mode)
          ]
        },
        {
          headerText: 'Time to take some Measurements...',
          questionKeys: [
            '-L6DURK38IpveIbqMt-d', // What is the widest free space at the side of the bed?
            '-L6DUOfGxfEyd1_b6eDK', // What is the height of the bed from the floor to the top of the mattress?
            '-L82djnLe91vpX2v5wZs', // Is there a gap under the bed for manoeuvring a mobile hoist?
            '-L6DUPy6z0XDM_8XJrzo', // What is the height of the gap under the bed - from the floor to the underside of the bed?
          ]
        },
        {
          headerText: 'Bedroom',
          questionKeys: [
            '-L6DUTNhUPyXteXAEuad', // Do you have any other comments or information about the accessible bedroom?  Anything we have missed which you would like to add?
          ]
        },
      ],
      portal: [
        {
          headerText: 'Bedrooms',
          questionKeys: [
            '-L6DTt_i9TDqVqrtQqlR', // How many fully accessible bedrooms do you have in this hotel?
            '-L6DTuvta24mUCyD6Zwb', // Do you have any fully accessible bedrooms available on the ground floor?
            '-L6DTwffSrlqJB9Oy02S', // Do any of the accessible bedrooms have an interconnecting/adjoining bedroom?
          ]
        },
        {
          headerText: 'Convenience',
          questionKeys: [
            '-L8nqehj8NtphZQQ9gsO', // Is there a spyhole on the bedroom door at a lower height?
            '-L6DU3pnH0YIHTh1Bi4D', // Is there a telephone reachable from the bed?
            '-L6DU5Ds_eI-pqT1MLLm', // Is there a light switch reachable from the bed?

            '-LdJlpjoVYyIDEYGAidj', // Is there an electrical socket reachable from the bed?
            '-L7YnkWS4aY4HxT8uqNo', // Is the TV remote control reachable from the bed?
          ]
        },
        {
          headerText: 'Safety',
          questionKeys: [
            '-L6DU9Tsu-B6srCBK9QJ', // Do you have a mini fridge in the bedroom for medications or available on request?
            '-L8Yd9Aq46wnsNgrXLmh', // Do you have any refrigeration options for medication available on request?
            '-L6DUH7OROer8dijZTWU', // Is there a visual alarm/flashing beacon alert in the room?

            '-L82bhg77tDKfz0XqUcX', // Do you have a vibrating pillow alarm available on request?

          ]
        },
        {
          headerText: 'Other',
          questionKeys: [
            '-LdcTDumQ6zkkld1slfR', // What is the floor surface in the bedroom?
            '-L6DUEQ3ubJgoD4VtWMz', // Do you have a lower hanging rail in the wardrobe?

            '-L6DUFfiGVqRd-eUhLNo', // Does the TV have a closed captioning option (subtitles) for hearing impaired?

            '-L6DUIRCbPfu0RBtFCmb', // Do any of the accessible bedrooms have a ceiling track hoist?
            '-L6DUJfddpX5vW0f00S7', // Do any of the accessible bedrooms have a height adjustable bed?
            '-L82djnLe91vpX2v5wZs', // Is there a gap under the bed for manoeuvring a mobile hoist?
          ]
        },
        {
          headerText: 'Comments',
          questionKeys: [
            '-L6DUTNhUPyXteXAEuad', // Do you have any other comments or information about the accessible bedroom?  Anything we have missed which you would like to add?

          ]
        },
        {
          headerText: 'Measurements',
          questionKeys: [
            '-L6DU0p7kMQPxGSBULag', // What is the accessible bedroom door width?
            '-L6DURK38IpveIbqMt-d', // What is the widest free space at the side of the bed?
            '-L6DUOfGxfEyd1_b6eDK', // What is the height of the bed from the floor to the top of the mattress?
            '-L6DUPy6z0XDM_8XJrzo', // What is the height of the gap under the bed - from the floor to the underside of the bed?
          ]
        },
        {
          headerText: 'Photographs',
          questionKeys: [
            '-L6DTyiEECV_ZaDfKRNe', // Please take 2 photos of the Accessible Bedroom doorway - 1 Open & 1 Closed (in landscape mode)
            '-L6DUMJJt6Y2xkGxSAcq', // Please take 3 photos of accessible bedroom from different angles  (in landscape mode)
          ]
        },
      ]
    },
    evaluation: {
      homePageIconPath: 'assets/icons/bedroom_icon.purple.svg',
    },
    portal: {
      navigationMenu: {
        iconImgBase: 'assets/service.navigation-menu/Bedroom_icon'
      }
    },
    uuid: '07f6a579-df0e-4af8-9459-fc2bacd5245e'
  });
  static readonly GENERAL = new EvaluationSection({
    name: 'General',
    // firebaseAnswersRoot: '/answers',
    firebaseAspectId: 'general',
    // questions: General.getAllQuestionValues() as IScoredQuestion[],
    questions: [
      GeneralQuestions01.ACCESSIBLE_PARKING_SPACES.value as IScoredQuestion,
      GeneralQuestions01.NUMBER_OF_STEPS_INTO_HOTEL.value as IScoredQuestion,
      GeneralQuestions01.STEP_FREE_ENTRANCE.value as IScoredQuestion,
      GeneralQuestions01.ALTERNATIVE_ACCESSIBLE_ENTRANCE.value as IScoredQuestion,
      GeneralQuestions01.AUTOMATIC_OR_MANUAL_DOOR_AT_ENTRANCE.value as IScoredQuestion,
      GeneralQuestions02.ENTRANCE_DOOR_WIDTH_MEASUREMENT.value as IScoredQuestion,
      GeneralQuestions03.PHOTOS_OF_OUTSIDE.value as IScoredQuestion,
      GeneralQuestions04.LOWER_COUNTER_AT_RECEPTION.value as IScoredQuestion,
      GeneralQuestions04.TTY_TEXT_TELEPHONE.value as IScoredQuestion,
      GeneralQuestions04.INDUCTION_LOOP_IN_RECEPTION_AREA.value as IScoredQuestion,
      GeneralQuestions04.GUIDE_DOGS_WELCOME.value as IScoredQuestion,
      GeneralQuestions05.PHOTOS_OF_RECEPTION.value as IScoredQuestion,
      GeneralQuestions06.WHEELCHAIR_AVAILABLE_TO_BORROW.value as IScoredQuestion,
      GeneralQuestions06.WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER.value as IScoredQuestion,
      GeneralQuestions06.BATHROOM_DOOR_WIDTH_MEASUREMENT.value as IScoredQuestion,
      GeneralQuestions06.BABY_CHANGING_FACILITIES.value as IScoredQuestion,
      GeneralQuestions07.PHOTOS_OF_WHEELCHAIR_ACCESSIBLE_BATHROOM_IN_FOYER.value as IScoredQuestion,
      GeneralQuestions08.HAS_RESTAURANT.value as IScoredQuestion,
      GeneralQuestions08.RESTAURANT_IS_WHEELCHAIR_ACCESSIBLE.value as IScoredQuestion,
      GeneralQuestions08.RESTAURANT_HAS_LARGE_PRINT_MENU.value as IScoredQuestion,
      GeneralQuestions08.HAS_BAR.value as IScoredQuestion,
      GeneralQuestions08.BAR_IS_WHEELCHAIR_ACCESSIBLE.value as IScoredQuestion,
      GeneralQuestions09.PHOTOS_OF_RESTAURANT_AND_OR_BAR.value as IScoredQuestion,
      GeneralQuestions10.HAS_LIFT.value as IScoredQuestion,
      GeneralQuestions10.VOICE_ANNOUNCEMENT_IN_LIFT.value as IScoredQuestion,
      GeneralQuestions10.RAISED_NUMBERS_ON_LIFT.value as IScoredQuestion,
      GeneralQuestions11.LIFT_DOOR_WIDTH.value as IScoredQuestion,
      GeneralQuestions11.LIFT_INTERNAL_WIDTH.value as IScoredQuestion,
      GeneralQuestions11.LIFT_INTERNAL_DEPTH.value as IScoredQuestion,
      GeneralQuestions12.PHOTOS_OF_LIFT.value as IScoredQuestion,
      GeneralQuestions13.HAS_POOL.value as IScoredQuestion,
      GeneralQuestions13.HAS_POOL_HOIST.value as IScoredQuestion,
      GeneralQuestions14.PHOTOS_OF_POOL_HOIST.value as IScoredQuestion,
      GeneralQuestions15.STAFF_TRAINING_FOR_CUSTOMERS_WITH_DISABILITIES.value as IScoredQuestion,
      GeneralQuestions15.DETAILS_OF_TRAINING.value as IScoredQuestion,
      GeneralQuestions15.HOTEL_ACCESS_STATEMENT.value as IScoredQuestion,
      GeneralQuestions15.ACCESSIBILITY_ACCREDITATION.value as IScoredQuestion,
      GeneralQuestions15.OTHER_COMMENTS.value as IScoredQuestion,
    ],
    sections: {
      evaluation: [
        {
          headerText: 'General',
          questionKeys: [
            '-L68__m25kCYRyJd3Ee-', // How many wheelchair parking spaces do you have?

            '-L6DSSmoBN7vDZmddfIG', // How many steps are there up to the main hotel entrance?

            '-L6DSPiulNXmCtxJxElU', // Do you have step free access into the main hotel entrance?  This could be level access, ramp access or via a lift.

            '-L6DSRo6vhlaMi5auigl', // Do you have an alternative accessible entrance?
            '-L6DSTqBmCMPe0O-_C-q', // What type of door do you have into the main hotel entrance? Please choose most accessible one.
          ]
        },
        {
          headerText: 'Time to take some Measurements...',
          questionKeys: [
            '-L6DUWeouya_jPxYHCr4', // What is the main accessible entrance door width measurement?

          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DTLoSb79g01k5lEFp', // Please take 2 photos of outside of building - Accessible hotel entrance (in landscape mode)
          ]
        },
        {
          headerText: 'General',
          questionKeys: [
            '-L6DUYviwVH0ANE8BhJP', // Do you have a lower counter/desk at reception?

            '-LdNlNPm7xyhcTPeUbwX', // Do you have a TTY or TDD text telephone?

            '-L6DU_2ueTTl0RA9YYNE', // Do you have an induction loop in the reception area?
            '-L6DUaSSJ7RNVE5cevfu', // Are guide dogs and service dogs welcome?
          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DUgrH2S9Kv6VxIxg_', // Please take 2 photos of the reception area (in landscape mode)
          ]
        },
        {
          headerText: 'General',
          questionKeys: [
            '-L6DUbw12EOhbryKK0hw', // Do you have a wheelchair available to borrow?

            '-L6DUjPR316laxgEUxak', // Do you have a wheelchair accessible bathroom in the hotel lobby/foyer area for general use?
            '-LC-VVJqmMZ38CK0T9__', // What is the door width of this general accessible bathroom?
            '-L6DUkmgUA4IsBhSevg1', // Do you have baby changing facilities in your hotel?

          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-LdiQFT9o-8P6lKjNxyU', // Please take 2 photos of the wheelchair bathroom in lobby/foyer area (in landscape mode)
          ]
        },
        {
          headerText: 'General',
          questionKeys: [
            '-LdK1I9Vg7sAAXjOfNUy', // Do you have a dining area in the hotel?
            '-LdK6Mg3um_UFWICeVEQ', // Is the dining area accessible to a wheelchair user? This could be level access, ramp access or via a lift.
            '-LdK6MmZabhkYOfrDuKb', // Do you have a menu available in larger print?
            '-LdK1IEN6m64D5q0mgF0', // Do you have a bar in the hotel?
            '-LdK1ISncjPs_ccSy_3Z', // Is the bar accessible to a wheelchair user? This could be level access, ramp access or via a lift.
          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DUmu-t8ZOjW_UqB4j', // Please take 2 photos of the dining area and 2 photos of the bar area (if applicable) (in landscape mode)
          ]
        },
        {
          headerText: 'General',
          questionKeys: [
            '-L6DUq6oQynhK9WKayYn', // Do you have a lift/elevator?
            '-L6DUrFUO4qmrcFvKHrB', // Do you have a voice announcement for each floor in the lift/elevator?

            '-L6DUsWDwAU6jzGWu1F4', // Do you have raised numbers, letters or braille on the lift/elevator buttons?
          ]
        },
        {
          headerText: 'Time to take some Measurements of the lift ...',
          questionKeys: [
            '-L6DUx1LLkQZpgZiil6T', // What is the "width" of the lift/elevator door?
            '-L6DUyNUA_z4vz9-l3rN', // What is the "internal width" of the lift/elevator?
            '-L6DUzbUIMB8JptS8oN0', // What is the "internal depth" of the lift/elevator?
          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-L6DUuTVyIhnJScW7J9c', // Please take 1 photo of the lift/elevator with the doors open (in landscape mode)
          ]
        },
        {
          headerText: 'General',
          questionKeys: [
            '-LdJz62cgvah-cdBlljK', // Do you have a swimming pool?

            '-LdJz62X5U349p99Hbv0', // Do you have a swimming pool hoist or lift to assist customers with disabilities?
          ]
        },
        {
          headerText: 'Let’s take some photos...',
          questionKeys: [
            '-LdK-l7JsQWk78ZAzhMf', // Please take 2 photo of the swimming pool including the pool hoist/lift (in landscape mode)
          ]
        },
        {
          headerText: 'General',
          questionKeys: [
            '-L6DV13rMnZakH1WBUzQ', // Have your staff completed a certified disability awareness training course?
            '-L6DV3KcIZeNvzsk5O74', // Details of training
            '-LDBg1L60jia6DnXRryr', // Does your hotel have an access statement?
            '-LFlmqSpHiupCHuKrWVC', // Do you have any accessibility, inclusion or universal design standards accreditation?
            '-L6DV4US2AMYe2W8PA5n', // Do you have any other comments or information about the entrance or lobby area? Anything we have missed which you would like to add?
          ]
        },
      ],
      portal: [
        {
          headerText: 'Entrance',
          questionKeys: [
            '-L68__m25kCYRyJd3Ee-', // How many wheelchair parking spaces do you have?

            '-L6DSSmoBN7vDZmddfIG', // How many steps are there up to the main hotel entrance?

            '-L6DSPiulNXmCtxJxElU', // Do you have step free access into the main hotel entrance?  This could be level access, ramp access or via a lift.

            '-L6DSRo6vhlaMi5auigl', // Do you have an alternative accessible entrance?
            '-L6DSTqBmCMPe0O-_C-q', // What type of door do you have into the main hotel entrance? Please choose most accessible one.
          ]
        },
        {
          headerText: 'Reception',
          questionKeys: [
            '-L6DUYviwVH0ANE8BhJP', // Do you have a lower counter/desk at reception?

            '-L6DU_2ueTTl0RA9YYNE', // Do you have an induction loop in the reception area?
            '-L6DUjPR316laxgEUxak', // Do you have a wheelchair accessible bathroom in the hotel lobby/foyer area for general use?
          ]
        },
        {
          headerText: 'Services',
          questionKeys: [
            '-LdNlNPm7xyhcTPeUbwX', // Do you have a TTY or TDD text telephone?

            '-L6DUaSSJ7RNVE5cevfu', // Are guide dogs and service dogs welcome?
            '-L6DUbw12EOhbryKK0hw', // Do you have a wheelchair available to borrow?

            '-L6DUkmgUA4IsBhSevg1', // Do you have baby changing facilities in your hotel?

            '-LdJz62cgvah-cdBlljK', // Do you have a swimming pool?

            '-LdJz62X5U349p99Hbv0', // Do you have a swimming pool hoist or lift to assist customers with disabilities?
          ]
        },
        {
          headerText: 'Dining and bar',
          questionKeys: [
            '-LdK1I9Vg7sAAXjOfNUy', // Do you have a dining area in the hotel?
            '-LdK6Mg3um_UFWICeVEQ', // Is the dining area accessible to a wheelchair user? This could be level access, ramp access or via a lift.
            '-LdK6MmZabhkYOfrDuKb', // Do you have a menu available in larger print?
            '-LdK1IEN6m64D5q0mgF0', // Do you have a bar in the hotel?
            '-LdK1ISncjPs_ccSy_3Z', // Is the bar accessible to a wheelchair user? This could be level access, ramp access or via a lift.
          ]
        },
        {
          headerText: 'Lift and elevator',
          questionKeys: [
            '-L6DUq6oQynhK9WKayYn', // Do you have a lift/elevator?
            '-L6DUrFUO4qmrcFvKHrB', // Do you have a voice announcement for each floor in the lift/elevator?

            '-L6DUsWDwAU6jzGWu1F4', // Do you have raised numbers, letters or braille on the lift/elevator buttons?
          ]
        },
        {
          headerText: 'Accreditation',
          questionKeys: [
            '-L6DV13rMnZakH1WBUzQ', // Have your staff completed a certified disability awareness training course?
            '-LDBg1L60jia6DnXRryr', // Does your hotel have an access statement?
            '-LFlmqSpHiupCHuKrWVC', // Do you have any accessibility, inclusion or universal design standards accreditation?
          ]
        },
        {
          headerText: 'Comments',
          questionKeys: [
            '-L6DV4US2AMYe2W8PA5n', // Do you have any other comments or information about the entrance or lobby area? Anything we have missed which you would like to add?
          ]
        },
        {
          headerText: 'Measurements',
          questionKeys: [
            '-L6DUWeouya_jPxYHCr4', // What is the main accessible entrance door width measurement?

            '-LC-VVJqmMZ38CK0T9__', // What is the door width of this general accessible bathroom?
            '-L6DUx1LLkQZpgZiil6T', // What is the "width" of the lift/elevator door?
            '-L6DUzbUIMB8JptS8oN0', // What is the "internal depth" of the lift/elevator?
            '-L6DUyNUA_z4vz9-l3rN', // What is the "internal width" of the lift/elevator?
          ]
        },
        {
          headerText: 'Photographs',
          questionKeys: [
            '-L6DTLoSb79g01k5lEFp', // Please take 2 photos of outside of building - Accessible hotel entrance (in landscape mode)
            '-L6DUgrH2S9Kv6VxIxg_', // Please take 2 photos of the reception area (in landscape mode)
            '-LdiQFT9o-8P6lKjNxyU', // Please take 2 photos of the wheelchair bathroom in lobby/foyer area (in landscape mode)
            '-L6DUmu-t8ZOjW_UqB4j', // Please take 2 photos of the dining area and 2 photos of the bar area (if applicable) (in landscape mode)
            '-L6DUuTVyIhnJScW7J9c', // Please take 1 photo of the lift/elevator with the doors open (in landscape mode)
            '-LdK-l7JsQWk78ZAzhMf', // Please take 2 photo of the swimming pool including the pool hoist/lift (in landscape mode)
          ]
        },
      ]
    },
    evaluation: {
      homePageIconPath: 'assets/icons/general_hotel_icon.purple.svg',
    },
    portal: {
      navigationMenu: {
        iconImgBase: 'assets/service.navigation-menu/fontawesome.building-regular'
      }
    },
    uuid: '4fe72cfb-df7b-4380-a97d-e9da452ed197'
  });


  static readonly aspects: EvaluationSection[] = [
    AccessibilityQuestions.GENERAL,
    AccessibilityQuestions.BEDROOM,
    AccessibilityQuestions.BATHROOM,
  ];
}



{
  // const aspect = AccessibilityQuestions.GENERAL;
  //
  // console.log( 'portalSections ...\n\n' );
  //
  //
  // let json = aspect.sectionQuestionsToJson( aspect.portalSections );
  // console.log( json );
  // console.log( '... portalSections' );

}
