import {ActivatedRoute, Router} from '@angular/router';
import {RouteDescriptor} from "../browser.lib.evaluation-tool/util/RouteDescriptor";



export class AppRouteManifest {



  static readonly ASPECT_SECTION = new class extends RouteDescriptor {

    navigateToAspect(router: Router, aspectIndex: number, sectionIndex: number ) {
      const params = {
        aspectIndex,
        sectionIndex
      };

      this.navigate( router, params );
    }

    async getAspectIndex( route: ActivatedRoute ): Promise<number> {

      const aspectIndex: string = await this.getParam( route, 'aspectIndex' );
      if( !aspectIndex ) {
        return 0;
      }
      return Number.parseInt( aspectIndex );
    }

    async getSectionIndex( route: ActivatedRoute ): Promise<number> {

      const aspectIndex: string = await this.getParam( route, 'sectionIndex' );
      if( !aspectIndex ) {
        return 0;
      }
      return Number.parseInt( aspectIndex );
    }

    constructor() {

      super('aspect/:aspectIndex/:sectionIndex' );
    }
  };


  static readonly DOCUMENTS = new RouteDescriptor('documents' );

  static readonly HOME = new RouteDescriptor('home' );

  static readonly PASSWORD_RESET = new class extends RouteDescriptor {

    navigateToPasswordReset(router: Router, emailAddress: string  ) {
      const params = {
        emailAddress
      };

      this.navigate( router, params );
    }

    async getEmailAddress( route: ActivatedRoute ): Promise<string|null> {

      return this.getParam( route, 'emailAddress' );
    }


    constructor() {
      super('password-reset/:emailAddress');
    }
  };

  static readonly PROPERTY_SELECT = new RouteDescriptor('property-select' );

  static readonly SCORE = new RouteDescriptor('score' );

}



