


export class BrowserAppEvaluationToolInfo {

  public version = '2023.9.25.2';

  constructor() {
  }

}
